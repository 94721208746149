.claim-business-main-container{
  margin: auto;
  background-color: #fff;
  width: 100%;
  overflow-y: scroll;
  max-height: 70vh;
}

.claim-business-container{
  display: flex;
  flex-direction: column;
  padding: 1% 6% 6% 6%;
  align-items: center;
}

.name-field {
  margin-right: 1%;
}

.business-form{
  display: flex;
  min-height: max-content;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 6%;
}

.field {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin: 1% 0;
}

.error-message-modal {
  margin-top: 1%;
  color: #c70b1b;
}

.claim-message{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.success{
  color: #008000;
}

.failed {
  color: #bf0708;
}

.close-icon-claim {
  display: flex;
  justify-content: space-between;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .claim-business-main-container{
    max-height: 80vh;
    overflow-y: scroll;
  }
  .business-form{
    min-height: max-content;
  }
  .claim-message{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

.claim-business-main-container::-webkit-scrollbar{
  width: 5px;
  border-right: 1px solid #808080;
}

.claim-business-main-container::-webkit-scrollbar-thumb{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #555;
  height: 2px;
}