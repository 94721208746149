.overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 2;
  background-color: #245497;
  opacity: 0.95;
  overflow-x: hidden;
}

.overlayContent {
  position: fixed;
  bottom: 20%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  height: auto;
  text-align: center;
}
.text {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  flex-direction: column;
}
/*.medical-content {*/
  /*display: flex;*/
/*}*/
.home-header {
  color: white;
  margin-bottom: 2%;
  font-weight: 600;
  font-size: 18px;
}

.home-sub-header {
  margin:7px;
}

.resource-button {
  height: 170px;
  width: 220px;
  background-color: transparent;
  display: inline-block;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.web-side-panel {
  display: flex;
  justify-content: center;
  color: #ffffff;
}
.m-side-panel{
  display: flex;
  justify-content: center;
  color: #ffffff;
}
.header-wrap.header-display{
  display: none;
}

@media only screen and (max-width: 600px) {
  .overlayContent {
    top: 55%;
    -ms-transform: translateY(-55%);
    transform: translateY(-55%);
  }
}

@media only screen and (max-height: 700px) {
  .resource-button {
    height:125px;
  }
}
@media screen and (min-width: 320px) and (max-width: 768px){
  .content-header .close{
    /*display: none;*/
  }
}
@media screen and (min-width: 320px) and (max-width: 1200px){
  .header-wrap.header-display {
    display: flex;
    height: 56px;
    position: absolute;
  }
}
@media screen and (min-width: 320px) and (max-width: 1200px){
  .m-side-panel{
    display: none;
  }
  .main-screen{
    width: 100%;
  }
 /* .header-wrap.header-display{
    display: flex;
  }*/
}