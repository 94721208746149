.program-form-container {
  width: 100%;
  /*height: auto;*/
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.checkbox-div span{
  color: #F29223;
  border-radius: 5px;
}

.program-form-header-img{
  width: 100%;
}

.program-form-header-img img{
  height: 100%;
  width: 10%;
}

.form-header {
  padding: 2%;
  font-size: 37px;
  width: 100vw;
  text-align: center;
  color: #245397;
}

.login-details{
  width: 100%
}

.form-container {
  margin: auto;
  width: 75%;
}

.form {
  border-radius: 10px;
  box-shadow: 0px 5px 21px 1px rgba(0,0,0,0.25);
  /*display: flex;*/
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  background-color: #ffffff;
}

.program-form-group {
  width:100%;
  padding-bottom: 0;
  margin-bottom: 15px;
}

.schedule-phone .program-form-group{
  padding: 0px 0px;
}
.business-profileadmin-program-button-group{
  display: flex;
  justify-content: flex-end;
  padding: 8px 15px;
  background: white;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius:0 0 5px 5px;
}

.business-profileadmin-program-button-group button {
  background: #F29223;
  border: none;
  color: #fff;
  width: 25%;
}
.new-program-form {
  border-radius: 10px;
  box-shadow: 0px 5px 21px 1px rgba(0,0,0,0.25);
  /*display: flex;*/
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  background-color: #F8F9F9;
}
.new-program-form .new-program-form-group {
  width:100%;
  margin-top: 15px;
  margin-bottom: 0%;
}
.MuiCheckbox-root-749 {
  color: #CACCD2 !important;
}
.ql-toolbar.ql-snow{
  border: none !important;
  
}

.new-program-form .new-program-form-group header{ width: 100% !important; box-shadow: none !important; }
.new-program-form .new-program-form-group.program-tabs-height{ margin-top: 0 !important; }

.quill .ql-editor{ box-shadow: none !important; }
.ql-container.ql-snow{ height: auto !important; border:none !important; }
.tab-color-Health{ background-color: transparent !important; }


.ql-snow .ql-formats{
  padding: 4px 2px;
    background: #FFFFFF;
    border: 1px solid #CACACA;
    border-radius: 5px !important;
}

.new-program-form .new-program-form-group header,.new-program-form .new-program-form-group .swipable-view{
  width: 95%;
  margin: auto;
}

.new-program-form.program-tabs-height .new-program-form-group header{
  width: 100%;
}


.new-program-form-group #select-state{
  padding: 15.5px 14px;
}

.right-padding-10{
  padding-right: 10%;
}

.right-padding-5{
  padding-right: 5%;
}

.new-program-form-input{
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  background-color: #ffffff;
  height: 100% !important;
}

.info-icon {
  height: 16px;
  width: 18px;
  margin-left: 5px;
  cursor: pointer;
  color:#245497;
}

.button-container{
  width: 40%;
}

.composite-field{
  display: flex;
  height: auto;
  position: relative;
}
.composite-field header{
  position: absolute;
}
.composite-field .swipable-view{
  margin-top: 73px;
}

.composite-field .categories-data label{
  width: 24%;
}

.composite-language-field{
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  padding: 0% 2%;
  height: auto;
  flex-direction: row ;
  flex-wrap: wrap;
}
.composite-language-field span{
  font-weight: bold;
}
.newlist-title{
  color: #FFFFFF;
  font-weight: bold;
  font-size: 2.2em;
  text-align: center;
  width: 100%;
}
.newsublist-title{
  color: #FFFFFF;
  font-weight: bold;
  font-size: 1.5em;
  text-align: center;
  width: 100%;
}

.required-label {
  color:red;
}
.uploaded-image{
  margin-bottom: 2%;
  width: auto;
  height: 150px;
}

.thumbnail-container{
  height: 100%;
  width: 200px;
  border: 1px solid grey;
  position: relative;
}

.thumbnail-image{
  height: 100%;
  object-fit: contain;
}

.remove-image{
  position: relative;
  left: 150px;
}

.image-overlay {
  position: absolute;
  top:0;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.65);
  width: 100%;
  height: 100%;
  display: none;
}

.subcategory-container {
  display: inline-block;
  width:100%;
  /*flex-direction: column;*/
  padding: 15px;
  background-color: #f6f6f6;
}

.filter-sub-category {
  padding: 20px 0 20px 30px;
}
.filter-sub-category label{
  height: 30px;
  width: 90%;
}
.subcategory-option {
  font-size: 14px;
  color: #545860;
  font-style: Roboto;
}
.filter-sub-category label .MuiPrivateSwitchBase-root-154 {
padding: 8px 12px;
}
.operation{
  display: flex;
  padding: 0 4%;
  border-bottom: 1px solid #DEE1E5;
}
.operation >label{
  width: 140px;
}
.operation >label span{
  font-weight: bold;
}
.button-label{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content:space-between;
}
.timing-collapse{
  width: 100%;
}

.timing-container{
  display:flex;
  align-items: center;
}
.timing-container .program-timing{
  flex-direction: column;
  display:flex;
  width: 100%;
  text-align: center;
}
.program-timing .hr-operation {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 10px;
}

.timing-container .program-timing .from ,.timing-container .program-timing .to {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  color: #A6ABB6;
}
.timing-container .program-timing .inputbox {
  min-width: 114px;
  max-width: 114px;
  margin: 0 15px;
  font-weight: normal;
  background: #F8F9F9;
}
.timing-container .program-timing .inputbox:focus div{
  border: none;
  outline: none;
}

/*@media (min-width: 1280px) {*/
  /*.program-timing > div > div > div {*/
    /*max-width: 100%;*/
    /*flex-basis: 100%;*/
  /*}*/
/*}*/

@media (min-width: 1440px) {
 /* .program-timing > div > div > div {
    max-width: 50%;
    flex-basis: 50%;
  }*/
}

.custom-stepper-wrap{
  margin:20px 0px 10px 0px;
}

.program-timing-field {
  display: flex;
}
.thumbnail-container:hover .image-overlay{
  display: inherit;
}


.error-message {
  margin-top: 3%;
  color: #c70b1b;
}



.name-group {
  width:100%;
  display: inline-flex;
}

.form h1 {
  font-weight: 600;
}

.div-wrapper{
  width:100%;
  padding: 1% 4% 4%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.div-wrapper.emp-title{
  background: #fff;
  height: 78px;
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.12);
  border-radius: 5px 5px 0 0;
  padding: 0 6%;
  margin-bottom: 1px;
}

.div-wrapper .add-more {
  width: 90%;
}
.div-wrapper .add-more textarea{
  width: 100%;
}
.div-wrapper .add-more input{
  padding: 10.5px 14px;
}
.div-wrapper .add-more svg{
  width: 20px;
  height: 20px;
}
.active-icon-circle {
  background: #6FA963;
  border-radius: 50%;
  padding: 6px;
}

.active-icon-done {
  background: #6FA963;
  border-radius: 50%;
  padding: 3px;
}

.uncomplete-icon-div {
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 0px 7px;
}

.parrent-div-wrapper {
  padding: 2% 15%;
}
.emp-title{

}
.btn-weapper{
  display: flex;
  justify-content: space-between;
  padding: 8px 15px;
  background: white;
  box-shadow: 0 -5px 5px -5px #b5b5b5;
  border-radius:0 0 5px 5px ;
}
.btn-weapper .btnMobile,.btn-weapper .btnMobile:hover{
  background: transparent;
  border: 2px solid #CACACA;
  color: #474747 !important;
}
.btn-weapper .btnMobile > span{
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}
.btn-weapper .back-btn svg{
  color: #165593
}

.btn-weapper .btnMobile.next{
  background: #F29223;
  border: none;
  color: #fff!important;
}
.btn-weapper .btnMobile:hover{
  box-shadow: 0 0 11px rgba(33,33,33,.2);
}

.custom-stepper{
  width:50%;
}
.title-each{
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: #545860;
  margin: auto;
}

/*.step-completed{*/
  /*color: green;*/
  /*padding: 0;*/
/*}*/

.step-remaining{
  color: #255397;
  padding: 0;
}

.outer-div-wrapper {
  /*padding: 0 15%;*/
}
.program-form-lable{

}
.program-form-group div:nth-child(1) {
  min-height: 25px;
  width: 100%;
  text-align: left;
}
.program-form-group label{
  color: black;
  top:0;
}
.new-program-form-group .program-best {
  width: 24%;
  float: left;
  color: #474747;
}

.program-split-hours {
  width:43%;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  margin-bottom:0;
}
.timing-wrapper.program-timing-wrap .program-split-hours label{
  width: 90%;
}
.timing-wrapper.program-timing-wrap .program-split-hours label span{
  font-weight: 600;
  color: #545860;
}
/*.program-split-hours label span {
  color:black;
}*/

.timing-wrapper{
  /*border-bottom: 1px solid lightgrey;*/
  margin-bottom: 1%;
  padding: 40px 56px 50px 56px;
}

.program-form-group fieldset{
  border-color: #dadada !important;
}

.program-form-inputborder{

}

.timing-checkbox {
  color:black;
  display: flex;
  justify-content: space-evenly;
}

.sub-title{
  text-align: center;
  color: #000;
  fontSize: 17px;
}

.d-flex{
  display: flex;
}

.without-padding input {
  padding: 10px 14px;
}
.without-padding-select {
  padding: 10px 14px !important;
  min-height: 0!important;
}
.composite-field .program-category .program-type-0,
.composite-field .program-category .program-type-1,
.composite-field .program-category .program-type-2{
  background-color: transparent;
  width:100%;
  margin:0 !important;
}
.composite-field .program-category .program-type-0 span,
.composite-field .program-category .program-type-1 span,
.composite-field .program-category .program-type-2 span{
 color: #545860;
}
.composite-field > div{
  width: 100%;
}
.composite-field .program-category .category-title{
  font-size: 18px;
  font-weight: 600;
}

.schedule-phone .program-category .category-title{
  font-size: 16px;
  font-weight: 600;
  color: white;
}

.subcategory0{
  border: 2px solid #245397;
}
.subcategory1{
  border: 2px solid #1fb400;
}
.subcategory2{
  border: 2px solid #808080;
}
.subcategory3{
  border: 2px solid #bf1a05;
}

.program-type-checkbox {
  display: none !important;
  background-color: white;
}

.composite-field .program-category{
  margin-bottom: 10px;
  flex: 1;
  height: 48px;
  border-right: 1px solid #A6ABB6;
  background: white;
}
.composite-field .program-category:last-child{
  border: none;
}

.schedule-title {
  background-color: #fff;
  width: 100%;
  margin: 0 !important;
  color: white;
  padding: 15px;
  margin-bottom: 1px !important;
  box-shadow: 0 8px 6px -6px #cccccc;
  border-radius: 5px;
  background-color: #3eaaee;
}

.schedule-title-active {
  background-color: #ececec;
  width: 100%;
  margin: 0 !important;
  color: white;
  padding: 15px;
  box-shadow: 0 8px 6px -6px #cccccc;
  border-radius: 5px;
  background-color: #F29223;
}

.program-category .schedule-title ,.program-category .schedule-title-active{
  position:relative;
}
.program-category .schedule-title svg ,.program-category .schedule-title-active svg{
  color: white;
  font-size: 18px;
  font-weight: 600;
  position: absolute;
  right: 15px;
}
.error-div{
  margin-left: 40px;
  margin-top: 10px;
}

.middle-title{
  display: flex;
  justify-content: center;
}

.btn-single-weapper {
  display: flex;
  justify-content: flex-end;
  padding: 8px 15px;
  background: white;
  box-shadow: 0 -5px 5px -5px #b5b5b5;
  border-radius: 0 0 5px 5px;
}
.btn-single-weapper .btnMobile ,.btn-single-weapper .btnMobile:hover{
  background: #F29223;
  border: none;
  color: #fff;
}
.btn-single-weapper .btnMobile > span{
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}
.submit-title{
  font-size: 60px;
  font-weight: bolder;
  text-align: center;
  color: #255397;
  margin:0;
}


.submit-message{
  font-size: 23px;
  font-weight: bolder;
  text-align: center;
  color: #000000;
}
.check-circle{
  font-size: 115px;
}

.check-mark{
  padding:0;
  text-align: center;
  font-size: 46px;
  color: #1fb400;
  margin:0;
}
.outer-div-wrapper-submit {
  padding:10% 15%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.schedule-phone{
  /* display: none; */
}
.schedule-pc{
  display: block;
  background-color: #F8F9F9;
  max-height: calc(100vh - 250px);
  overflow-y: auto;
}

@media screen and (max-width: 576px) {
  .schedule-pc {
    max-height: calc(100vh - 270px);
  }
}


.schedule-pc > div > div{
  transition: transform 1s cubic-bezier(0.15, 0.3, 0.25, 1) 0s !important;
}

.business-profile-admin-form-container .business-profile-header{
  background: #fff;
  height: 78px;
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.12);
  border-radius: 5px 5px 0 0;
  padding: 0 3%;
  margin-bottom: 1px;
  font-weight: bold;
}

.program-form-group .input-box {
  width: 86%;
  background: #fff;
  box-shadow: 0 8px 6px -6px #bfbfbf;
  height: 45px;
  margin: 0;
}

.bottom-title {
  text-align: center;
  margin: 60px auto;
}
.without-padding-select-cities {
  padding: 5px 14px !important;
  min-height: 0 !important;
}

.select-div {
  min-height: 47px !important;
  align-items: center;
  display: flex;
}
.schedule-error-message {
  margin-top: 15px;
  text-align: center;
  color: red;
}
.program-timing-wrap{
  padding: 0px;
}

.without-padding {
  margin-bottom: 0 !important;
  height: auto !important;
  background: #FFFFFF;
}
.schedule-pc-desktop header{
  border-radius: 5px 5px 0 0 ;
}

.schedule-pc-desktop header button{
  box-shadow: 0 8px 6px -6px black;
  height: 60px;
  border-right:1px solid #A6ABB6 ;
  max-width: inherit;
  flex: 1;
  background: #F7F7F7;
  /*border-radius: 5px 0 0 0;*/
}
.schedule-pc-desktop header button:first-child{
  border-radius: 5px 0 0 0 ;
}
.schedule-pc-desktop header button:last-child{
  border-radius: 0 5px 0 0  ;
}

.schedule-pc-desktop header button span{
  font-weight: bold;
}
.schedule-pc-desktop header button.activeTabClass{
  background: #FFFFFF;
  color: #545860;
  position: relative;
  min-width: 65px;
}
.activeTab ,.activeTabClass{
  background: #ffffff !important;
  color: #545860 !important;
  position: relative;
  min-width: 65px;
}
.schedule-pc-desktop header button.activeTabClass:after,.activeTab :after,.activeTabClass{
  content: '';
  position: absolute;
  background-color: #f8f9f9;
  width: 100%;
  height: 9px;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.swipable-view{
  background: #F8F9F9;
}
.admin-program-button-group {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

.admin-program-button-group .btn-saveandpub{
  background: #F29223;
  color: #fff;
  margin: 1% 0%;
  width: 30%;
  box-shadow: none;
  padding: 10px;
  font-weight: bold;
  max-width: 190px;
}

body .div-wrapper .company-logo {
  margin-bottom: 1%;
  box-shadow: none;
  background-color: transparent;
}
.admin-program-button-group .btn-saveanddra{
  background: rgb(255, 255, 255);
  color: #474747;
  padding: 10px;
  margin: 1%;
  border: 1px solid #CACACA;
  box-shadow: none;
  font-weight: bold;
}
.admin-program-button-group .btn-saveanddra svg{
  margin: 0 5px ;
  color: #6FA963;
}

.about-description-box{
  width: 90%;
}
.quill {
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
border-radius: 5px;
}

.quill .ql-editor{
  box-shadow: none;
  max-height:80px;
  background:#FFFFFF;
  min-height:80px;
  border: none;
}
.tab-main{
  justify-content: space-between;
  border-radius: 5px;
}

.newTimePicker{
  padding: 10px;
  border-radius: 10px;
}

/* 
.tab-main .activeTab:nth-of-type(1), .tab-main .active-tab:nth-of-type(1), .activeTab:nth-of-type(1) span:after {
  background: #29abe2 !important;
  color: #fff !important;
}

.activeTab:nth-of-type(1) span .category-icon, .active-tab:nth-of-type(1) span .category-icon{
  border: 1px solid #fff;
  border-radius: 4px
}
.tab-main .activeTab:nth-of-type(2), .tab-main .active-tab:nth-of-type(2), .activeTab:nth-of-type(2) span:after {
  background: #6fa963 !important;
  color: #fff !important;
}

.activeTab:nth-of-type(2) span .category-icon, .active-tab:nth-of-type(2) span .category-icon  {
  border: 1px solid #fff;
  border-radius: 4px
}
.tab-main .activeTab:nth-of-type(3), .tab-main .active-tab:nth-of-type(3), .activeTab:nth-of-type(3) span:after {
  background: #da6a6a !important;
  color: #fff !important;
} */

.activeTab:nth-of-type(3) span .category-icon, .active-tab:nth-of-type(3) span .category-icon {
  border: 1px solid #fff;
  border-radius: 4px
}
/* .tab-main .activeTab:nth-of-type(4), .tab-main .active-tab:nth-of-type(4), .activeTab:nth-of-type(4) span:after {
  background: #165593 !important;
  color: #fff !important;
} */

.activeTab:nth-of-type(4) span .category-icon, .active-tab:nth-of-type(4) span .category-icon  {
  border: 1px solid #fff;
  border-radius: 4px
}


.program-tabs-height .tab-main button.active-tab{
  background-color: #fff !important;
  color: black !important;
  border-bottom: 2px solid #c6c6c6cc;
  border-right: 1px solid #c6c6c6cc !important;
}

.div-wrapper .add-more{margin: 10px 0 0;}
.div-wrapper .add-more svg {    transform: rotateX(
  190deg
  );}



.tab-main .teb-cont{
  max-width: inherit;
  border-right: 1px solid #A6ABB6;
  font-weight: bold;
}
.tab-main .teb-cont:last-child{
  max-width: inherit;
  border-right:none;
}
/* .tab-main .active-tab:nth-of-type(1) {
  background: #29abe2 !important;
  color: #fff !important;
} */
.tab-main button span {
  display: contents;
}
.category-width label{
  /*width: 24%;*/
}
.tab-color-Health {
  background-color: #c9eaf8;
}

.tab-color-Food {
  background-color: #dbe9d8;
}

.tab-color-Housing {
  background-color: #f6dada;
}

.tab-color-Education {
  background-color: #c5d4e4;
}

.up-icon {
  width: 35px;
  height: 35px;
  background: #29ABE2;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
}
.direction-parent-div > div {
  padding-right:5px ;
}
.direction-icon-padding >div{
  padding-right: 5px;
}
.direction-icon-padding >div input{
  height: 47px;
  padding: 0 15px;
}
.direction-icon {
  width: 35px;
  height: 35px;
  background: #29ABE2;
  color: #fff;
  padding: 8px;
  border-radius: 5px;
}
.add-more .directions-div , .add-more .youtube-div {
  margin-top: 1%;
}
.directions-div > div ,.youtube-div > div{
  padding-right: 5px;
  background: white;
  height: 47px;
}
.program-form-container .stepper-width {
  width: 65%;
  background-color: transparent;
}
.program-tabs-height .tab-main button {
  height: auto;
  min-height: 50px;
  padding: 0;
  min-width: auto;
  flex-grow: 1;
  border-right: none !important;
}
.program-tabs-height .tab-main button .category-icon {
  margin-top: 0;
  margin-right: 15px;
}


.schedule-pc{ background-color: #fff; }

@media screen and (min-width: 1024px) and (max-width: 2560px) {
  .form-container {
    margin: auto;
    width: 78%;
  }
  .outer-div-wrapper{
   /* padding: 0px 10%;*/
  }

}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .container {
    /*height: auto;*/
    /*width: 100vw;*/
    /*padding-bottom: 10%;*/
  }
  .btnMobile{
    width:25% !important;
  }
  .btn-weapper{
    padding: 0 15px;
  }
  .program-info-timestamp {
    padding: 0 15px;
    font-size: 1rem;
    margin-bottom: 10%;
  }
  .btn-single-weapper{
    padding: 0 15px;
    margin-bottom: 10%;
  }
  .form-container {
    width: 100%;
    margin: auto 10%;
  }
  .form {
    border: none;
    margin-top: 75px;
  }

  .new-program-form-group{
    margin-bottom: 15px;
  }
  .timing-container{
    flex-direction: column;
    align-items: flex-start;
  }
  .program-timing{
    width: 100%;
  }
  .program-split-hours{
    margin:0;
  }
  .button-container{
    width: 60%;
  }
  .form-header{
    padding: 20% 2% 2% 2%;
  }
  .uploaded-image {
    width: 100%;
  }

  .custom-stepper {
    display: none;
  }
  .outer-div-wrapper{
    padding: 0;
  }
  .title-each{
    font-size: 24px;
    margin: 20px 5px;
  }
  .schedule-pc{
    /* display:none; */
  }
  .schedule-phone{
    display:block;
  }
}
@media (min-width: 960px){
          .res_tab{
            padding: 6px 0px;
            font-size: 12px;
          }
}
@media screen and (max-width: 768px){
  .form {
    border: none;
    margin-top: 15%;
  }
  .program-tabs-height .tab-main button {
      min-height: 65px !important;
      border-right: 1px solid #ddd;
  }
  .program-tabs-height .tab-main button:last-child {
    border-right: none;
  }
  .program-tabs-height .tab-main button .category-icon {
    margin-right: 0;
  }
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .tab-main button span {
    display: block;
  }
  .swipable-view >div{
   height: auto !important;
  }
  .program-form-container .stepper-width {
    width: 100%;
  }
  .container {
    /*height: auto;*/
    /*width: 100vw;*/
    /*padding-bottom: 10%;*/
  }
  .form-container {
    width: 100%;
    margin: auto 20px;
  }
  .btnMobile{
    width:25% !important;
  }
  .newlist-title{
    margin-top: 50px !important;
  }

  .btn-single-weapper ,.btn-weapper{
    padding: 15px;
  }

  .program-info-timestamp {
    padding: 0 15px;
    margin-bottom: 10%;
    font-size: 1rem;
  }
  .form {
    border: none;
    margin-top: 75px;
  }
  .div-wrapper .add-more ,.admin-program-form-group > textarea{
    width: 100%;
  }
  .timing-container{
    flex-direction: column;
    align-items: flex-start;
    background: #f8f9f9;
  }
  .program-timing .hr-operation ,.program-split-hours{
    justify-content: left;
    flex-wrap: wrap;
  }
  .program-timing .hr-operation .from, .program-timing .hr-operation .to {
    width: 100%;
  }
  .program-timing .hr-operation .from .label-text, .program-timing .hr-operation .to .label-text {
    width: 80px;
    text-align: left;
  
      }
      .program-timing .hr-operation div  {
        width: 100%;
      }
  .program-timing{

    width: 100%;
  }
  .timing-container .program-timing .inputbox {
    min-width: unset;
    margin: 0 !important;
    flex: 1;
  }
  .program-split-hours{
    margin:0;
    width: 100%;
  }
  .button-container{
    width: 60%;
  }
  .form-header{
    padding: 20% 2% 2% 2%;
  }
  .uploaded-image {
    width: 100%;
  }
  .custom-stepper {
    display: none;
  }
  .outer-div-wrapper{
    padding: 0;
  }
  .div-wrapper.emp-title{
    height: auto;
  }
  .title-each{
    font-size: 24px;
    margin: 20px 5px;
  }
  .swipable-view label{
    width: 48% !important;
  }
  .sub-title{
    text-align: center;
    color: #000;
    font-size: 15px;
    margin-bottom: 70px;
  }
  .schedule-pc{
    display:block;
  }
  .schedule-phone{
    display:block;
  }
  .composite-language-field {
    flex-wrap: wrap;
    padding: 0;
  }
  .new-program-form-group .program-best{
    width: 48%;
  }
  .middle-title {
    justify-content: left;
  }
  .outer-div-wrapper-submit{
    padding: 5% 10%;
  }
  .operation{
    flex-wrap: wrap;
  }
  .submit-title{
    font-size: 38px;
  }
  .submit-message{
    font-size: 16px;
  }
  .timing-wrapper {
    padding: 0;
    border:0;
  }
  .program-timing-field{
    flex-direction: column;
  }
  .timing-collapse{
    background-color: #f6f6f6;
  }
  .check-circle {
    font-size: 70px;
  }
  .select-div {
    min-height: 0px !important;
  }
  .business-profileadmin-program-button-group button{
  width: 100%;}
  .program-form-group .input-box {
    width: 100%;
  }
  .schedule-pc-desktop{
    display: none;
  }
  .admin-program-button-group .btn-saveandpub{
    width: 100%;
  }
  .composite-field .categories-data label{
    width: 49%;
  }
  .right-padding-10 , .right-padding-5{
    padding: 0;
  }
  .direction-icon-padding >div input{
    height: 47px;
  }

  }
@media screen and (max-width: 768px) {
  .select-div {
    min-height: 38px !important;
    align-items: center;
    display: flex;
  }
  .composite-field {
    margin-top: 10px;
  }
  .newlist-title {
    font-size: 22px;
  }
  .newsublist-title {
    font-size: 12px;
  }
  .div-wrapper {
    /* max-height: calc(100vh - 390px); */
    overflow-x:hidden;
    scroll-behavior: smooth;
  }
  .program-form-container {
    height: auto !important;
  }
  .quill {
    width: 100% !important;
  }
  .bottom-title {
    margin: 30px auto !important;
  }
  .tab-main button {
    min-height: 45px !important;
  }
  .tab-main button span img {
    margin-right: 4px;
  }
  .tab-container-div .activeTabClass:nth-of-type(1) {
    background: #29abe2 !important;
    color: #fff !important;
  }

  .activeTabClass:nth-of-type(1) span .category-icon, .activeTabClass:nth-of-type(2) span .category-icon, .activeTabClass:nth-of-type(3) span .category-icon,
  .activeTabClass:nth-of-type(4) span .category-icon{
    border: 1px solid #fff;
    border-radius: 4px
  }
  .tab-container-div .activeTabClass:nth-of-type(2){
    background: #6fa963 !important;
    color: #fff !important;
  }


  .tab-container-div .activeTabClass:nth-of-type(3){
    background: #da6a6a !important;
    color: #fff !important;
  }


  .tab-container-div .activeTabClass:nth-of-type(4) {
    background: #165593 !important;
    color: #fff !important;
  }




}


@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .program-form-group fieldset{
    margin-top: 4px;
  }
  .new-program-form-group fieldset{
    margin-top: 4px;
  }
}

@media screen and (min-width: 320px) and (max-width: 375px) {
  .program-tabs-height .tab-main button span > div {
   font-size: 11px;
  }
}