.signup-center-div{
  height: auto;
  background: #ffffff;
  min-height: max-content;
  border-radius: 5px;
  width: 420px;
  position: relative;
  top: 50%;
  left: 50%!important;
  transform: translate(-50%, -50%);
}
.signup-admin-form-container{
  padding: 2%;
  background: #F8F9F9;
  max-height: 68vh;
  overflow: auto;
  border-radius: 0 0 5px 5px;
}

.signup-center-div .close-icon {
  position: absolute;
  right: -39px;
  background: #A6ABB6;
  border-radius: 0 5px 5px 0;
  top: 8px;
  height: 40px;
  width: 40px;
}
.signup-center-div .close-icon button, .signup-center-div .close-icon button:hover{
  background: transparent;
  color: #FFFFFF;
  padding: 8px;
}
.signup-center-div .modal-title {
  background: #fff;
  border-radius: 5px 5px 0 0;
  height: 60px;
  display: flex;
  justify-content: left;
  padding: 0 6%;
  align-items: center;
  margin: 3px 0;
  box-shadow: 0 6px 15px -8px #a7a7a7;
}
.signup-center-div .modal-title h2{
  margin: 0;
  font-weight: bold;
  color: #545860;
}

.bottom-title {
  text-align: center;
  margin: 60px auto;
}

.admin-container{
  margin-top: 0px;
  background: #f4f4f4;
}

.signup-header-img img{
  width: 10%;
}
.signup-admin-program-form-group{
  width: 100%;
  padding:0 5%;
}
.signup-admin-program-form-group .signup-btn button{
  background: #F29223;
  width: 100%;
  border-radius: 3px;
  margin: 0 0 10px;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  border-color: transparent;
}
.signup-admin-program-form-group .signup-btn button:hover{
  box-shadow: 0 0 11px rgba(33,33,33,.2);
  background: #F29223;
  color: #fff;
  border-color: transparent;
}
.program-form-group{
  margin-bottom: 1%;
}
.div-wrapper .input-boxes {
  border: 1px solid transparent;
}
.div-wrapper .input-boxes:hover{
  border: 1px solid #787878;
}
.validation-div {
  margin: 10px 0;
}

.program-form-group h3{
  font-size: 24px;
  font-weight: 600;
  color: #245497;
  text-align: center;
}

.admin-side-bar{
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 1;
}

.signup-admin-container {
  background-color: #f4f4f4;
  height: 100vh;
}
@media screen and (max-width: 1024px) and (min-width: 320px){
  .signup-admin-form-container{
    max-height: calc(100vh - 150px);
  }
}

@media screen and (max-width: 920px) and (min-width: 771px) {
  .signup-center-div {
    width: 280px;
  }
}
@media screen and (max-width: 767px) and (min-width: 320px){
  .signup-center-div{
    width: 100%;
  }
  .signup-center-div{
    width: 100%;
    max-width: 100%;
  }
  .signup-center-div .close-icon {
    top: 10px;
    right: 0;
    background: transparent;
  }
  .signup-center-div .close-icon button, .signup-center-div .close-icon button:hover{
    color: #545860;
  }
  .signup-admin-form-container{
    max-height: calc(100vh - 150px);
  }
}
.success_sign_up{
  z-index: 999999999 !important;
  top: 100px !important;
  position: fixed !important;
}