.content-container {
  width: 420px;
  display: flex;
  flex-direction: column;
  background-color: white;
  /*max-width: 420px;*/
  height: 100%;
  /*direction: rtl;*/
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
}
.content-header .close_1 {
  position: absolute;
  right: 10px;
  top: 1px;
  color: #fff;
  z-index: 999;
  cursor: pointer;
  border: none;
  background-color: transparent;
  left: 1px;
  display: flex;
  justify-content: flex-end;

}


.content-header p.cross {
  background: #165593;
  width: 35px;
  height: 35px;
  color: #fff;
  border-radius: 5px;
  font-size: 20px;
  padding: 2px;
}
.content-header p{
  margin: 0;
}
.img-icon-flyer{
  height: 20px;
  width: 20px;
}

.text-align-div {
  align-items: center;
}

.img-icon-donation {
  height: 15px;
  width: 15px;
  margin-right: 10px;
}

.content-header {
  display: block;
  direction: ltr;
  position: relative;
}

.div-wrap-img {
  display: flex;
  border: 1px solid #CACACA;
  border-radius: 5px;
  padding: 12px;
  font-size: 13px;
  color: #474747;
  font-weight: bold;
  cursor: pointer;
  width: 170px;
  height: 50px;
  min-width: 170px;
}

.img-size {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.content-middle {
  direction: ltr;
}

.content-middle-child {
  padding: 5% 8%;
}

.content-middle-child-shadow {
  background: #FFFFFF;
  padding: 5% 8%;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.12);
  border-bottom: 2px solid #DEE1E5;
  display: flex;
  /*justify-content: center;*/
}
.content-middle-child-shadow span {
  width: 50px;
  height: 50px;
}
.title-track {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.font-family-roboto p ,.icon-container-div p ,.info-container p{
  margin-bottom: 0;
}

.content-footer {
  width: 100%;
  display: none;
  justify-content: center;
  background-color: #245497;
  border-top: 1px solid #245497;
}

.day-div {
  display: flex;
  align-items: center;
  line-height: 30px;
  font-size: 14px;
}

.day-div{
  color: rgba(0,0,0,.65);
}

.business-name {
  font-size: 18px;
  font-weight: bold;
  color: #165593;
  margin-bottom: 1px;
}

.rating {
  margin-right: 5px;
  color: orange;
  font-weight: normal;
}

.alignment {
  align-items: center;
}

.address {
  padding-top: 4%;
  font-size: 14px;
  display: flex;
  padding-right: 1%;
}
.address a:hover, .contact-detail div a:hover {
  text-decoration: underline !important;
  color: #165593;
}
.contact-detail {
  padding-top: 4%;
  font-size: 14px;
  display: flex;
  cursor: pointer;
}

.contact-detail h5 {
  margin-bottom: 1%;
  font-size: 14px;
}

.contact-detail-icons {
  margin-right: 5%;
  font-size: 18px;
  color: #A6ABB6;
}

.gray-color-icon {
  color: #A6ABB6 !important;
}

.text-color-style {
  color: #29ABE2;
}

.info-container {
  /*margin-left: 1.3%;*/
}

.hide-info-container {
  display: none;
}

.info-icon {
  cursor: pointer;
}

.info-container-header {
  margin-top: 15px;
}

.info-container h4 {
  margin-bottom: 0;
}

.div-seprator {
  border-bottom: 1px solid #DEE1E5;
}

.more-info-header{
  font-weight: 600;
  font-size: 13px;
  color: #165593;
}

.info-content {
  font-size: 14px;
  color: #545860;
}
.info-content-donation,.info-content-donation:hover{
  background: #165593;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  text-align: center;
  margin: 0 auto;
  display: block;
  width: 100%;
  text-decoration: none;
  padding: 10px;
}

.info-content .youtube:nth-child(2){
  padding-bottom: 0px;
}

.youtube{
  display: flex;
  flex-direction: column;
}

.content-header .image-container {
  width: 100%;
  height: 235px;
  min-height: 150px;
  display: inline-block;
  cursor: pointer;
}

.image-map-container {
  width: 49%;
  min-height: 150px;
  display: inline-block;
}

.empty-image-container {
  display: none;
  padding: 0px 80px !important;
}
.icon-container{
  display: flex;
  justify-content: space-evenly;
  font-size: 29px;
  color:#29ABE2;
  align-items: center;
  padding: 5% 8%;
  background-color: #F8F9F9;
  border-bottom: 1px solid #DEE1E5;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 0px 0px 1px 1px;
}
.icon-container p{
  margin-bottom: 0;
}

.dot-div {
  height: 6px;
  width: 6px;
  background: #6FA963;
  border-radius: 3px;
  margin-right: 5px;
}

.dot-red-div {
  background: #DA6A6A;
}

.language-div {
  border-radius: 5px;
  padding: 2px 10px;
  color: #fff;
  background: #F29223;
  font-size: 14px;
  margin-right: 5px;
}

.edit-section {
  padding: 5% 8%;
  font-size: 14px;
  color: #165593;
  border-top: 1px solid #DEE1E5;
  font-weight: 600;
}

.edit-sub-section {
  margin-top: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.edit-section-icon {
  display: flex;
  margin-right: 10px;
  font-size: 18px;
  align-items: center;
  justify-content: center;
}

.globe-icon{
  display: flex;
  flex-direction: column;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
}

.globe-icon span{
  line-height: 0;
  font-size: 20px;
  cursor: pointer;
}

.globe-icon svg{
  font-size: 18px;
}

.edit-content {
  display: inline-block;
  width: 85%;
  vertical-align: middle;
  color: #29ABE2;
  font-size: 14px;
  font-weight: normal;
}
.edit-content p {
  margin-bottom: 0;
}

.image-map-span {
  width: 100%;
  height: 30vh;
}

.edit-content :first-child {
  line-height: 1;
}

.edit-content :first-child:hover {
  color: #165593 !important;
  text-decoration: underline;
}
.company-img {
  /*max-height: 255px;*/
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.edit-content :nth-child(2) {
  font-size: 11px;
  color: gray;
}

.edit-content :nth-child(3) {
  font-size: 11px;
  margin-left: 1%;
  margin-top: 0;
}

.image-map-container .gmnoprint a, .gmnoprint span {
  display:none;
}

.image-map-container .gmnoprint div {
  background:none !important;
}

.image-map-container #GMapsID div div a div img{
  display:none;
}

.image-map-span .gmnoprint a, .gmnoprint span {
  display:none;
}

.image-map-span .gmnoprint div {
  background:none !important;
}

.margin-less{
  margin-left: 0 !important;
}

.image-map-span #GMapsID div div a div img{
  display:none;
}

.description-section {
  padding: 5% 8%;
  background: #F7F7F7;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.12);
}
.service-section h4 {
  margin-bottom: 0;
}
.description-section p ,.description-section div{
  color: #545860;
}

.file-upload {
  display: none;
}

.bold-today {
  font-weight: 600;
}

.notification-content{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%
}

.icon-container-div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid #DEE1E5;
  background: #F8F9F9;
}
.icon-container-div a:hover ,.icon-container-div p:hover, .globe-icon:hover ,.globe-icon:hover svg{
  text-decoration: underline;
  color: #165593 !important;
}

.number-of-total-rating {
  margin-left: 5px;
  color: #A6ABB6;
}

.number-of-total-rating:hover {
  cursor: pointer;
}

.content-wrapper-read-more {
  position: relative;
}

.content-read-less {
  height: 40px;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.read-more-div {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #29ABE2;
  padding-top: 2px;
}
.read-more-div:hover {
  color: #165593;
  text-decoration: underline;
}
.content-read-more-content {
  color: #29ABE2;
  max-width: 75px;
  cursor: pointer;
}
content-read-more-content:hover {
  text-decoration: underline;
}
.content-text-input-div{
  margin-top: 5px;
  margin-bottom: 0px;
  min-height:0px;
  overflow: hidden;
}
@media screen and (min-width: 320px) and (max-width: 425px) {
  .notification-popover:before{
    left: 81%;
  }
  body .quill .ql-snow .ql-tooltip{
    white-space: unset;
  }
  .div-wrap-img {
    width: 140px;
    font-size: 10px;
    align-items: center;
    margin: 5px 0;
  }

  .icon-button-div{
    padding: 2px !important;
  }
  .menuItem-div button p.cross, .said-icon button p.cross {
    width: 30px;
    height: 30px;
    padding: 0px;
    margin-right: 6px;
  }
  .searched-results .resourceContainer {
    padding: 10px 10px;
  }
  .globe-icon{
    font-size: 10px;
  }
  .icon-container-div p a:hover {
    color: #165593;
  }
  .content-middle-child ,.description-section ,.edit-section{
    padding: 5% 4%;
  }
  .admin-main .data-title .export-wrap button {
    padding: 4px;
    margin-right: 7px!important;
    font-size: 11.5px;
  }

}

@media screen and (min-width: 601px) and (max-width: 767px) {
  .content-middle {
    /* padding-top: 27%; */
  }
}

@media screen and (min-width: 320px) and (max-width: 780px) {
  .content-container {
    width: 100%;
    /* height: calc(100vh - 69px);*/
    height: 100vh;
    /*margin-bottom: 45px;*/
  }
  .content-header {
    display: flex;
  }

  .content-footer{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .image-container {
    position: relative;
    /*max-height: 150px;*/
  }
  .image-container span {
    position: absolute;
    right: 0;
    bottom: 4px;
    left: 10px;
    color: #fff;
    font-weight: 800;
  }
  .edit-section-last {
    margin-bottom: 140px !important;
  }
}

.res_det{
  position: fixed;
  z-index: 922222222;
  width: calc(41vw + 10px);
  height: 100vh;
  overflow-y: scroll;
}

@media (max-width: 1200px) {
  .res_det{
    width: 100vw;
  }
}

@media screen and (min-width: 320px) and (max-width: 468px) {
  .content-header {
    display: block;
    position: unset;
  }
}

@media screen and (min-width: 768px) {
  .image-container {
    width: 100%;
    position: relative;
    min-height: unset;
  }
  .image-container span{
    position: absolute;
    right: 0;
    bottom: 8px;
    left: 0;
    margin-left: 10px;
    font-size: 15px;
    font-weight: 700;
    color: #fff;
  }
  .image-map-container {
    display: none;
  }
  .image-map-span {
    display: none;
  }
  .content-footer {
    display: none;
  }
}

@supports (-ms-flow-from: thingy) { /* Edge only */
  .icon-container{
    justify-content: space-around;
    padding: 0px 30px;
  }

  .content-container {
    -ms-overflow-style: scrollbar;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .icon-container{
    justify-content: space-around;
    padding: 0px 30px;
  }
}

.g-wrap{
  position: fixed;
  z-index: 9999999999999999;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #f4f4f4;
}