.program-info-admin-container {
  width: 98%;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 40px;
  margin: auto;
}
.wrapper-main-div {
  padding-top: 1%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  /*flex-wrap: wrap;*/
}
.wrapper-category-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 30%;
}

.admin-program-form-group.categoryHeight header, .admin-program-form-group.categoryHeight .swipable-view{
  width: 100% !important;
  box-shadow: none !important;
}
.admin-program-form-group.categoryHeight .swipable-view{
  padding-left: 20px; padding-right: 20px;
  width: 94% !important;
}

.edit_loader {
  text-align: center;
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.schedule-wrap.header-out {
  padding: 0 4%;
}

.reviews-wrap .border-radius-paper,.schedule-wrap.header-out .border-radius-paper {
  border-radius: 5px;
  margin-top: 0;
}

.admin-login-details{
  width: 100%
}
.reviews-wrap{
  margin-top: 10px;
}
.schedule-phone.admin-page .data-set{
  display: flex;
}

.program-info-admin-form-container {
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
}

.admin-program-form-group .category-width label{
  /*width: 24%;*/
}

.admin-form {
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}

.admin-program-form-group {
  width:100%;
  margin-top: 0;
  margin-bottom: 1%;
}
.admin-program-form-group .input-box > div,.admin-program-form-group > textarea {
  width:100%;
  background: #fff;
  box-shadow: 0 8px 6px -6px #bfbfbf;
  height: auto;
  margin: 0;

} 
.div-wrapper{     padding:25px 25px 4%;} 
.company-logo label{padding-left: 15px;}
.admin-program-form-group .input-box{
  padding-right: 15px;
  padding-left: 15px;
}
.admin-program-form-group .input-box > div{ background: none; box-shadow: none;  }
.admin-program-form-group .input-box .MuiInputBase-root-444{
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
  border-radius: 5px;
  background-color: #fff;
}

.admin-program-form-group .input-box .MuiPrivateNotchedOutline-root-711{ border: none; border-radius: 5px; }

.admin-program-form-group .input-box > div input{
  padding: 13.5px 14px;
}
.admin-info-icon {
  height: 16px;
  width: 18px;
  margin-left: 5px;
  cursor: pointer;
}

.admin-button-container{
  width: 40%;
}

.admin-composite-field{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
}

.admin-uploaded-image{
  margin-bottom: 2%;
  width: auto;
  height: 150px;
}

.activeTab .drop-down-mobile .form-group-tab {
  display: block;
  margin-bottom: 5px;
  margin-right: 20%;
  margin-top: 8px;
}

.activeTab .drop-down-mobile .form-group-tab input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.activeTab .drop-down-mobile .form-group-tab label {
  position: relative;
  cursor: pointer;
}

.activeTab .drop-down-mobile .form-group label:before ,.activeTabClass .drop-down-mobile .form-group label:before{
  content:'';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 4px;
}
.activeTab .drop-down-mobile .form-group input + label:after {
  background: transparent !important;
}
.activeTab .drop-down-mobile .form-group input:checked + label:after ,.activeTabClass .drop-down-mobile .form-group input:checked + label:after{
  content: '';
  display: block;
  position: absolute;
  top: 2px;
  left: 7px;
  width: 6px;
  height: 12px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  background: transparent !important;
  transform: rotate(45deg);
  border-radius: 4px;
}

 .drop-down-mobile .form-group-tab {
  display: block;
  margin-bottom: 5px;
   margin-top: 8px;
}

 .drop-down-mobile .form-group-tab input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

 .drop-down-mobile .form-group-tab label {
  position: relative;
  cursor: pointer;
}

 .drop-down-mobile .form-group label:before {
  content:'';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #a7a7a7;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
   border-radius: 4px;
}
 .drop-down-mobile .form-group input + label:after {
  background: transparent !important;
}
 .drop-down-mobile .form-group input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 2px;
  left: 7px;
  width: 6px;
  height: 12px;
  border: solid #a7a7a7;
  border-width: 0 2px 2px 0;
  background: transparent !important;
  transform: rotate(45deg);
   border-radius: 4px;
}




.admin-thumbnail-container{
  height: 100%;
  width: 200px;
  border: 1px solid grey;
}

.admin-thumbnail-image{
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.admin-remove-image{
  position: relative;
  left: 150px;
}

.img-overlay{
  position: relative;
  bottom: 0px;
}

.custom-upload{
  margin-bottom: 15px;
}

.w-100{
  width: 100%;
}

.tab-container-div{
  display: block;
}
.admin-image-overlay {
  position: relative;
  bottom: 148px;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.65);
  width: 100%;
  height: 100%;
  display: none;
}
.categoryHeight{
  height: 400px;
  overflow-y: auto;
}
.admin-program-form-group.categoryHeight header, .admin-program-form-group.categoryHeight .swipable-view{
  width: 95%;
  margin: auto;
}

.admin-program-form-group.categoryHeight header + div >div{
  height: auto !important;
}
/* 
.admin-program-form-group .tab-color-education{
  background-color: #c5d4e4;
}

.admin-program-form-group .tab-color-health{
  background-color: #c9eaf8;
}

.admin-program-form-group .tab-color-food{
  background-color: #dbe9d8;
}

.admin-program-form-group .tab-color-housing{
  background-color: #f6dada;
} */

.admin-subcategory-container {
  display: flex;
  flex-direction: column;
}

.admin-program-form-group{margin-bottom: 20px ;}


.schedule-pc-desktop header button.activeTabClass::after, .activeTab ::after, .activeTabClass{
  background-color: #fff !important;
}
.schedule-pc-desktop header button{ border-radius:5px 5px 0 0 ; }

.unClaim-button-wrapper{
  text-align: center;
}

.admin-button-label{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content:space-between;
}

.admin-timing-container{
  display:flex;
  align-items: center;
}
.categoryHeight.div-width-wrap label{
  width: 24%;
}

.admin-program-timing{
  display:flex;
  flex-direction: column;
  width: 50%;
}

.admin-thumbnail-container:hover .admin-image-overlay{
  display: inherit;
}

.admin-program-split-hours{
  margin-left: 20%
}

.admin-error-message {
  margin-top: 3%;
  color: #c70b1b;
}

.program-info-admin-program-button-group {
  display: flex;
  justify-content: flex-start;
}
.program-info-timestamp {
  padding: 0 30px;
  font-size: 1rem;
}
.admin-form h1{
  font-weight: 600;
  width: 45%;
}

.name-group {
  display: inline-flex;
}

.profile-title {
  display: inline-flex;
  width: 100%;
}

.ml-2{
  margin-left: 10px;
}

.schedule-wrap{
  /*padding: 24px 0px;*/
  padding: 0px 24px 24px 24px;
}

.program-info-admin-program-form-group{
  padding: 0px 24px;
  margin-bottom: 3%;
  width: 100%;
}

.tab-container-div{
  display: flex;

}

.category-icon {
  margin-right: 17px;
  margin-top: -32px;
}

.composite-language-field-program-info{
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
}

.company-logo label{
  color: #545860;
  font-weight: normal;
}

.program-form-group textarea{
  width: 100%;
  height: 80px;
}

.drop-down-mobile svg {
  margin-left: 15px;
}

.company-logo h4{
  color: #545860;
}

.validation-text {
  color: #545860;
  font-weight: 500;
}

.validation-text p{
  margin-top: 10px;
  margin-bottom: 0;
}


.program-tabs-height .tab-main button {
  height: auto;
  min-height: 60px;
  padding: 0;
}
.program-tabs-height .tab-main button .category-icon {
  margin-top: 0;
  margin-right: 15px;
}


@media screen and (min-width: 320px) and (max-width: 767px) {
  .program-info-admin-container {
    height: auto;
    padding-bottom: 10%;
    padding: 5px;
    width: 100%;
  }
  .wrapper-main-div {
    flex-wrap: wrap;
  }
  .wrapper-category-div{
   width: 100%;
  }
  .flyer-div-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
  }
  .flyer-div-wrap div{
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .admin-form {
    border: none;
  }
  .program-info-admin-program-form-group{
    margin-bottom: 3%;
  }
  .admin-timing-container{
    flex-direction: column;
    align-items: flex-start;
  }
  .admin-program-timing{
    width: 100%;
  }
  .admin-program-split-hours{
    margin:0;
  }
  .admin-button-container{
    width: 60%;
  }
  .admin-uploaded-image {
    width: 100%;
  }
  .program-info-admin-program-button-group {
    flex-direction: column;
    width: 100%;
  }
  .admin-form h1{
    font-size: 20px;
    width: 80%;
  }
  .schedule-phone.admin-page .data-set{
    display: flex;
    flex-wrap: wrap;
  }
  .categoryHeight{
    margin-top: 20px;
    padding-bottom: 20px;
  }
  .category-icon{
    margin-right: 0;
    margin-top: 0 !important;
  }

  .tab-container-div button {
    min-height: 65px !important;
    border-right: 1px solid #ddd;
  }
  .tab-container-div button:last-child {
    border-right: none;
  }
  .categoryHeight.div-width-wrap label{
    width: 100%;
  }
  .drop-down-mobile svg {
    margin-left: 5px;
  }
}
.schedule-phone .admin-page {
  margin: 0 10px;
  margin-top: 10px;
}

.country-flag {
  width: 29px;
}

.select-div #select-email{
  width: 100%;
}
/*.swipable-view .react-swipeable-view-container {
  height: auto !important;
}*/
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  /* .country-flag{
    .country-flag?
  } */
}
@media screen and (min-width: 320px) and (max-width: 375px) {
  .tab-container-div button {
    min-height: 68px !important;
    min-width: auto;
    padding-top: 0 !important;
    font-size: 11px;
  }
}