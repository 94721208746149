.outer-div {
  background-color: #ffffff;
  padding: 5%;
  width: 100vw;
  height: auto;
  min-height: 100vh;
  overflow-x: hidden;
}

.program-container{
  background-color: white;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.action-btn {
  width: 22%;
  margin-bottom: 2%;
  display: flex;
  justify-content: space-evenly;
}


@media screen and (min-width: 320px) and (max-width: 767px) {
  .outer-div {
    width: 100vw;
    height: auto;
    padding: 5% 0 0 0;
    overflow-x: hidden;
  }
  .action-btn {
    width: 70%
  }
}