.pageTemplate-container {
  height: calc(100vh - 103px);
  display: block;
  width: 100%;
}
.map-container {
  height: 100%;
  width: calc(100% - 420px);
  position: absolute;
  display: inline-block;
}

.sidePanel-container {
  display: inline-block;
  height: 100%;
  width: 420px;
}

.notification-content{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%
}


@media screen and (min-width: 320px) and (max-width: 768px) {
  .map-container {
    display: none;
  }
  .sidePanel-container {
    width: 100%;
    height: 100vh;
  }
  .content-container {
    overflow-y: auto !important;
  }
  .presentation{
    z-index: 1300!important;
  }
}
@media screen and (min-width: 769px) and (max-width: 1366px) {
  /* .sidePanel-container {
    width: 33.33%;
  }
  .map-container {
   width: calc(100% - 33.33%);
  } */
  .content-container {
    overflow-y: auto !important;
  }
}


.map-wrap{
  position: relative;
  height: 100%;
}
.template-withMap .sidePanel-container {
  width: 41vw;
}
.template-withMap .menu-view.div-wrap> div{
  width: 41vw;
}
.gm-fullscreen-control,.gmnoprint{
  display: none !important;
}
.open_map.template-withMap .abc{
  display: none;
}

.open_map.template-withMap .map-wrap{
  display: block;
}
.map-wrap{
  height: calc(100vh - 103px);
}

.resource-details .content-container{
  width: 41%;
}

@media (max-width: 1200px) {
  .template-withMap .map-wrap{
    display: none;
  }
  .template-withMap .sidePanel-container{
    width: 100%;
  }
  .template-withMap .menu-view.div-wrap> div{
    width: 100%;
  }
  .map-wrap{
    height: calc(100vh - 215px);
  }
  .map-wrap .view-switch{
    display: none;
  }
}

.pdf-name{
  overflow: hidden;
}
@media (max-width: 900px) {
  .map-wrap{
    height: calc(100vh - 250px) !important;
  }
}