body {
  background-color: #fff;
  min-height: 100vh;
  width: 100%;
  z-index: 1;
  overflow-x: hidden;
  font-family: Roboto !important;
}
.abc{
  z-index: 1;
}
.bg-white {
  background: #FFFFFF !important;
}
.p-0 {
  padding: 0 !important;
}
.wrapper{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  overflow: hidden;
  max-width: 100vw;
}
.modal-main-div{
  width: calc(100vw - 420px);
  height: auto;
  padding: 6%;
  margin-left: 420px;
}
.business-modal {
margin-left: 0;
}

/* .modal-main-div > div { */
  /* left: 420px;
  margin: 0 auto; */
/* } */
.business-profile-admin-container {
  /* position: relative;
  left: 50%;
  transform: translateX(-50%); */
  width: 100%;
}

.header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  background-color: #616161;
}
.footer {
  max-height: 250px;
}
.main-screen{
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-positive: 1;
      flex-grow: 1;
  overflow: auto;
  background-color: #165593;
  background-repeat: no-repeat;
}
.app-btn {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  border-top: 1px solid #245497;
  background-color: #245497;
  color: white;
  font-weight: 600;
}
.full-width-btn{
  background: #F29223;
  width: 100%;
  border-radius: 3px;
  margin: 10px 0;
  color: #fff;
}
.pagination-div{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}
.full-width-btn:hover{
  box-shadow: 0 0 11px rgba(33,33,33,.2);
  background: #F29223;
  color: #fff;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .footer {
    display: none;
  }
  .header {
    display: none;
  }
  .app-btn{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .mobile-btn{
    display: none;
  }
  .wrapper{
    display: block;
  }
  .main-screen{
    display: block;
    width: 100%;
  }
  .image-map-container .map{
    display: block;
  }
  .map{
    display: none;
  }
  .modal-main-div > div ,.modal-main-div{
    margin-left: 0 !important;
    left: 0 ;
  }
  body .modal-main-div{
    width: 100%;
  }
}

@media screen and (min-width: 320px) and (max-width: 1200px){
  .main-screen{
    position: absolute;
    right: 0;
  }
  body .header-display{
    right: 05px;
    top: 0;
  }
}
::-webkit-scrollbar {
  width: 0.5em;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: #165593;
  outline: 1px solid slategrey;
}

.MuiInputBase-input-445{
  border:none !important;  
}

.MuiOutlinedInput-root-712 .MuiOutlinedInput-notchedOutline-719{
  border-color:transparent !important;  
}
.pl15{ padding-left: 15px; }


.menuItem-div button p.cross, .said-icon button p.cross, .said-icon-wh button p.cross{ background: none!important; color: #165593 !important;
   }

.custom-cluster img{
    display: none;
}

.custom-cluster{
}

.custom-cluster >div{
  height: 48px !important;
  width: 48px !important;
  background: #C64D18;
  border-radius: 50%;
  line-height: 48px !important;
}

.custom-cluster > *{
  color: #fff !important;
  font-weight: 700 !important;
  font-size: 16px !important;
}

.MuiMenu-paper-739{ max-height: 200px !important; }

@media screen and (min-width: 1024px) and (max-width: 2560px){

.form-container {
    margin: auto;
    width: 96% !important;
}
}



.MuiPrivateTabIndicator-colorSecondary-153{
  display: none !important;
}

.video-container {
    overflow: hidden;
    position: relative;
    width:600px;
    max-width: 100%;
}

.video-container::after {
    padding-top: 56.25%;
    display: block;
    content: '';
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.login {
  height: auto;
  background: #ffffff;
  min-height: -webkit-max-content;
  min-height: max-content;
  border-radius: 5px;
  width: 420px;
  position: relative;
  top: 50%;
  left: 50% !important;
  -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
}
.user-login {
  padding: 5%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
  -ms-flex-align: center;
      align-items: center;
  background: #F8F9F9;
  border-radius: 0 0 5px 5px;
}
.login .close-icon {
  position: absolute;
  right: -40px;
  background: #A6ABB6;
  border-radius: 0 5px 5px 0;
  top: 8px;
  height: 40px;
  width: 40px;
}
.login .close-icon button ,.login .close-icon button:hover{
  background: transparent;
  color: #FFFFFF;
  padding: 8px;
}
.login .modal-title {
  background: #fff;
  height: 78px;
  border-radius: 5px 5px 0 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: left;
      justify-content: left;
  padding: 0 5%;
  -ms-flex-align: center;
      align-items: center;
  margin: 3px 0;
  box-shadow: 0 6px 15px -8px #a7a7a7;
  border-radius: 5px 5px 0px 0px;
}
.login .modal-title h2{
  margin: 0;
  font-weight: bold;
  color: #545860;
}
.login .field-container > div{
  width: auto;
  margin: 5px 0 15px;
}


.btn-section{
  width: 100%;
}
.btn-section .login-btn{
  background: #F29223;
  width: 100%;
  border-radius: 3px;
  margin: 10px 0;
  color: #fff;
}
.btn-section .login-btn:hover{
  box-shadow: 0 0 11px rgba(33,33,33,.2);
  background: #F29223;
  color: #fff;
}

.modal-footer{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  padding: 10px 0;
}

.google {
  margin-top: 3%;
}

.error {
  color: #c70b1b;
  margin: 0;
}
.forgot {
  color: blue;
  text-decoration: underline;
  margin-top: 5px;
}
.forgot:hover{
  cursor: pointer;
}
@media screen and (max-width: 920px) and (min-width: 771px) {
  .login {
    width: 280px;
  }
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .login{
    width: 100%;
    max-width: 100%;
    top: 40%;
  }
  .login .close-icon {
    top:20px;
    right: 0;
    background: transparent;
  }

  .login .close-icon button ,.login .close-icon button:hover{
    color: #545860;
  }
}
.password-main-container{
  background: #ffffff;
}

.password-modal-container {
  height: auto;
  padding: 1% 6% 6% 6%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}

.modal-field {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 1% 0;
}

.password-error{
  margin-top: 1%;
  color: #bf0708;
}

.password-message{
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: center;
      justify-content: center;
}

.password-success{
  color: #008000;
}

.password-failed {
  color: #bf0708;
}
.menu-container {
  z-index: 10;
  margin: 5px;
  position: fixed;
  right: 1%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.notification-wrap-div {
  overflow: auto;
}

.menu-popover {
  position: absolute;
  z-index: 1111;
  top: 103px;
  background: #FFFFFF;
  height: 100%;
}


/*.MuiPopover-paper-62{*/
/*  left: 0 !important;*/
/*  top: 0 !important;*/
/*  width: 420px !important;*/
/*  min-height: 100vh !important;*/
/*  background: #fff;*/
/*}*/
.underline-div {
  margin: 0px !important;
  padding: 10px 50px !important;
}
.bottom-div {
  position: fixed;
  bottom: -30px;
  width: 100%;
}
.border-last-div {
  border-bottom: 0 !important;
}
.div-wrap {

}
.copy-right-div{
  height: 80px;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  position: absolute;
  width: 100%;
  color: #A6ABB6;
  position: absolute;
  background: #FFFFFF;
  box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.12);
}
.MuiList-padding-397{
  padding: 0;
}
.MuiMenu-paper-52,
.MuiMenu-paper-392{
  width: 418px;
  top: 0px !important;
  left: 1px !important;
}
.input-div {
  background-color: white;
  border-radius: 5px;
  width: 300px;
  padding: 10px;
  position: absolute;
  z-index: 999;
  right: 0;
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 139ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  opacity: 1;
  box-shadow: 1px 5px 15px #888888;
}

.input-non-focused {
  opacity: 0;
  transition: opacity 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 139ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.category-collapse-wrapper{
  padding:0 2px;
  margin: 5px 0;
}

.search-apply-filter{
  width: 100%;
  text-align: center;
  font-weight: 600;
}

.business-profile-header-img img{
  width: 50% !important;
  height: auto;
}

.toogleMenu { position: absolute !important; left: 0; margin-top: -4px ​!important;  }

.business-wrap .business-profile-header-img{
  text-align: center;
}

.business-wrap .wrap-notification{
  -ms-flex-align: center;
      align-items: center;
  margin: 0px 20px;
  border-bottom: 1px solid #CACCD2;
  padding: 8px 30px;
  font-size: 20px;
  color: #A6ABB6;
}
.business-wrap .noauth{
  -ms-flex-align: center;
      align-items: center;
  margin: 0px 20px;
  padding: 8px 30px;
  font-size: 20px;
  color: #A6ABB6;
}
.business-wrap .wrap-notification:hover, .business-wrap .noauth:hover ,.business-wrap .wrap-notification.active-menu,.business-wrap .noauth.active-menu  {
  margin: 0px 0px;
  padding: 8px 50px;
  background: #29ABE2;
  color: #FFFFFF;
}
.business-wrap .wrap-notification svg{
  width: 20px;

}
.business-wrap .wrap-notification:hover li, .business-wrap .noauth:hover li ,.wrap-notification:hover li .youtube-channel ,.business-wrap .wrap-notification.active-menu li, .business-wrap .noauth.active-menu li{
  color: #ffffff;
  background: transparent;
}
.business-wrap .wrap-notification li:hover,.business-wrap .wrap-notification:hover li:focus{
  background: transparent;
}

.business-wrap .wrap-font ,.business-wrap .wrap-font:focus {
  font-weight: bold;
  color:#545860;
  font-style: normal;
  font-size: 16px;
  width: 100%;
  background: transparent;
}
.business-wrap .wrap-font a{
  width: 100%;
}
.menu-view.div-wrap div:focus{
  outline: none;
}
.menu-view{
  z-index: 33;
}
.menuItem-div {
  text-align: end;
  position: absolute;
  right: 0;
}
.menuItem-div:focus{
  outline: none;
}

.menu-wrap{
  padding: 0px 0px 35px 0px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);
  background-color: #FFFFFF;
}

.notification-popover {
  width: auto;
  position: relative;
  border-top: 2px solid #165593;
}

.notification-popover:before{
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-right: 12px solid #165593;
  right: 50px;
  top: -20px;
  -ms-transform: rotate(90deg);
      transform: rotate(90deg);
}
.notification-popover{
  padding: 10px;
  width: 390px;
  max-width: 390px;
  background: #F8F9F9;
  border-radius: 5px 5px 0px 0px;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.12);
  margin-bottom: 2px;
}
body .quill .ql-snow .ql-tooltip{
  left: 0!important;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: left;
      justify-content: left;
}
body .quill .ql-snow .ql-tooltip.ql-hidden{
  display: none;
}
.notification-popover h3{
  font-size: 14px;
  font-weight: 600;
  margin-left: 15px;
}
.notification-footer {
  background: #FFFFFF;
  padding: 10px;
  width: auto;
  background: #FFFFFF;
  box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.12), 0px -1px 2px rgba(0, 0, 0, 0.12);
  border-radius: 5px 5px 0px 0px;
  -ms-transform: matrix(1, 0, 0, -1, 0, 0);
      transform: matrix(1, 0, 0, -1, 0, 0);
}

.manage-alert-div-wrap >span {
  color: #165593;
  font-size: 12px;
}
.manage-alert-div-wrap span.new-alert-label {
  color:#A1A1A1 ;
  font-size: 12px;
}
.manage-alert-div-wrap {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  margin: 10px;
  -ms-transform: matrix(1, 0, 0, -1, 0, 0);
      transform: matrix(1, 0, 0, -1, 0, 0);
}

.manage-alert-div-wrap button ,.manage-alert-div-wrap button:hover{
  color: white;
  background: #F29223 !important;
  font-size: 12px;
  font-weight: bold;
}
.notification-item {
  padding: 5px;
  margin: 10px 0;
  font-size: 12px;
  font-family: Roboto;
  border-bottom: black;
  -ms-flex: 6 1;
      flex: 6 1;
}
.noti-div-wrap svg, .noti-div-wrap-last svg{
  -ms-flex: 1 1;
      flex: 1 1;
  font-size: 14px;
  font-weight: 900;
  color: #29ABE2;
}
.menu-img-tag {
  height: 22px;
  width: 25px;
}
.icon-button-div {
  padding: 5px !important;
  /*position: absolute !important;
  right: 0;*/
}
.icon-button-div:hover {
  background-color: transparent !important;
}
.menu-last-div {
  background: #F8F9F9;
  margin-top: 3px;
}
.noti-div-wrap:hover {
background-color: #CACCD2;
cursor: pointer;
}
.noti-div-wrap {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
  -ms-flex-align: center;
      align-items: center;
  border-bottom: 1px solid #D7D8DD;
}
.noti-div-wrap-last {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
  -ms-flex-align: center;
      align-items: center;
}
.menu-view.div-wrap{
  width: 100%;
  min-height: calc(100vh - 103px);
  height: 100%;
  overflow: auto;
}

.menu-view.div-wrap> div{
  width: 420px;
}
.said-icon button p ,.menuItem-div button p{
  font-size: 20px;
  margin: 0 2px;
  color: #9A9EA7;
}

.said-icon-wh button p{ 
  font-size: 20px;
  margin: 0 2px;
  color: white;
}

.menuItem-div button p.cross ,.said-icon button p.cross, .said-icon-wh button p.cross {
  background: #165593;
  width: 40px;
  height: 40px;
  color: #fff;
  padding: 5px;
  border-radius: 8px;
  margin-right:5px;
}
.youtube-menu-icon{
  margin: 0;
}
.youtube-menu-icon svg{
  width: 25px;
  height: 25px;
}
.youtube-channel{
  color: #545860;
}


@media screen and (min-width: 320px) and (max-width: 768px){
  .menu-popover ,.menu-view.div-wrap> div {
    width: 100%;
  }
  .MuiPopover-paper-62{
    max-width: 100%;
    width: 100% !important;
  }
  .business-wrap .wrap-notification{
    margin: 0px 15px;
  }
  .notification-popover{
    width: 100%;
  }
  .notification-popover:before{
    left: 75%;
  }
  .menu-view.div-wrap{
    width: 100%;
  }
  .menu-view.div-wrap.menu-hide-div{
    display: none;
  }
  .mobile-pop-up div + div {
    right: 10px;
    left: auto !important;
    width: 280px;
  }
  .notification-wrap-div{
    max-width: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 330px;
  }
  .business-profile-header-img img{
    width: auto !important;
    height: auto;
  }

  .toogleMenu { position: absolute !important; left:10px; right: auto;} 
}

@media (max-width: 1200px) {
  .menu-popover {
    top: 75px;
    height: 100%;
    position: fixed !important;
  }
  .header-bar_fixed{
    position: fixed;
    width: 100%;
    z-index: 999999;
    top: 0;

  }

}
.main-contactform-container {
  height: auto;
  background: #ffffff;
  max-height: 100%;
  min-height: -webkit-max-content;
  min-height: max-content;
  border-radius: 5px;
  width: 420px;
  margin: 0 auto;
  /* position: relative;
  left: 50% !important;
  top: 25%;
  transform: translate(-50%, 0%); */
}

.main-contactform-container .close-icon {
  position: absolute;
  right: -40px;
  background: #A6ABB6;
  border-radius: 0 5px 5px 0;
  top: 8px;
}
.main-contactform-container .close-icon button{
  height: 40px;
  width: 40px;
  color: white;
  padding: 8px;
}
.main-contactform-container .close-icon button:hover{
  background: #A6ABB6;
}
.main-contactform-container .close-icon >div{
  color: #fff;
}

.contactform-container {
  overflow-y: auto;
  padding: 1% 6% 6% 6%;
  background-color: #f8f9f9;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: justify;
      justify-content: space-between;
  border-radius: 0 0 5px 5px;
  max-height: calc(100vh - 250px);
}
.main-contactform-container .modal-title {
  background: #fff;
  height: 78px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.12);
  border-radius: 5px 5px 0 0;
  padding: 0 6%;
  margin-bottom: 1px;

}
.main-contactform-container .modal-title h2{
  margin: 0;
  font-weight: bold;
  color: #545860;
  font-size: 24px;
}
.field-container {
  max-height: 70vh;
  margin: 2% 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  width: 100%;
}
.contactform-container .error{
  border: none;
  box-shadow: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  height: auto;
  margin-top: 10px;
}
.error p{
  margin: 0 0 0 5px;
}
.field-container label{
  font-size: 16px;
  color: #545860;
}
.field-container > div ,.field-container > div:focus{
  height: 40px;
  margin-top: 9px;
  border: 1px solid #dadada;
  padding: 0 1%;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}
.field-container > div:hover{
  border: 1px solid #787878;
}
.field-container > div.error:hover{
  border: none;
  box-shadow: none;
}

.contactform-container button ,.contactform-container button:hover{
  width: 80%;
  margin: 10px auto;
  background: #F29223;
  height: 38px;
}

.ql-align-center {
  text-align: center;
  list-style-position: inside;
}

.ql-align-right {
  text-align: right;
  list-style-position: inside;
}

.ql-align-justify {
  text-align: justify;
  list-style-position: inside;
}

.ql-size-huge {
  font-size: 2.5em;
}

.ql-size-large {
  font-size: 1.5em;
}

.ql-size-small {
  font-size: 0.75em;
}

.disclaimer-modal-container::-webkit-scrollbar {
  width: 5px;
  border-right: 1px solid #808080;
}

.disclaimer-modal-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}
.main-modal {
  position: relative;
} 
@media screen and (max-width: 920px) and (min-width: 771px) {
   .main-contactform-container{
    width: 280px;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .main-contactform-container{
    max-width: 100%;
    width: 300px;
  }
  
  .main-contactform-container .close-icon {
    top: 4%;
    right: 0;
    background: transparent;
  }
  .main-contactform-container .close-icon button{
    color: #545860;
  }
  .contactform-container { 
    max-height: calc(100vh - 200px);
  }
}

.list-div{
  margin: 10px 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: start;
      align-items: flex-start;
}

.list-div a{
  color: #2fc5ff;
  cursor: pointer;
}

a:hover{
  /*text-decoration: underline;*/
}

.list-img-div{
  height: auto;
  width: 60%;
}

.list-img-div img{
  height: 100%;
  width: 100%;
  object-fit: contain;
}

@media screen and (min-width: 320px) and (max-width: 760px){
  .list-img-div{
    height: auto;
    width: 100%;
  }
}

.business-profile-admin-container .close-icon {
  position: absolute;
  right: -48px;
  background: #A6ABB6;
  border-radius: 0 5px 5px 0;
  top: 8px;
}
.business-profile-admin-container .close-icon button ,.business-profile-admin-container .close-icon button:hover{
  color: #FFFFFF;
  background: transparent;
}

.admin-login-details{
  width: 100%
}
.business-profile-admin-form-container .schedule-pc .div-wrapper{
padding: 2% 0%;
}
.business-profile-admin-form-container {
  width: 80%;
  margin: auto;
  background-color: white;
  border-radius: 5px;
  position: relative;
  /* left: 50%; */
  /* transform: translateX(-50%) !important; */
  }

.admin-form {
  padding: 2%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.business-profile-admin-program-form-group {
  width:100%;
  margin-bottom: 3%;
}
.admin-info-icon {
  height: 16px;
  width: 18px;
  margin-left: 5px;
  cursor: pointer;
}

.admin-button-container{
  width: 40%;
}

.admin-composite-field{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  height: auto;
}

.admin-uploaded-image{
  margin-bottom: 2%;
  width: auto;
  height: 150px;
}

.admin-thumbnail-container{
  height: 100%;
  width: 200px;
  border: 1px solid grey;
}

.admin-thumbnail-image{
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.admin-remove-image{
  position: relative;
  left: 150px;
}

.img-overlay{
  position: relative;
  bottom: 0px;
}

.custom-upload{
  margin-bottom: 15px;
}

.w-100{
  width: 100%;
}

.admin-image-overlay {
  position: relative;
  bottom: 148px;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.65);
  width: 100%;
  height: 100%;
  display: none;
}
.admin-subcategory-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}

.admin-button-label{
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack:justify;
      justify-content:space-between;
}

.admin-timing-container{
  display:-ms-flexbox;
  display:flex;
  -ms-flex-align: center;
      align-items: center;
}

.admin-program-timing{
  display:-ms-flexbox;
  display:flex;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 50%;
}

.admin-thumbnail-container:hover .admin-image-overlay{
  display: inherit;
}

.admin-program-split-hours{
  margin-left: 20%
}

.admin-error-message {
  margin-top: 3%;
  color: #c70b1b;
}

.business-profile-admin-program-button-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: start;
      justify-content: flex-start;
}

.admin-form h1{
  font-weight: 600;
  width: 45%;
}

.select-input{
  text-align: center;
  margin-left: 10px;
  width: 135px;
}

.user-level-div{
  -ms-flex-align: center;
      align-items: center;
}
.user-level-div .input-box > div{
  width: 100%;
  margin: 0;
  min-height: 45px !important;
}

.user-level-label{
  font-size: 17px;
  color: #000;
}

.business-profile-admin-container{
  margin-top: 0px;
  height: 100vh;
}

.name-group {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.profile-title {
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 100%;
}

.ml-2{
  margin-left: 10px;
}

.admin-side-bar{
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 1;
}

.schedule-wrap{
  /*padding: 24px 0px;*/
  padding: 0px 24px 24px 24px;
}

.business-profile-header{
  font-size: 25px;
}

.business-profile-admin-program-form-group{
  padding: 0px 24px;
  margin-bottom: 3%;
  width: 100%;
}

.composite-language-field{
  padding: 10px 20px;
  margin-top: 0px;
}

.program-form-group textarea{
  width: 100%;
  height: 80px;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
.modal-main-div.business-modal{
  padding-bottom: 200px !important;
}

  .business-profile-admin-container {
    height: auto;
    width: 100%;
    padding-bottom: 10%;
  }
  .business-profile-admin-form-container {
    -ms-transform: none;
        transform: none;
    width: 90% !important;
    height:auto;
    margin: 0 auto;
  }
  .business-profile-admin-container .close-icon{
    right: 0;
    background: transparent;
    top: 2%;
  }
  .business-profile-admin-container .close-icon button{
    color: #545860;
  }
  .admin-form {
    border: none;
  }
  .business-profile-admin-form-container .schedule-pc{
    display: block;
  }
  .business-profile-admin-program-form-group{
    margin-bottom: 3%;
  }
  .business-profile-header{
    font-size: 25px;
  }
  .admin-timing-container{
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: start;
        align-items: flex-start;
  }
  .admin-program-timing{
    width: 100%;
  }
  .admin-program-split-hours{
    margin:0;
  }
  .admin-button-container{
    width: 60%;
  }
  .admin-uploaded-image {
    width: 100%;
  }
  .business-profile-admin-program-button-group {
    -ms-flex-direction: column;
        flex-direction: column;
    width: 100%;
  }
  .admin-form h1{
    font-size: 20px;
    width: 80%;
  }
}
.schedule-phone .admin-page {
  margin: 0 10px;
  margin-top: 10px;
}

.country-flag {
  width: 29px;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  /* .country-flag{
    .country-flag?
  } */
}
@media screen and (min-width: 1024px) {
  .business-modal {
    margin-left: 420px !important;
    width: calc(100% - 420px) !important;
  }
}
.main-modal-container{
  width: 100%;
  background-color: rgb(255, 255, 255);
  margin: auto;
  border-radius: 5px;
}

.popup-image {
  height: 100%;
  width: 100%;
  object-fit: contain;
  border: 1px solid #aaa;
}

.popup-image-container{
  height: 100%;
  width: 100%;
  margin: auto;
  padding: 1% 6% 6% 6%;
}
.main-modal {
  height: auto;
  background: #ffffff;
}

.review-modal{
  padding: 6%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.review-sub-container{
  border: 1px solid #000;
  border-radius: 5px;
  height: auto;
}

.review-subject{
  text-align: left;
  padding: 1%;
  border-bottom: 1px solid black;
}

.review-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 100%;
  padding: 1%;
  overflow-wrap: break-word;
  height: auto;
}

.reply-container{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 100%;
  padding: 2% 0;
}

.reply-actions {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

@media screen and (max-width: 920px) and (min-width: 771px) {
  .main-modal {
    width: 280px;
  }
}
.main-modal {
  height: auto;
  background: #ffffff;
  border-radius: 5px;
}

.question-modal{
  padding: 6%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.question-sub-container{
  border: 1px solid #000;
  border-radius: 5px;
  height: auto;
}

.question-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 100%;
  padding: 1%;
  overflow-wrap: break-word;
  height: auto;
}

.answer-container{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 100%;
  padding: 2% 0;
}

.answer-user{
  text-align: left;
  padding: 1%;
  border-bottom: 1px solid black;
}

.answer-actions {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}
.program-info-admin-container {
  width: 98%;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 40px;
  margin: auto;
}
.wrapper-main-div {
  padding-top: 1%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
  -ms-flex-align: start;
      align-items: flex-start;
  /*flex-wrap: wrap;*/
}
.wrapper-category-div {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 30%;
}

.admin-program-form-group.categoryHeight header, .admin-program-form-group.categoryHeight .swipable-view{
  width: 100% !important;
  box-shadow: none !important;
}
.admin-program-form-group.categoryHeight .swipable-view{
  padding-left: 20px; padding-right: 20px;
  width: 94% !important;
}

.edit_loader {
  text-align: center;
  display: -ms-flexbox;
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  height: 100%;
  width: 100%;
}

.schedule-wrap.header-out {
  padding: 0 4%;
}

.reviews-wrap .border-radius-paper,.schedule-wrap.header-out .border-radius-paper {
  border-radius: 5px;
  margin-top: 0;
}

.admin-login-details{
  width: 100%
}
.reviews-wrap{
  margin-top: 10px;
}
.schedule-phone.admin-page .data-set{
  display: -ms-flexbox;
  display: flex;
}

.program-info-admin-form-container {
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
}

.admin-program-form-group .category-width label{
  /*width: 24%;*/
}

.admin-form {
  padding: 2%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.admin-program-form-group {
  width:100%;
  margin-top: 0;
  margin-bottom: 1%;
}
.admin-program-form-group .input-box > div,.admin-program-form-group > textarea {
  width:100%;
  background: #fff;
  box-shadow: 0 8px 6px -6px #bfbfbf;
  height: auto;
  margin: 0;

} 
.div-wrapper{     padding:25px 25px 4%;} 
.company-logo label{padding-left: 15px;}
.admin-program-form-group .input-box{
  padding-right: 15px;
  padding-left: 15px;
}
.admin-program-form-group .input-box > div{ background: none; box-shadow: none;  }
.admin-program-form-group .input-box .MuiInputBase-root-444{
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
  border-radius: 5px;
  background-color: #fff;
}

.admin-program-form-group .input-box .MuiPrivateNotchedOutline-root-711{ border: none; border-radius: 5px; }

.admin-program-form-group .input-box > div input{
  padding: 13.5px 14px;
}
.admin-info-icon {
  height: 16px;
  width: 18px;
  margin-left: 5px;
  cursor: pointer;
}

.admin-button-container{
  width: 40%;
}

.admin-composite-field{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  height: auto;
}

.admin-uploaded-image{
  margin-bottom: 2%;
  width: auto;
  height: 150px;
}

.activeTab .drop-down-mobile .form-group-tab {
  display: block;
  margin-bottom: 5px;
  margin-right: 20%;
  margin-top: 8px;
}

.activeTab .drop-down-mobile .form-group-tab input {
  padding: 0;
  height: auto;
  height: initial;
  width: auto;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.activeTab .drop-down-mobile .form-group-tab label {
  position: relative;
  cursor: pointer;
}

.activeTab .drop-down-mobile .form-group label:before ,.activeTabClass .drop-down-mobile .form-group label:before{
  content:'';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 4px;
}
.activeTab .drop-down-mobile .form-group input + label:after {
  background: transparent !important;
}
.activeTab .drop-down-mobile .form-group input:checked + label:after ,.activeTabClass .drop-down-mobile .form-group input:checked + label:after{
  content: '';
  display: block;
  position: absolute;
  top: 2px;
  left: 7px;
  width: 6px;
  height: 12px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  background: transparent !important;
  -ms-transform: rotate(45deg);
      transform: rotate(45deg);
  border-radius: 4px;
}

 .drop-down-mobile .form-group-tab {
  display: block;
  margin-bottom: 5px;
   margin-top: 8px;
}

 .drop-down-mobile .form-group-tab input {
  padding: 0;
  height: auto;
  height: initial;
  width: auto;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

 .drop-down-mobile .form-group-tab label {
  position: relative;
  cursor: pointer;
}

 .drop-down-mobile .form-group label:before {
  content:'';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #a7a7a7;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
   border-radius: 4px;
}
 .drop-down-mobile .form-group input + label:after {
  background: transparent !important;
}
 .drop-down-mobile .form-group input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 2px;
  left: 7px;
  width: 6px;
  height: 12px;
  border: solid #a7a7a7;
  border-width: 0 2px 2px 0;
  background: transparent !important;
  -ms-transform: rotate(45deg);
      transform: rotate(45deg);
   border-radius: 4px;
}




.admin-thumbnail-container{
  height: 100%;
  width: 200px;
  border: 1px solid grey;
}

.admin-thumbnail-image{
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.admin-remove-image{
  position: relative;
  left: 150px;
}

.img-overlay{
  position: relative;
  bottom: 0px;
}

.custom-upload{
  margin-bottom: 15px;
}

.w-100{
  width: 100%;
}

.tab-container-div{
  display: block;
}
.admin-image-overlay {
  position: relative;
  bottom: 148px;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.65);
  width: 100%;
  height: 100%;
  display: none;
}
.categoryHeight{
  height: 400px;
  overflow-y: auto;
}
.admin-program-form-group.categoryHeight header, .admin-program-form-group.categoryHeight .swipable-view{
  width: 95%;
  margin: auto;
}

.admin-program-form-group.categoryHeight header + div >div{
  height: auto !important;
}
/* 
.admin-program-form-group .tab-color-education{
  background-color: #c5d4e4;
}

.admin-program-form-group .tab-color-health{
  background-color: #c9eaf8;
}

.admin-program-form-group .tab-color-food{
  background-color: #dbe9d8;
}

.admin-program-form-group .tab-color-housing{
  background-color: #f6dada;
} */

.admin-subcategory-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}

.admin-program-form-group{margin-bottom: 20px ;}


.schedule-pc-desktop header button.activeTabClass::after, .activeTab ::after, .activeTabClass{
  background-color: #fff !important;
}
.schedule-pc-desktop header button{ border-radius:5px 5px 0 0 ; }

.unClaim-button-wrapper{
  text-align: center;
}

.admin-button-label{
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack:justify;
      justify-content:space-between;
}

.admin-timing-container{
  display:-ms-flexbox;
  display:flex;
  -ms-flex-align: center;
      align-items: center;
}
.categoryHeight.div-width-wrap label{
  width: 24%;
}

.admin-program-timing{
  display:-ms-flexbox;
  display:flex;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 50%;
}

.admin-thumbnail-container:hover .admin-image-overlay{
  display: inherit;
}

.admin-program-split-hours{
  margin-left: 20%
}

.admin-error-message {
  margin-top: 3%;
  color: #c70b1b;
}

.program-info-admin-program-button-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: start;
      justify-content: flex-start;
}
.program-info-timestamp {
  padding: 0 30px;
  font-size: 1rem;
}
.admin-form h1{
  font-weight: 600;
  width: 45%;
}

.name-group {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.profile-title {
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 100%;
}

.ml-2{
  margin-left: 10px;
}

.schedule-wrap{
  /*padding: 24px 0px;*/
  padding: 0px 24px 24px 24px;
}

.program-info-admin-program-form-group{
  padding: 0px 24px;
  margin-bottom: 3%;
  width: 100%;
}

.tab-container-div{
  display: -ms-flexbox;
  display: flex;

}

.category-icon {
  margin-right: 17px;
  margin-top: -32px;
}

.composite-language-field-program-info{
  -ms-flex-pack: justify;
      justify-content: space-between;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.company-logo label{
  color: #545860;
  font-weight: normal;
}

.program-form-group textarea{
  width: 100%;
  height: 80px;
}

.drop-down-mobile svg {
  margin-left: 15px;
}

.company-logo h4{
  color: #545860;
}

.validation-text {
  color: #545860;
  font-weight: 500;
}

.validation-text p{
  margin-top: 10px;
  margin-bottom: 0;
}


.program-tabs-height .tab-main button {
  height: auto;
  min-height: 60px;
  padding: 0;
}
.program-tabs-height .tab-main button .category-icon {
  margin-top: 0;
  margin-right: 15px;
}


@media screen and (min-width: 320px) and (max-width: 767px) {
  .program-info-admin-container {
    height: auto;
    padding-bottom: 10%;
    padding: 5px;
    width: 100%;
  }
  .wrapper-main-div {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .wrapper-category-div{
   width: 100%;
  }
  .flyer-div-wrap {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: justify;
        justify-content: space-between;
    padding: 0 5px;
  }
  .flyer-div-wrap div{
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .admin-form {
    border: none;
  }
  .program-info-admin-program-form-group{
    margin-bottom: 3%;
  }
  .admin-timing-container{
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: start;
        align-items: flex-start;
  }
  .admin-program-timing{
    width: 100%;
  }
  .admin-program-split-hours{
    margin:0;
  }
  .admin-button-container{
    width: 60%;
  }
  .admin-uploaded-image {
    width: 100%;
  }
  .program-info-admin-program-button-group {
    -ms-flex-direction: column;
        flex-direction: column;
    width: 100%;
  }
  .admin-form h1{
    font-size: 20px;
    width: 80%;
  }
  .schedule-phone.admin-page .data-set{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .categoryHeight{
    margin-top: 20px;
    padding-bottom: 20px;
  }
  .category-icon{
    margin-right: 0;
    margin-top: 0 !important;
  }

  .tab-container-div button {
    min-height: 65px !important;
    border-right: 1px solid #ddd;
  }
  .tab-container-div button:last-child {
    border-right: none;
  }
  .categoryHeight.div-width-wrap label{
    width: 100%;
  }
  .drop-down-mobile svg {
    margin-left: 5px;
  }
}
.schedule-phone .admin-page {
  margin: 0 10px;
  margin-top: 10px;
}

.country-flag {
  width: 29px;
}

.select-div #select-email{
  width: 100%;
}
/*.swipable-view .react-swipeable-view-container {
  height: auto !important;
}*/
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  /* .country-flag{
    .country-flag?
  } */
}
@media screen and (min-width: 320px) and (max-width: 375px) {
  .tab-container-div button {
    min-height: 68px !important;
    min-width: auto;
    padding-top: 0 !important;
    font-size: 11px;
  }
}
.signup-center-div{
  height: auto;
  background: #ffffff;
  min-height: -webkit-max-content;
  min-height: max-content;
  border-radius: 5px;
  width: 420px;
  position: relative;
  top: 50%;
  left: 50%!important;
  -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
}
.signup-admin-form-container{
  padding: 2%;
  background: #F8F9F9;
  max-height: 68vh;
  overflow: auto;
  border-radius: 0 0 5px 5px;
}

.signup-center-div .close-icon {
  position: absolute;
  right: -39px;
  background: #A6ABB6;
  border-radius: 0 5px 5px 0;
  top: 8px;
  height: 40px;
  width: 40px;
}
.signup-center-div .close-icon button, .signup-center-div .close-icon button:hover{
  background: transparent;
  color: #FFFFFF;
  padding: 8px;
}
.signup-center-div .modal-title {
  background: #fff;
  border-radius: 5px 5px 0 0;
  height: 60px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: left;
      justify-content: left;
  padding: 0 6%;
  -ms-flex-align: center;
      align-items: center;
  margin: 3px 0;
  box-shadow: 0 6px 15px -8px #a7a7a7;
}
.signup-center-div .modal-title h2{
  margin: 0;
  font-weight: bold;
  color: #545860;
}

.bottom-title {
  text-align: center;
  margin: 60px auto;
}

.admin-container{
  margin-top: 0px;
  background: #f4f4f4;
}

.signup-header-img img{
  width: 10%;
}
.signup-admin-program-form-group{
  width: 100%;
  padding:0 5%;
}
.signup-admin-program-form-group .signup-btn button{
  background: #F29223;
  width: 100%;
  border-radius: 3px;
  margin: 0 0 10px;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  border-color: transparent;
}
.signup-admin-program-form-group .signup-btn button:hover{
  box-shadow: 0 0 11px rgba(33,33,33,.2);
  background: #F29223;
  color: #fff;
  border-color: transparent;
}
.program-form-group{
  margin-bottom: 1%;
}
.div-wrapper .input-boxes {
  border: 1px solid transparent;
}
.div-wrapper .input-boxes:hover{
  border: 1px solid #787878;
}
.validation-div {
  margin: 10px 0;
}

.program-form-group h3{
  font-size: 24px;
  font-weight: 600;
  color: #245497;
  text-align: center;
}

.admin-side-bar{
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 1;
}

.signup-admin-container {
  background-color: #f4f4f4;
  height: 100vh;
}
@media screen and (max-width: 1024px) and (min-width: 320px){
  .signup-admin-form-container{
    max-height: calc(100vh - 150px);
  }
}

@media screen and (max-width: 920px) and (min-width: 771px) {
  .signup-center-div {
    width: 280px;
  }
}
@media screen and (max-width: 767px) and (min-width: 320px){
  .signup-center-div{
    width: 100%;
  }
  .signup-center-div{
    width: 100%;
    max-width: 100%;
  }
  .signup-center-div .close-icon {
    top: 10px;
    right: 0;
    background: transparent;
  }
  .signup-center-div .close-icon button, .signup-center-div .close-icon button:hover{
    color: #545860;
  }
  .signup-admin-form-container{
    max-height: calc(100vh - 150px);
  }
}
.success_sign_up{
  z-index: 999999999 !important;
  top: 100px !important;
  position: fixed !important;
}
.confirm-email-container {
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  text-align: center;
  border: 1px solid;
  padding: 20px 30px;
  background: #fff;
  border-radius: 5px;
}
body .Content-success-332{
  background-color: #008000;
}
body .Content-error-331{
  background-color: #bf0708;
}
.confirm-email-container img {
  width: 40%;
}
.confirm-email-container h1 {
  font-size: 26px;
  color: #666;
}

.confirm-email-container p {
  font-size: 14px;
}

.resend-email-div {
  background-color: #6ea8dc;
  color: #fff;
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 14px;
  cursor: pointer;
}

.wrapeer{
  width: 40%;
  margin: 0 auto;
  margin-top: 100px;
}

@media (max-width: 425px) and (min-width: 320px){
  .wrapeer{
    width: 100%;
    margin: 0 auto;
  }
  body .swipable-view label{
    width: 100% !important;
  }
  .confirm-email-container{
    margin-top: 80px;
  }
}
.sidePanel-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  height: 42px;
  width: 45px;
  color: #ffffff;
  background: #29ABE2;
  padding: 0px 5px !important;
  border-radius: 5px;
  -ms-flex-align: center;
      align-items: center;
}

.hover-title:hover {
  text-decoration: underline;
  color: #29abe2;
  cursor: pointer;
}

.sidePanel-header .tool-tip {
  visibility: visible;
  width: 185px;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  -ms-flex-align: right;
      align-items: right;
  bottom: -55px;
  position: absolute;
  z-index: 3;
  right: calc(25% - 92.5px);
}

.sidePanel-header:hover {
  cursor: pointer;
}

.sidePanel-header:hover .tool-tip {
  visibility: hidden;
}

.sidePanel-header-display {
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.header-category {
  display: -ms-flexbox;
  display: flex;
  color: white;
  font-size: 18px;
  font-weight: 600;
}

.btn-select {
  background-color: #245497;
  color: white;
  border: 2px solid white;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  padding: 5px 10px;
}

.btn-select:hover {
  background-color: #234b8d;
}

.mobile-sidePanel-header {
  /*height: 205px;*/
  width: 100%;
}

.mobile-category-container {
  padding: 2% 0;
  /*padding: 20px 0;*/
  /*padding-left: 25px;*/
  background-color: #245497;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  min-height: 66.67px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 4;
  width: 100%;
}

.map-text {
  position: relative;
  z-index: 1;
}

.header-wrap {
  position: absolute;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
  -ms-flex-align: center;
      align-items: center;
  width: 100%;
  z-index: 1;
}

.header-wrap img {
  width: 100px;
  margin: 5px 10px;
}

.img-wrap {
  background-color: #0000004d;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
}

.title-text {
  font-size: 17px;
  font-weight: 700;
}
.header-detail{
  text-align: center;
  height: 66px;
}
.header-detail .header-img{ display: -ms-flexbox; display: flex; padding-left: 50px; }

.header-detail .header-img img {
  margin-top: 15px;
  max-width: 70px;
  height: 40px! important;
}

.header-detail h3{
  color: #545860;
  font-weight: 800;
  font-size: 14px;
  margin-bottom: 0;
  line-height: 20px;
  margin-top: 6px;
}
.header-detail h4{
  font-size: 14px;
  line-height: 25px;
  color: #165593;
  font-weight: 800;
  margin-top: 26px;
  max-width: 140px;
  text-align: left;
  line-height: 20px;
  padding-top: 10px;
  padding-left: 10px;

}

.header-detail h4:hover{ text-decoration: none; }

.search-wrap{
  display: -ms-flexbox;
  display: flex;
  background-color: #fff;
  height: 50px;
  -ms-flex-align: center;
      align-items: center;
  padding: 0px;
  padding-left: 5px;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
}

.header-category {
  color: #fff;
  font-size: 14px;
  display: block;
}

.MuiInput-root-65{
  width: 100%;
}

.search-wrap-div {
padding: 15px;
  background: #F29223;
  box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.15), 0px 5px 2px rgba(0, 0, 0, 0.12);
}

.input-wrap{
  background: #fff;
  margin: 0;
  height: 40px;
  width: 90%;

}
.input-wrap >div {
  display: block;
}

.input-wrap input{
  padding: 10px 15px;
  width: 100%;
}

.search-btn{
  position: relative;
  height: 40px;
  background: #FFF;
  width: 40px;
  display: -ms-flexbox;
  display: flex;
  left: 0;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.search-btn svg{
  color:#545860 !important;
}

@media (min-width: 692px) {
  .mobile-category-container {
    display: none;
  }

  .mobile-menu-container {
    display: none !important;
  }
}

.mobile-category-container svg {
  opacity: 0;
}

.mobile-btn-select {
  width: 50%;
  color: #000;
  background-color: #e5e5e5;
  /*border: 1px solid #9e9e9e;*/
  /*border-radius: 20px;*/
  border: 1px solid #e5e5e5;
  cursor: pointer;
  font-weight: 800;
  padding: 10px 0px;
}

.filter-button {
  width: 50%;
  cursor: pointer;
  border: none;
  color: #000;
  background-color: #fff;
  padding: 10px 0px;
  font-weight: 800;
  /*background-color: #fff;*/
  /*color: #1890ff;*/
}

.sidePanel-header-map {
  /*height: 150px;*/
  width: 100%;
}

.btn-group {
  height: auto;
  /*padding: 3%;*/
  background-color: white;
  /*display: flex;*/
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.filter-button:focus {
  outline: 0;
}

.label-class {
  color: black;
  font-weight: bold;
}

.disable-button {
  background-color: #bbb6b6;
  border: 1px solid #9e9e9e;
  color: #999;
}

.header-img-wrap {
  position: absolute;
  height: 150px;
  width: 100%;
  background-color: #0000004d;
}

.searched-results{
  direction: ltr !important;
}

.resourceContainer{
  background: #FFFFFF;
  border: 1px solid #F3F3F3;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  margin:6px 10px;
}


@media screen and (min-width: 320px) and (max-width: 768px) {
  .map-text{ 
    max-height: 130px;
  }
  .header-detail .header-img img{
    margin-top: 13px;
  }
  .header-detail h4 {
    font-size: 20px;
    margin-top: 6px;
  }
  .header-wrap img{
    width:75px;
  }

  .sidePanel-header .tool-tip {
    visibility: visible;
    width: 140px;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    -ms-flex-align: right;
        align-items: right;
    /* Position the tooltip */
    right: calc(25% - 70px);
    bottom: -55px;
    position: absolute;
    z-index: 3;
  }

  .header-wrap {
    background: #245497;
    height: 60px;
    -ms-flex-pack: end;
        justify-content: flex-end;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
  }

  .header-mobile-wrap {
    position: absolute;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: end;
        justify-content: flex-end;
    -ms-flex-align: center;
        align-items: center;
    width: 100%;
    z-index: 1;
  }
  .header-detail-wrap{
    position: relative;
    top: -60px;
    z-index: 1;
  }
  .header-wrap{
    position: unset;
  }
  .search-wrap{
    -ms-flex-pack: justify;
        justify-content: space-between;
  }
}

@media screen and (min-width: 320px) and (max-width: 1024px) {
  .header-wrap div {
    /*position: absolute;
    right: 5px;*/
    z-index: 5;
    /*top: 5px;
    width: 100%;*/
  }
}

.img-check {
  margin-left: 15px;
  margin-top: 11px;
  width: 24px;
  height: 24px;
}
@media (max-width: 375px) {
  .btn_mobile{
    margin-right: -15px !important;
  }
}
 body .main-review-container{
  background: #ffffff;
}

 body .main-review-container.review-div{
   position: relative;
   overflow-y: inherit;
   border-radius: 5px;
   top: 50%;
   left: 47% !important;
   -ms-transform: translate(-50%, -50%);
       transform: translate(-50%, -50%);
 }


 body .main-review-container .titlebar{
   padding: 10px 0;
   padding-left:40px ;

 }
.review-box{
  height: auto;
  background: #ffffff;
  max-height: 100%;
  min-height: -webkit-max-content;
  min-height: max-content;
  border-radius: 5px;
  width: 420px;
  position: relative;
  top: 50%;
  left: 50% !important;
  -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
  overflow-y: inherit;
}

.review-box .close-box {
  position: absolute;
  right:-40px;
  background: #A6ABB6;
  border-radius: 0 5px 5px 0;
  top: 8px;
  height: 40px;
  width: 40px;
  color: white;

}
.review-box .close-box button ,.review-box .close-box button:hover{
  color: #FFFFFF;
  background: transparent;
  padding: 8px;
}

.review-container {
  background: #F8F9F9;
  padding: 1% 6% 6% 6%;
  display: block;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  min-height: -webkit-max-content;
  min-height: max-content;
  height: calc(100vh - 200px);
  overflow: auto;
  border-radius: 0 0 5px 5px;
  margin-bottom: 5px;
}

.review-container .star-ratings,.review-container .star-ratings:hover{
  border: none;
  box-shadow: none;
  height: 20px;
  margin: 8px 0;
}

.review-box .titlebar{

  height: 78px;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -ms-flex-pack: left;
      justify-content: left;
  padding: 0 20px;
  -ms-flex-align: center;
      align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.12);
  border-radius: 5px 5px 0px 0px;
}
.review-box .titlebar h2{
  font-weight: bold;
  color: #545860;
  margin: 0;
}
.user-review{
  width: 100%;
  margin-bottom:1%;
  padding-right: 5%;
  border-bottom: 1px solid #000;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: start;
      align-items: flex-start;
  text-align: justify;
}

.review-label{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  cursor: pointer
}

.reply{
  width: 100%;
  margin: 5% 0 0 5%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: end;
      align-items: flex-end;
  text-align: justify;
}

.by-line{
  font-size: 11px;
  color: gray;
}

.message{
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: center;
      justify-content: center;
}

.success{
  color: #008000;
}

.failed {
  color: #bf0708;
}
.btn-submit{
  text-align: center;
}
.btn-submit button,.btn-submit button:hover{
  width: 80%;
  margin: 10px auto;
  background: #F29223;
  height: 38px;
}
@media screen and (max-width: 920px) and (min-width: 771px) {
  .main-review-container {
    width: 280px;
  }
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .main-review-container{
    width: 100%;
  }
  .star-container svg{
    /*height: 20px !important;*/
    /*width: 20px !important;*/
  }
  .review-container{
    min-height: -webkit-max-content;
    min-height: max-content;
  }
  .message{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    text-align: center;
    -ms-flex-align: center;
        align-items: center;
  }
  .review-box .close-box {
    top: 4%;
    right: 0;
    background: transparent;
  }
  .review-box .close-box button ,.review-box .close-box button:hover{
    color:#545860;
  }
  body .main-review-container.ask-Question {
    left: 50% !important;
  }
}
.list-container {
  padding: 0% 40px 40px;
  max-height: 64vh;
  overflow: auto;
  background: #F8F9F9;
  border-radius: 5px;
}
.header-total-rating {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: baseline;
      align-items: baseline;
}
.rating-review-count p {
  color: #F29223;
  margin-right: 5px;
}
.reviewers-total-list {
  margin-top: 15px;
}
.single-review {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin-top: 8px;
  background: #FFFFFF;
  border: 1px solid #F3F3F3;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding: 18px 20px;
  font-size: 14px;
  color: #545860;
  font-style: italic;
}
.single-review p{
  font-style: normal;
  margin-bottom: 5px;
}
.single-review h3{
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.01em;
  margin-bottom: 0;
  color: #545860;
}
.divider-line {
  width: auto;
  height: 1px;
  background-color: #CACCD2;
  margin: 5px 0;
}
.list-container h2 {
  font-weight: 500;
}
.header-total-rating h3 {
  color: orange;
  font-size: 16px;
  margin-right: 5px;
  font-weight: 500;
}
.header-total-rating p {
  color: #A6ABB6;
  margin-left: 5px;
  font-size: 16px;
  margin-bottom: 0;
}
.review-button {
  float: right;
}
.review-rating-title {

}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .main-title-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
  }
  .list-container {
    padding: 0 16px 16px;
    height: 250px;
    overflow: auto;
  }
  .review-button{
    float: left;
  }
}

.mod1{
  z-index: 22222222;
}

.resource-details .MuiPaper-root-169{
    left: 400px !important;
}
.MuiModal-root{
  background-color: red !important;
}

.list-resources {
  width: 100%;
  height: calc(100vh - 197px);
  position: relative;
  direction: rtl;
  overflow-y: scroll;
  scroll-behavior: smooth;
  z-index: 999;
}

.img-icon {
  padding: 0px 1px;
}

.today-open{
  color: #6FA963;
  margin-left: 6px;
}

.today-close{
  color: #DA6A6A;
  margin-left: 6px;
}

.now-open-div {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.div-top-container {
  border-bottom: 1px solid rgb(202, 204, 210);
  padding-bottom: 7px;
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.read-more-icons-div {
  margin-top: 5px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
}
.list-resources-timestamp{
  color: #ccc;
  font-size: 14px;
}

.now-open-text{
  margin-bottom: 0;
}

.clock-img{
  height: 12px;
  width: 12px;
}

.searched-results {
  width: 100%;
  position: relative;
  direction: rtl;
  scroll-behavior: smooth;
  z-index: 1;
}

.rating-div-color {
  margin: 0px 5px;
  color: #F29223;
  font-weight: normal;
}

.list-resources-text-input-div{
  margin-top: 7px;
  margin-bottom: 0px;
  min-height:0px;
  height: auto;
  overflow: hidden;
  font-size: 14px;
}

.list-resource-loader {
  direction: ltr;
  text-align: center;
  margin-top: 2%;
}

 .presentation{
   /*position: absolute !important;*/
   z-index: 0 !important;
 }

/*.resourceContainer .arrow {*/
  /*border-top: 15px solid transparent;*/
  /*border-left: 25px solid red;*/
  /*border-bottom: 15px solid transparent;*/
  /*position: absolute;*/
  /*top: 40%;*/
  /*transform: translate(-40%);*/
  /*right: -5px;*/
  /*z-index: 999;*/
/*}*/
.title-address{
  /*text-transform: lowercase;*/
  font-size: 18px;
  font-weight: 900;
  color: #245397;
}
/*.title-address:first-letter{*/
/*  text-transform:uppercase;*/
/*}*/
.resourceContainer {
  padding: 18px 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  cursor: pointer;
  font-size: 13px;
  color: #545860;
  direction: ltr;
  position: relative;
  border-bottom: 1px solid #808080;
}

.selected-resources-container {
  background-color: #29ABE2;
  color: #FFFFFF;
  position: relative;
}
.selected-resources-container:before{
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-right: 12px solid #29abe2;
  right: -11px;
  top: 40%;
  -ms-transform: rotate(180deg);
      transform: rotate(180deg);
}
.selected-resources-container .title-address {
  color: #FFFFFF;
  text-decoration: underline;
}
.selected-resources-container .now-open-text ,.selected-resources-container .number-of-rating ,.selected-resources-container .rating-div-color{
  color: #AFE6FE;
}
.videoBlock{  margin-top: 20px; padding:0 10px; } 
.assistanceBtn{
  background: #6FA963;
border-radius: 5px;
width: 100%;
color: #fff;
border: none;
padding: 10px ;
font-size: 14px;
font-weight: 500;
}
.assistanceBtn svg{margin-right: 10px;}
.assistanceBtn span{font-size: 16px; font-weight: 600; padding-left:10px ;}
.p10{padding:0 10px;}
.menuLeft{
  list-style: none;
  padding: 0 10px; margin: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  padding-top: 5px; 
  line-height: 22px;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;

}
.menuLeft li {
  padding:0px 10px;
}
.menuLeft li a{  color: #474747; font-size: 13px; padding: 0 8px; text-decoration: underline}
.menuLeft li a:hover{  color: #245397; text-decoration: none}
.dropDown{
  color: #474747 !important;     font-size: 11px !important; background: #fff !important; padding: 0 !important;
  text-transform: capitalize !important; box-shadow: none !important;  font-weight: normal !important;
}
.MuiListItem-root-186{ 
  padding-top:5px !important;
  padding-bottom:5px !important; 
}
.WithStyles-MenuItem--root-182:focus .MuiTypography-subheading-211{ color: #fff !important; }
.Component-paper-147{ border: none !important; }
.MuiList-padding-179{ padding-top: 0 !important; padding-bottom: 0 !important; }

.resource-icon{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
  -ms-flex-align: center;
      align-items: center;
}
.resource-icon p{
  width: 22px;
  margin: 0 2px;
  color: #fff;
  border-radius: 5px;
  font-size: 10px;
  height: 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}

.resource-icon p.pizza{background: #6FA963;}
.resource-icon p.home{background: #DA6A6A;}
.resource-icon p.thlarge{background: #165593;}
.resource-icon p.heart{background: #29ABE2;}
.selected-resources-container .resource-icon p,.selected-resources-container .resource-icon p.heart,.selected-resources-container .resource-icon p.thlarge ,.selected-resources-container .resource-icon p.home, .selected-resources-container .resource-icon p.pizza{
  border: 1px solid #AFE6FE;
  background: transparent;
  color: #AFE6FE;
}
.selectedResourceContainer {
  padding: 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  cursor: pointer;
  font-size: 13px;
  background-color: lightgray;
  color: #000;
  direction: ltr;
  position: relative;
  border-bottom: 1px solid #808080;
}

.rate1 {
  display: none;
}

.number-of-rating {
  margin-left: 5px;
  color: #A6ABB6;
}

.rating-container {
  display: contents;
  width: 40%;
  padding: 3px;
}

.detail-section {
  margin-left: 2%;
}
.MuiModal-root-19{
  z-index: 3333333 !important;
}
.MuiModal-root-30 .ListResources-popup-100{
  left: 440px !important;
}/*
.presentation :nth-of-type(2n){
  left: 440px !important;
}*/
.presentation .ListResources-popup-89{
  left: 440px!important;
}

@media screen and (min-width: 320px) and (max-width: 425px) {
  /*.presentation :nth-of-type(2n){
    left: 0px !important;
  }*/
  .presentation .ListResources-popup-89{
    left:0px!important;
  }
}

@media screen and (min-width: 1025px) {
 .selected-resources-container .rating-container {
   color: #000;
   padding: 3px;
   width: 40%;
 }

  .selected-resources-container .rate2 {
    display: none;
  }
  
  .selected-resources-container .rate1 {
    display: inherit;
  }

}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .list-resources {
    width: 100%;
    height: calc(100vh - 262px);
    overflow-y: scroll;
    z-index: 0;
  }
  .searched-results {
    width: 100%;
    z-index: 0;
    padding-bottom: 20px;
  }

  .presentation {
    /*position: absolute !important;*/
    z-index: 5 !important;
  }
}
.list-resources::-webkit-scrollbar
{
  width: 6px;
  border-right: 1px solid #808080;
}

.searched-results::-webkit-scrollbar
{
  width: 6px;
  border-right: 1px solid #808080;
}

.list-resources::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #245397;
  background-color: #245397;
}

.searched-results::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #245397;
  background-color: #245397;
}

.list-resources-wrapper-read-more {
  position: relative;
}

.list-resources-read-less {
  height: 40px;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;

}

.list-resources-read-more-content {
  color: #29ABE2;
  max-width: 75px;
}
list-resources-read-more-content:hover {
  text-decoration: underline;
}

.menuLeft .dropDown {
  background-color:transparent !important;
  color: #474747 !important; 
  font-size: 13px !important;    
}


 
.menuLeft .MuiTypography-subheading-211
{
  font-size: 13px !important;
}

.Organizations-link{
  padding-left: 8px !important;
  font-size: 13px !important;
  color: #545860  !important;
  line-height: .4em  !important;
  padding-top: 10px  !important;
  padding-bottom: 10px  !important;
  height: auto  !important;
  width: 70px !important;
}

.Organizations-link a{ 
  color: #545860  !important; 
}




/*v2*/
.resource * {
    color: #000;
}

.resource{
  padding:20px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #DDDFE3;
  cursor: pointer;
}
.resource-title{
  color: #04346C;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.18px;
  margin-bottom: 4px;
}
.resource-subTitle{
  color: #545860;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #DDDFE3;
}

.resource-description{
  line-height: 22px;
  height:62px;
  overflow: hidden;
  white-space: pre-wrap;
  text-overflow: ellipsis;
}



.resource-links{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-top: 10px;
}

.resource-links a,.resource-links > div {
  margin-right: 16px;
  margin-bottom:20px
}
.resource-links img {
    margin-right: 8px;
    max-width: 24px;
}

.resource-list .resource{
  margin-bottom:20px;
}

.resource-modal-item{
  width: 400px;
}

.close-modal-map{
  background-color: #9A9EA7;
  width:40px;
  height: 40px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 0px 5px 5px 0;
  border: none;
  position: absolute;
  right:-52px;
  top: 0;
  z-index: 30000;
  cursor: pointer;
  transition: all 0.2s linear;
}
.close-modal-map:hover{
  background-color: #04346C;
}
.resource-modal{
  position: relative;
}

.close-modal-map span{
    background-color: #fff;
    width:2px;
    height: 16px;
    display: block;
    position: absolute;
}

.close-modal-map span:first-child{
  -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
}
.close-modal-map span:last-child{
  -ms-transform: rotate(45deg);
      transform: rotate(45deg);
}

.gm-style-iw-t,.gm-style-iw-d ,.gm-style-iw-c{
  overflow: visible !important;
}

button.gm-ui-hover-effect{
  display:none !important;
}

.gm-style-iw.gm-style-iw-c{
  padding-bottom:12px !important;
  padding-right:12px !important;
}

.resource-modal-list{
  max-height: 400px;
  overflow-y: scroll;
  padding-right: 8px;
}
.resource-modal-list > div{
  margin-bottom: 8px;
}
.presentation{
  z-index: 30002 !important;
}
.resource-details{
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1234;
  max-height: 100vh;
  overflow-y: scroll;
  width: 41vw;
}
.resource-details .content-container{
  width: 100% !important;
}
@media (max-width:1200px) {
  .resource-details{
    width: 100vw;
  }
}

@media (max-width: 576px) {
  .gm-style-iw.gm-style-iw-c{
    max-width: calc(100vw - 40px) !important;
    max-height: unset !important;
  }
  .resource-modal-item{
    width: 100%;
  }
  .resource-modal-list{
    max-height: 260px;
  }
}

@media (max-width: 390px) {
  .gm-style-iw.gm-style-iw-c{
    max-width: calc(100vw - 80px) !important;
  }

  .resource-modal-list{
    max-height: 220px;
  }

}
.question-main-container{
  height: auto;
  background: #ffffff;
  min-height: -webkit-max-content;
  min-height: max-content;
  border-radius: 5px;
  width: 420px;
  position: relative;
  top: 50%;
  left: 50% !important;
  -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
}
.question-main-container .close-icon {
  position: absolute;
  right: -40px;
  background: #A6ABB6;
  border-radius: 0 5px 5px 0;
  top: 8px;
  width: 40px;
  height: 40px;
}
.question-main-container .close-icon button,.question-main-container .close-icon button:hover{
  color: #FFFFFF;
  background: transparent;
  padding: 8px;
}
.question-container .btn-section button{
  background: #F29223;
  width: 100%;
  border-radius: 3px;
  margin: 10px 0;
  color: #fff;
}
.question-container .btn-section button:hover{
  box-shadow: 0 0 11px rgba(33,33,33,.2);
  background: #F29223;
  color: #fff;
}
.question-main-container .modal-title {
  background: #fff;
  height: 78px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.12);
  border-radius: 5px 5px 0 0;
  padding: 0 6%;
  margin-bottom: 1px;

}
.question-main-container .modal-title h2{
  margin: 0;
  font-weight: bold;
  color: #545860;
}

.question-container {
  padding: 1% 6% 6% 6%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  overflow-y: scroll;
}

.user-question{
  width: 100%;
  margin-bottom:1%;
  padding-right: 5%;
  border-bottom: 1px solid #000;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: start;
      align-items: flex-start;
  text-align: justify;
}

.question-label{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  cursor: pointer
}

.answer{
  width: 100%;
  margin: 0% 0 0 5%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: end;
      align-items: flex-end;
  text-align: justify;
}

.by-line{
  font-size: 11px;
  color: gray;
}

.by-line.italic-font {
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
  color: #A6ABB6;
  margin-bottom: 8px;
}

.message{
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: center;
      justify-content: center;
}

.success{
  color: #008000;
}

.failed {
  color: #bf0708;
}

.question-container::-webkit-scrollbar
{
  width: 5px;
  border-right: 1px solid #808080;
}

.question-container::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #555;
  height: 2px;
}

.user-question-text {
  margin-bottom: 0px;
}
@media screen and (max-width: 920px) and (min-width: 771px) {
  .question-main-container{
    width: 280px;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .message{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
    text-align: center;
  }

  .question-main-container .close-icon  {
    top: 4%;
    right: 0;
    background: transparent;
  }
  .question-main-container .close-icon button ,.question-main-container .close-icon button:hover{
    color:#545860;
  }
}
.main-review-container.ask-Question{
  height: auto;
  background: #ffffff;
  max-height: 700px;
  max-width: 630px;
  min-height: -webkit-max-content;
  min-height: max-content;
  border-radius: 5px;
  position: relative;
  top: 50%;
  left: 47%;
  -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
  overflow-y: inherit;
}

.ask-Question .close-icon,.main-review-container .close-icon  {
  position: absolute;
  right: -40px;
  background: #A6ABB6;
  border-radius: 0 5px 5px 0;
  top: 8px;
  height: 40px;
  width: 40px;
}
.ask-Question .close-icon button ,.main-review-container .close-icon button {
  color: #FFFFFF;
  background: transparent;
  padding: 8px;
}
.ask-Question .titlebar ,.main-review-container .titlebar{
  background: #fff;
  min-height: 108px;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -ms-flex-pack: left;
      justify-content: left;
  padding: 0 10px;
  -ms-flex-align: center;
      align-items: center;
  box-shadow: 0 16px 15px -8px #eaeaea;
  border-radius: 5px;
}
.ask-Question .titlebar h2 ,.main-review-container .titlebar h2{
  font-weight: bold;
  color: #545860;
  margin: 0;
}
.main-question-title-header {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  padding-right: 40px;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.question-button {
  float: right;
}
.question-button button ,.question-button button:hover{
  background: transparent;
  border: 1px solid #CACACA;
  border-radius: 5px;
  padding: 11px;
  font-size: 13px;
  color: #474747;
  font-weight: bold;
  width: 100%;
}

.questions-total-list {
  margin: 20px 0 0;
  background: #FFFFFF;
  border: 1px solid #F3F3F3;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}

.single-question {
  padding: 18px 20px;
  margin-bottom: 16px;
  background: #ffffff;
}

.list-question-text {
  margin-bottom: 0px;
  font-weight: bold;
  font-size: 14px;
  color: #545860;
}

.divider {
  width: auto;
  height: 1px;
  background-color: #CACCD2;
}
@media screen and (max-width: 920px) and (min-width: 771px) {
  .question-main-container {
    width: 280px;
  }
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .ask-Question .titlebar {
    height: 78px;
    padding-right: 40px;
  }
  .main-review-container .titlebar{
    height: auto;
  }
  .main-question-title-header{
    display: block;
  }
  .ask-Question .close-icon ,.question-main-container .close-icon ,.main-review-container .close-icon{
  right: 0;
  top: 16px;
  background: transparent;
  z-index: 1;
  }
  .ask-Question .close-icon, .main-review-container .close-icon{
    top: 3%;
  }
  .ask-Question .close-icon button ,.ask-Question .close-icon button ,.main-review-container .close-icon button{
    color: #545860;
  }
  .question-main-container .close-icon button, .question-main-container .close-icon button:hover ,.main-review-container .close-icon button :hover{
    color: #545860;
  }
  .question-main-container{
    width: 100%;
  }
  .ask-Question .titlebar h2 {
    width: 100%;
    padding-top: 10px;
    font-size: 20px;
    padding-bottom: 10px;
  }
  .ask-Question .titlebar h2, .main-review-container .titlebar h2{
    width: 95%;
  }
  body .main-review-container .titlebar{
    padding-left: 10px;
  }
}
.claim-business-main-container{
  margin: auto;
  background-color: #fff;
  width: 100%;
  overflow-y: scroll;
  max-height: 70vh;
}

.claim-business-container{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding: 1% 6% 6% 6%;
  -ms-flex-align: center;
      align-items: center;
}

.name-field {
  margin-right: 1%;
}

.business-form{
  display: -ms-flexbox;
  display: flex;
  min-height: -webkit-max-content;
  min-height: max-content;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: start;
      justify-content: flex-start;
  width: 100%;
  padding-bottom: 6%;
}

.field {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: start;
      justify-content: flex-start;
  width: 100%;
  margin: 1% 0;
}

.error-message-modal {
  margin-top: 1%;
  color: #c70b1b;
}

.claim-message{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  text-align: center;
}

.success{
  color: #008000;
}

.failed {
  color: #bf0708;
}

.close-icon-claim {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .claim-business-main-container{
    max-height: 80vh;
    overflow-y: scroll;
  }
  .business-form{
    min-height: -webkit-max-content;
    min-height: max-content;
  }
  .claim-message{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
    text-align: center;
  }
}

.claim-business-main-container::-webkit-scrollbar{
  width: 5px;
  border-right: 1px solid #808080;
}

.claim-business-main-container::-webkit-scrollbar-thumb{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #555;
  height: 2px;
}
.content-container {
  width: 420px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  background-color: white;
  /*max-width: 420px;*/
  height: 100%;
  /*direction: rtl;*/
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
}
.content-header .close_1 {
  position: absolute;
  right: 10px;
  top: 1px;
  color: #fff;
  z-index: 999;
  cursor: pointer;
  border: none;
  background-color: transparent;
  left: 1px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;

}


.content-header p.cross {
  background: #165593;
  width: 35px;
  height: 35px;
  color: #fff;
  border-radius: 5px;
  font-size: 20px;
  padding: 2px;
}
.content-header p{
  margin: 0;
}
.img-icon-flyer{
  height: 20px;
  width: 20px;
}

.text-align-div {
  -ms-flex-align: center;
      align-items: center;
}

.img-icon-donation {
  height: 15px;
  width: 15px;
  margin-right: 10px;
}

.content-header {
  display: block;
  direction: ltr;
  position: relative;
}

.div-wrap-img {
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #CACACA;
  border-radius: 5px;
  padding: 12px;
  font-size: 13px;
  color: #474747;
  font-weight: bold;
  cursor: pointer;
  width: 170px;
  height: 50px;
  min-width: 170px;
}

.img-size {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.content-middle {
  direction: ltr;
}

.content-middle-child {
  padding: 5% 8%;
}

.content-middle-child-shadow {
  background: #FFFFFF;
  padding: 5% 8%;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.12);
  border-bottom: 2px solid #DEE1E5;
  display: -ms-flexbox;
  display: flex;
  /*justify-content: center;*/
}
.content-middle-child-shadow span {
  width: 50px;
  height: 50px;
}
.title-track {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: start;
      align-items: flex-start;
}
.font-family-roboto p ,.icon-container-div p ,.info-container p{
  margin-bottom: 0;
}

.content-footer {
  width: 100%;
  display: none;
  -ms-flex-pack: center;
      justify-content: center;
  background-color: #245497;
  border-top: 1px solid #245497;
}

.day-div {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  line-height: 30px;
  font-size: 14px;
}

.day-div{
  color: rgba(0,0,0,.65);
}

.business-name {
  font-size: 18px;
  font-weight: bold;
  color: #165593;
  margin-bottom: 1px;
}

.rating {
  margin-right: 5px;
  color: orange;
  font-weight: normal;
}

.alignment {
  -ms-flex-align: center;
      align-items: center;
}

.address {
  padding-top: 4%;
  font-size: 14px;
  display: -ms-flexbox;
  display: flex;
  padding-right: 1%;
}
.address a:hover, .contact-detail div a:hover {
  text-decoration: underline !important;
  color: #165593;
}
.contact-detail {
  padding-top: 4%;
  font-size: 14px;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
}

.contact-detail h5 {
  margin-bottom: 1%;
  font-size: 14px;
}

.contact-detail-icons {
  margin-right: 5%;
  font-size: 18px;
  color: #A6ABB6;
}

.gray-color-icon {
  color: #A6ABB6 !important;
}

.text-color-style {
  color: #29ABE2;
}

.info-container {
  /*margin-left: 1.3%;*/
}

.hide-info-container {
  display: none;
}

.info-icon {
  cursor: pointer;
}

.info-container-header {
  margin-top: 15px;
}

.info-container h4 {
  margin-bottom: 0;
}

.div-seprator {
  border-bottom: 1px solid #DEE1E5;
}

.more-info-header{
  font-weight: 600;
  font-size: 13px;
  color: #165593;
}

.info-content {
  font-size: 14px;
  color: #545860;
}
.info-content-donation,.info-content-donation:hover{
  background: #165593;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  text-align: center;
  margin: 0 auto;
  display: block;
  width: 100%;
  text-decoration: none;
  padding: 10px;
}

.info-content .youtube:nth-child(2){
  padding-bottom: 0px;
}

.youtube{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}

.content-header .image-container {
  width: 100%;
  height: 235px;
  min-height: 150px;
  display: inline-block;
  cursor: pointer;
}

.image-map-container {
  width: 49%;
  min-height: 150px;
  display: inline-block;
}

.empty-image-container {
  display: none;
  padding: 0px 80px !important;
}
.icon-container{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
  font-size: 29px;
  color:#29ABE2;
  -ms-flex-align: center;
      align-items: center;
  padding: 5% 8%;
  background-color: #F8F9F9;
  border-bottom: 1px solid #DEE1E5;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 0px 0px 1px 1px;
}
.icon-container p{
  margin-bottom: 0;
}

.dot-div {
  height: 6px;
  width: 6px;
  background: #6FA963;
  border-radius: 3px;
  margin-right: 5px;
}

.dot-red-div {
  background: #DA6A6A;
}

.language-div {
  border-radius: 5px;
  padding: 2px 10px;
  color: #fff;
  background: #F29223;
  font-size: 14px;
  margin-right: 5px;
}

.edit-section {
  padding: 5% 8%;
  font-size: 14px;
  color: #165593;
  border-top: 1px solid #DEE1E5;
  font-weight: 600;
}

.edit-sub-section {
  margin-top: 15px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer;
}

.edit-section-icon {
  display: -ms-flexbox;
  display: flex;
  margin-right: 10px;
  font-size: 18px;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
}

.globe-icon{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
}

.globe-icon span{
  line-height: 0;
  font-size: 20px;
  cursor: pointer;
}

.globe-icon svg{
  font-size: 18px;
}

.edit-content {
  display: inline-block;
  width: 85%;
  vertical-align: middle;
  color: #29ABE2;
  font-size: 14px;
  font-weight: normal;
}
.edit-content p {
  margin-bottom: 0;
}

.image-map-span {
  width: 100%;
  height: 30vh;
}

.edit-content :first-child {
  line-height: 1;
}

.edit-content :first-child:hover {
  color: #165593 !important;
  text-decoration: underline;
}
.company-img {
  /*max-height: 255px;*/
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.edit-content :nth-child(2) {
  font-size: 11px;
  color: gray;
}

.edit-content :nth-child(3) {
  font-size: 11px;
  margin-left: 1%;
  margin-top: 0;
}

.image-map-container .gmnoprint a, .gmnoprint span {
  display:none;
}

.image-map-container .gmnoprint div {
  background:none !important;
}

.image-map-container #GMapsID div div a div img{
  display:none;
}

.image-map-span .gmnoprint a, .gmnoprint span {
  display:none;
}

.image-map-span .gmnoprint div {
  background:none !important;
}

.margin-less{
  margin-left: 0 !important;
}

.image-map-span #GMapsID div div a div img{
  display:none;
}

.description-section {
  padding: 5% 8%;
  background: #F7F7F7;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.12);
}
.service-section h4 {
  margin-bottom: 0;
}
.description-section p ,.description-section div{
  color: #545860;
}

.file-upload {
  display: none;
}

.bold-today {
  font-weight: 600;
}

.notification-content{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  width: 100%
}

.icon-container-div {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -ms-flex-align: center;
      align-items: center;
  border-bottom: 1px solid #DEE1E5;
  background: #F8F9F9;
}
.icon-container-div a:hover ,.icon-container-div p:hover, .globe-icon:hover ,.globe-icon:hover svg{
  text-decoration: underline;
  color: #165593 !important;
}

.number-of-total-rating {
  margin-left: 5px;
  color: #A6ABB6;
}

.number-of-total-rating:hover {
  cursor: pointer;
}

.content-wrapper-read-more {
  position: relative;
}

.content-read-less {
  height: 40px;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.read-more-div {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #29ABE2;
  padding-top: 2px;
}
.read-more-div:hover {
  color: #165593;
  text-decoration: underline;
}
.content-read-more-content {
  color: #29ABE2;
  max-width: 75px;
  cursor: pointer;
}
content-read-more-content:hover {
  text-decoration: underline;
}
.content-text-input-div{
  margin-top: 5px;
  margin-bottom: 0px;
  min-height:0px;
  overflow: hidden;
}
@media screen and (min-width: 320px) and (max-width: 425px) {
  .notification-popover:before{
    left: 81%;
  }
  body .quill .ql-snow .ql-tooltip{
    white-space: unset;
  }
  .div-wrap-img {
    width: 140px;
    font-size: 10px;
    -ms-flex-align: center;
        align-items: center;
    margin: 5px 0;
  }

  .icon-button-div{
    padding: 2px !important;
  }
  .menuItem-div button p.cross, .said-icon button p.cross {
    width: 30px;
    height: 30px;
    padding: 0px;
    margin-right: 6px;
  }
  .searched-results .resourceContainer {
    padding: 10px 10px;
  }
  .globe-icon{
    font-size: 10px;
  }
  .icon-container-div p a:hover {
    color: #165593;
  }
  .content-middle-child ,.description-section ,.edit-section{
    padding: 5% 4%;
  }
  .admin-main .data-title .export-wrap button {
    padding: 4px;
    margin-right: 7px!important;
    font-size: 11.5px;
  }

}

@media screen and (min-width: 601px) and (max-width: 767px) {
  .content-middle {
    /* padding-top: 27%; */
  }
}

@media screen and (min-width: 320px) and (max-width: 780px) {
  .content-container {
    width: 100%;
    /* height: calc(100vh - 69px);*/
    height: 100vh;
    /*margin-bottom: 45px;*/
  }
  .content-header {
    display: -ms-flexbox;
    display: flex;
  }

  .content-footer{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .image-container {
    position: relative;
    /*max-height: 150px;*/
  }
  .image-container span {
    position: absolute;
    right: 0;
    bottom: 4px;
    left: 10px;
    color: #fff;
    font-weight: 800;
  }
  .edit-section-last {
    margin-bottom: 140px !important;
  }
}

.res_det{
  position: fixed;
  z-index: 922222222;
  width: calc(41vw + 10px);
  height: 100vh;
  overflow-y: scroll;
}

@media (max-width: 1200px) {
  .res_det{
    width: 100vw;
  }
}

@media screen and (min-width: 320px) and (max-width: 468px) {
  .content-header {
    display: block;
    position: unset;
  }
}

@media screen and (min-width: 768px) {
  .image-container {
    width: 100%;
    position: relative;
    min-height: unset;
  }
  .image-container span{
    position: absolute;
    right: 0;
    bottom: 8px;
    left: 0;
    margin-left: 10px;
    font-size: 15px;
    font-weight: 700;
    color: #fff;
  }
  .image-map-container {
    display: none;
  }
  .image-map-span {
    display: none;
  }
  .content-footer {
    display: none;
  }
}

@supports (-ms-flow-from: thingy) { /* Edge only */
  .icon-container{
    -ms-flex-pack: distribute;
        justify-content: space-around;
    padding: 0px 30px;
  }

  .content-container {
    -ms-overflow-style: scrollbar;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .icon-container{
    -ms-flex-pack: distribute;
        justify-content: space-around;
    padding: 0px 30px;
  }
}

.g-wrap{
  position: fixed;
  z-index: 9999999999999999;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #f4f4f4;
}
.gallery-grid {
  width: 400px;
  height: 100vh;
  background-color:white;
  overflow-y: scroll;
  overflow-x: hidden;
  direction: rtl
}

.gallery-grid-list{
  margin-top: 25px;
  direction: ltr
}

.thumbnail-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.gallery-header {
  margin:auto;
  padding:5px;
  text-align: center;
  height:auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
}

@media screen and (max-width: 767px) {
  .gallery-grid {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
  }
  .gallery-header{
    margin-top: 10%;
  }
}


.gallery-grid::-webkit-scrollbar
{
  width: 5px;
  border-right: 1px solid #808080;
}

.gallery-grid::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #555;
}



.slider-container{
  padding-right: 2%;
  padding-left: 2%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  width: auto;
  height: 100vh;
  background-color: rgb(30,30,32);
}
.closegallery {
  position: absolute;
  right: 1px;
  top: 1px;
  color: #fff;
  z-index: 999;
  cursor: pointer;
}
.slider-container p.cross {
  background: #165593;
  width: 35px;
  height: 35px;
  color: #fff;
  border-radius: 5px;
  font-size: 20px;
  padding: 2px;
}
.slider-container p {
  margin: 0;
}
.image-container-wrap{
  position: relative;
  width: 100vw;
}
.image-dim {
  height:auto;
  width:auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
}

.image-container-wrap .slider-container button{
  position: absolute;
  top: 10px;
  right: 10px;
}
.nav-icons{
  position: absolute;
  bottom: 100px;
  left: 50%;
  -ms-transform: translateX(-50%);
      transform: translateX(-50%);
  text-align: center
}
.arrow-icon{
  display: none;
  position: absolute;
  top: 25px;
  left: 15px;
}
.nav-icons svg{
  color: #fff !important;
  font-size: 70px !important;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .slider-container{
    padding: 0;
    width: 100vw;
  }
  .image-dim{
    height: auto;
    width: 100vw;
    object-fit: cover;
  }

  .arrow-icon{
    display: inherit;
  }
  .nav-icons {
    bottom: 50px;
  }
}

.side-panel-content {
  height: 100%;
  background: white;
  /* overflow: hidden; */
}
.side-panel-content .map-text +div{
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: start;
      justify-content: flex-start;
}

.search-wrapper {
  -ms-flex-direction: row;
      flex-direction: row;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  background-color: #F1F1F1;
  padding: 06px;
  margin-top: 4px;
  max-height: 120px;
  overflow: auto;
}
.program-category .program-type-label .category-icon-filter{
  position: absolute;
  right: 15px;
  border-radius: 5px;
  top: 29%;
}
.program-category .program-type-label .filter-title {
  color: #545860;
}
.program-category .program-type-label .category-icon-filter p{
  width: 22px;
  margin: 0 2px;
  color: #fff;
  border-radius: 5px;
  font-size: 10px;
  height: 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}
.program-type-label.active .resource-icon p.heart,.program-type-label.active .resource-icon p.thlarge ,.program-type-label.active .resource-icon p.home, .program-type-label.active .resource-icon p.pizza{
  border: 1px solid #FFFFFF;
  background: transparent;
  color: #FFFFFF;
}


.search-btn-div {
  font-weight: normal;
  font-style: normal;
  font-size: 12px;
  color: #545860;
}

.filter-record {
  color: #000000;
  padding: 15px;
}
.filter-record .program-category{
  margin:0 ;
}
.filter-record .program-category .composite-field .subcategory0,
.filter-record .program-category .composite-field .subcategory1,
.filter-record .program-category .composite-field .subcategory2,
.filter-record .program-category .composite-field .subcategory3{
  border: none;
  height: 38vh;
  direction: unset;
  overflow-y: auto;
  scroll-behavior: smooth;
  box-shadow: 0 5px 6px -6px black;
}
.filter-record .filter-record-heading {
  background: #FFFFFF;
  border-radius: 5px 5px 0 0;
  padding: 25px 34px;
  border: 1px solid #dedede;
  box-shadow: 0 4px 2px -4px #ababab;
  margin-bottom: 1px;


}

.filter-record .filter-record-heading span {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #545860;
}
.filter-record .program-category .program-type-label {
  background: #F8F9F9;
  padding: 12px 30px;
  width:100%;
  margin:0 !important;
  color:#545860 !important;
  border: 1px solid #CACCD2;
  position: relative;
}
.filter-record .program-category .program-type-0.active{
  background: #29ABE2;
}
.filter-record .program-category .program-type-1.active{
  background: #6FA963;
}

.filter-record .program-category .program-type-2.active{
  background: #DA6A6A;
}

.filter-record .program-category .program-type-3.active{
  background: #165593;
}
.program-category .program-type-label.active .filter-title{
  color: #fff;
}

.program-category .program-type-label span{
  color: #CACCD2;
}
.program-category .program-type-label span.filter-title{
  color: #545860;
  font-weight: bold;
}

.filter-btn-wrap{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 80%;
  -ms-flex-pack: center;
      justify-content: center;
  margin: 20px auto 50px;
}
.filter-record .filter-btn-wrap{
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-direction: column;
      flex-direction: column;
  padding: 20px 50px;
  border: 1px solid #CACCD2;
  border-top: none;
  background: #F8F9F9;
  border-radius: 0 0 5px 5px;
  margin: 0;

}
.detail-section{
  margin-left: 0px !important;
}

.title-address:hover{
  text-decoration: underline;
}

.apply-button{
  font-weight: 800;
  background: linear-gradient(0deg, #29ABE2, #29ABE2), #165593;
  border-radius: 5px;
  color: #fff;
  border: none;
  margin-bottom: 15px;
  padding: 13px;
}
.apply-button img{
  margin-right: 10px;
}
.clear-button {
  -ms-flex-pack: center;
      justify-content: center;
  display: -ms-flexbox;
  display: flex;
  border: none;
  background: transparent;
  color: #545860;
}

.filter-scrollable {
  width: 100%;
  height: calc(100vh - 255px);
  position: relative;
  direction: unset;
  overflow-y: scroll;
  scroll-behavior: smooth;
  z-index: 1;
}

.filter-scrollable::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #245397;
  background-color: #245397;
}


.filter-scrollable::-webkit-scrollbar
{
  width: 6px;
  border-right: 1px solid #808080;
}

.filter-scrollable::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #245397;
  background-color: #245397;
}

.searched-results{
  background: #F1F1F1;
}

.searched-keyword{
  padding: 5px 7px;
  margin:3px 5px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  /* position: relative;
  width: 161.11px;
  height: 35px; */

  position: relative;
  width: auto;
  height: auto;

  /* left: 0.61px;
  top: 0px; */

  background: #FCFCFC;
  border-radius: 5px;
}

.searched-keyword-div{
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 22px;
  color: #000;
  margin-left: 5px;
}
.searched-keyword-div .search-btn-div{
  font-weight: normal;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .side-panel-content {
    height: 100vh;
  }
  .filter-scrollable {
    height: calc(100vh - 200px);
  }
  .search-wrapper{
    margin-top: 10px;
  }
}

.pageTemplate-container {
  height: calc(100vh - 103px);
  display: block;
  width: 100%;
}
.map-container {
  height: 100%;
  width: calc(100% - 420px);
  position: absolute;
  display: inline-block;
}

.sidePanel-container {
  display: inline-block;
  height: 100%;
  width: 420px;
}

.notification-content{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  width: 100%
}


@media screen and (min-width: 320px) and (max-width: 768px) {
  .map-container {
    display: none;
  }
  .sidePanel-container {
    width: 100%;
    height: 100vh;
  }
  .content-container {
    overflow-y: auto !important;
  }
  .presentation{
    z-index: 1300!important;
  }
}
@media screen and (min-width: 769px) and (max-width: 1366px) {
  /* .sidePanel-container {
    width: 33.33%;
  }
  .map-container {
   width: calc(100% - 33.33%);
  } */
  .content-container {
    overflow-y: auto !important;
  }
}


.map-wrap{
  position: relative;
  height: 100%;
}
.template-withMap .sidePanel-container {
  width: 41vw;
}
.template-withMap .menu-view.div-wrap> div{
  width: 41vw;
}
.gm-fullscreen-control,.gmnoprint{
  display: none !important;
}
.open_map.template-withMap .abc{
  display: none;
}

.open_map.template-withMap .map-wrap{
  display: block;
}
.map-wrap{
  height: calc(100vh - 103px);
}

.resource-details .content-container{
  width: 41%;
}

@media (max-width: 1200px) {
  .template-withMap .map-wrap{
    display: none;
  }
  .template-withMap .sidePanel-container{
    width: 100%;
  }
  .template-withMap .menu-view.div-wrap> div{
    width: 100%;
  }
  .map-wrap{
    height: calc(100vh - 215px);
  }
  .map-wrap .view-switch{
    display: none;
  }
}

.pdf-name{
  overflow: hidden;
}
@media (max-width: 900px) {
  .map-wrap{
    height: calc(100vh - 250px) !important;
  }
}
.overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 2;
  background-color: #245497;
  opacity: 0.95;
  overflow-x: hidden;
}

.overlayContent {
  position: fixed;
  bottom: 20%;
  -ms-transform: translateY(-50%);
      transform: translateY(-50%);
  width: 100%;
  height: auto;
  text-align: center;
}
.text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-direction: column;
      flex-direction: column;
}
/*.medical-content {*/
  /*display: flex;*/
/*}*/
.home-header {
  color: white;
  margin-bottom: 2%;
  font-weight: 600;
  font-size: 18px;
}

.home-sub-header {
  margin:7px;
}

.resource-button {
  height: 170px;
  width: 220px;
  background-color: transparent;
  display: inline-block;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column;
}
.web-side-panel {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  color: #ffffff;
}
.m-side-panel{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  color: #ffffff;
}
.header-wrap.header-display{
  display: none;
}

@media only screen and (max-width: 600px) {
  .overlayContent {
    top: 55%;
    -ms-transform: translateY(-55%);
    transform: translateY(-55%);
  }
}

@media only screen and (max-height: 700px) {
  .resource-button {
    height:125px;
  }
}
@media screen and (min-width: 320px) and (max-width: 768px){
  .content-header .close{
    /*display: none;*/
  }
}
@media screen and (min-width: 320px) and (max-width: 1200px){
  .header-wrap.header-display {
    display: -ms-flexbox;
    display: flex;
    height: 56px;
    position: absolute;
  }
}
@media screen and (min-width: 320px) and (max-width: 1200px){
  .m-side-panel{
    display: none;
  }
  .main-screen{
    width: 100%;
  }
 /* .header-wrap.header-display{
    display: flex;
  }*/
}
.program-form-container {
  width: 100%;
  /*height: auto;*/
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}

.checkbox-div span{
  color: #F29223;
  border-radius: 5px;
}

.program-form-header-img{
  width: 100%;
}

.program-form-header-img img{
  height: 100%;
  width: 10%;
}

.form-header {
  padding: 2%;
  font-size: 37px;
  width: 100vw;
  text-align: center;
  color: #245397;
}

.login-details{
  width: 100%
}

.form-container {
  margin: auto;
  width: 75%;
}

.form {
  border-radius: 10px;
  box-shadow: 0px 5px 21px 1px rgba(0,0,0,0.25);
  /*display: flex;*/
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  background-color: #ffffff;
}

.program-form-group {
  width:100%;
  padding-bottom: 0;
  margin-bottom: 15px;
}

.schedule-phone .program-form-group{
  padding: 0px 0px;
}
.business-profileadmin-program-button-group{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
  padding: 8px 15px;
  background: white;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius:0 0 5px 5px;
}

.business-profileadmin-program-button-group button {
  background: #F29223;
  border: none;
  color: #fff;
  width: 25%;
}
.new-program-form {
  border-radius: 10px;
  box-shadow: 0px 5px 21px 1px rgba(0,0,0,0.25);
  /*display: flex;*/
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  background-color: #F8F9F9;
}
.new-program-form .new-program-form-group {
  width:100%;
  margin-top: 15px;
  margin-bottom: 0%;
}
.MuiCheckbox-root-749 {
  color: #CACCD2 !important;
}
.ql-toolbar.ql-snow{
  border: none !important;
  
}

.new-program-form .new-program-form-group header{ width: 100% !important; box-shadow: none !important; }
.new-program-form .new-program-form-group.program-tabs-height{ margin-top: 0 !important; }

.quill .ql-editor{ box-shadow: none !important; }
.ql-container.ql-snow{ height: auto !important; border:none !important; }
.tab-color-Health{ background-color: transparent !important; }


.ql-snow .ql-formats{
  padding: 4px 2px;
    background: #FFFFFF;
    border: 1px solid #CACACA;
    border-radius: 5px !important;
}

.new-program-form .new-program-form-group header,.new-program-form .new-program-form-group .swipable-view{
  width: 95%;
  margin: auto;
}

.new-program-form.program-tabs-height .new-program-form-group header{
  width: 100%;
}


.new-program-form-group #select-state{
  padding: 15.5px 14px;
}

.right-padding-10{
  padding-right: 10%;
}

.right-padding-5{
  padding-right: 5%;
}

.new-program-form-input{
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  background-color: #ffffff;
  height: 100% !important;
}

.info-icon {
  height: 16px;
  width: 18px;
  margin-left: 5px;
  cursor: pointer;
  color:#245497;
}

.button-container{
  width: 40%;
}

.composite-field{
  display: -ms-flexbox;
  display: flex;
  height: auto;
  position: relative;
}
.composite-field header{
  position: absolute;
}
.composite-field .swipable-view{
  margin-top: 73px;
}

.composite-field .categories-data label{
  width: 24%;
}

.composite-language-field{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin: 10px 0;
  padding: 0% 2%;
  height: auto;
  -ms-flex-direction: row ;
      flex-direction: row ;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.composite-language-field span{
  font-weight: bold;
}
.newlist-title{
  color: #FFFFFF;
  font-weight: bold;
  font-size: 2.2em;
  text-align: center;
  width: 100%;
}
.newsublist-title{
  color: #FFFFFF;
  font-weight: bold;
  font-size: 1.5em;
  text-align: center;
  width: 100%;
}

.required-label {
  color:red;
}
.uploaded-image{
  margin-bottom: 2%;
  width: auto;
  height: 150px;
}

.thumbnail-container{
  height: 100%;
  width: 200px;
  border: 1px solid grey;
  position: relative;
}

.thumbnail-image{
  height: 100%;
  object-fit: contain;
}

.remove-image{
  position: relative;
  left: 150px;
}

.image-overlay {
  position: absolute;
  top:0;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.65);
  width: 100%;
  height: 100%;
  display: none;
}

.subcategory-container {
  display: inline-block;
  width:100%;
  /*flex-direction: column;*/
  padding: 15px;
  background-color: #f6f6f6;
}

.filter-sub-category {
  padding: 20px 0 20px 30px;
}
.filter-sub-category label{
  height: 30px;
  width: 90%;
}
.subcategory-option {
  font-size: 14px;
  color: #545860;
  font-style: Roboto;
}
.filter-sub-category label .MuiPrivateSwitchBase-root-154 {
padding: 8px 12px;
}
.operation{
  display: -ms-flexbox;
  display: flex;
  padding: 0 4%;
  border-bottom: 1px solid #DEE1E5;
}
.operation >label{
  width: 140px;
}
.operation >label span{
  font-weight: bold;
}
.button-label{
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack:justify;
      justify-content:space-between;
}
.timing-collapse{
  width: 100%;
}

.timing-container{
  display:-ms-flexbox;
  display:flex;
  -ms-flex-align: center;
      align-items: center;
}
.timing-container .program-timing{
  -ms-flex-direction: column;
      flex-direction: column;
  display:-ms-flexbox;
  display:flex;
  width: 100%;
  text-align: center;
}
.program-timing .hr-operation {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  margin: 0 10px;
}

.timing-container .program-timing .from ,.timing-container .program-timing .to {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  font-weight: bold;
  color: #A6ABB6;
}
.timing-container .program-timing .inputbox {
  min-width: 114px;
  max-width: 114px;
  margin: 0 15px;
  font-weight: normal;
  background: #F8F9F9;
}
.timing-container .program-timing .inputbox:focus div{
  border: none;
  outline: none;
}

/*@media (min-width: 1280px) {*/
  /*.program-timing > div > div > div {*/
    /*max-width: 100%;*/
    /*flex-basis: 100%;*/
  /*}*/
/*}*/

@media (min-width: 1440px) {
 /* .program-timing > div > div > div {
    max-width: 50%;
    flex-basis: 50%;
  }*/
}

.custom-stepper-wrap{
  margin:20px 0px 10px 0px;
}

.program-timing-field {
  display: -ms-flexbox;
  display: flex;
}
.thumbnail-container:hover .image-overlay{
  display: inherit;
}


.error-message {
  margin-top: 3%;
  color: #c70b1b;
}



.name-group {
  width:100%;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.form h1 {
  font-weight: 600;
}

.div-wrapper{
  width:100%;
  padding: 1% 4% 4%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: justify;
      justify-content: space-between;
}
.div-wrapper.emp-title{
  background: #fff;
  height: 78px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.12);
  border-radius: 5px 5px 0 0;
  padding: 0 6%;
  margin-bottom: 1px;
}

.div-wrapper .add-more {
  width: 90%;
}
.div-wrapper .add-more textarea{
  width: 100%;
}
.div-wrapper .add-more input{
  padding: 10.5px 14px;
}
.div-wrapper .add-more svg{
  width: 20px;
  height: 20px;
}
.active-icon-circle {
  background: #6FA963;
  border-radius: 50%;
  padding: 6px;
}

.active-icon-done {
  background: #6FA963;
  border-radius: 50%;
  padding: 3px;
}

.uncomplete-icon-div {
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 0px 7px;
}

.parrent-div-wrapper {
  padding: 2% 15%;
}
.emp-title{

}
.btn-weapper{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 8px 15px;
  background: white;
  box-shadow: 0 -5px 5px -5px #b5b5b5;
  border-radius:0 0 5px 5px ;
}
.btn-weapper .btnMobile,.btn-weapper .btnMobile:hover{
  background: transparent;
  border: 2px solid #CACACA;
  color: #474747 !important;
}
.btn-weapper .btnMobile > span{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -ms-flex-align: center;
      align-items: center;
  width: 100%;
}
.btn-weapper .back-btn svg{
  color: #165593
}

.btn-weapper .btnMobile.next{
  background: #F29223;
  border: none;
  color: #fff!important;
}
.btn-weapper .btnMobile:hover{
  box-shadow: 0 0 11px rgba(33,33,33,.2);
}

.custom-stepper{
  width:50%;
}
.title-each{
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: #545860;
  margin: auto;
}

/*.step-completed{*/
  /*color: green;*/
  /*padding: 0;*/
/*}*/

.step-remaining{
  color: #255397;
  padding: 0;
}

.outer-div-wrapper {
  /*padding: 0 15%;*/
}
.program-form-lable{

}
.program-form-group div:nth-child(1) {
  min-height: 25px;
  width: 100%;
  text-align: left;
}
.program-form-group label{
  color: black;
  top:0;
}
.new-program-form-group .program-best {
  width: 24%;
  float: left;
  color: #474747;
}

.program-split-hours {
  width:43%;
  text-align: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
  margin-bottom:0;
}
.timing-wrapper.program-timing-wrap .program-split-hours label{
  width: 90%;
}
.timing-wrapper.program-timing-wrap .program-split-hours label span{
  font-weight: 600;
  color: #545860;
}
/*.program-split-hours label span {
  color:black;
}*/

.timing-wrapper{
  /*border-bottom: 1px solid lightgrey;*/
  margin-bottom: 1%;
  padding: 40px 56px 50px 56px;
}

.program-form-group fieldset{
  border-color: #dadada !important;
}

.program-form-inputborder{

}

.timing-checkbox {
  color:black;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
}

.sub-title{
  text-align: center;
  color: #000;
  fontSize: 17px;
}

.d-flex{
  display: -ms-flexbox;
  display: flex;
}

.without-padding input {
  padding: 10px 14px;
}
.without-padding-select {
  padding: 10px 14px !important;
  min-height: 0!important;
}
.composite-field .program-category .program-type-0,
.composite-field .program-category .program-type-1,
.composite-field .program-category .program-type-2{
  background-color: transparent;
  width:100%;
  margin:0 !important;
}
.composite-field .program-category .program-type-0 span,
.composite-field .program-category .program-type-1 span,
.composite-field .program-category .program-type-2 span{
 color: #545860;
}
.composite-field > div{
  width: 100%;
}
.composite-field .program-category .category-title{
  font-size: 18px;
  font-weight: 600;
}

.schedule-phone .program-category .category-title{
  font-size: 16px;
  font-weight: 600;
  color: white;
}

.subcategory0{
  border: 2px solid #245397;
}
.subcategory1{
  border: 2px solid #1fb400;
}
.subcategory2{
  border: 2px solid #808080;
}
.subcategory3{
  border: 2px solid #bf1a05;
}

.program-type-checkbox {
  display: none !important;
  background-color: white;
}

.composite-field .program-category{
  margin-bottom: 10px;
  -ms-flex: 1 1;
      flex: 1 1;
  height: 48px;
  border-right: 1px solid #A6ABB6;
  background: white;
}
.composite-field .program-category:last-child{
  border: none;
}

.schedule-title {
  background-color: #fff;
  width: 100%;
  margin: 0 !important;
  color: white;
  padding: 15px;
  margin-bottom: 1px !important;
  box-shadow: 0 8px 6px -6px #cccccc;
  border-radius: 5px;
  background-color: #3eaaee;
}

.schedule-title-active {
  background-color: #ececec;
  width: 100%;
  margin: 0 !important;
  color: white;
  padding: 15px;
  box-shadow: 0 8px 6px -6px #cccccc;
  border-radius: 5px;
  background-color: #F29223;
}

.program-category .schedule-title ,.program-category .schedule-title-active{
  position:relative;
}
.program-category .schedule-title svg ,.program-category .schedule-title-active svg{
  color: white;
  font-size: 18px;
  font-weight: 600;
  position: absolute;
  right: 15px;
}
.error-div{
  margin-left: 40px;
  margin-top: 10px;
}

.middle-title{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}

.btn-single-weapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
  padding: 8px 15px;
  background: white;
  box-shadow: 0 -5px 5px -5px #b5b5b5;
  border-radius: 0 0 5px 5px;
}
.btn-single-weapper .btnMobile ,.btn-single-weapper .btnMobile:hover{
  background: #F29223;
  border: none;
  color: #fff;
}
.btn-single-weapper .btnMobile > span{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -ms-flex-align: center;
      align-items: center;
  width: 100%;
}
.submit-title{
  font-size: 60px;
  font-weight: bolder;
  text-align: center;
  color: #255397;
  margin:0;
}


.submit-message{
  font-size: 23px;
  font-weight: bolder;
  text-align: center;
  color: #000000;
}
.check-circle{
  font-size: 115px;
}

.check-mark{
  padding:0;
  text-align: center;
  font-size: 46px;
  color: #1fb400;
  margin:0;
}
.outer-div-wrapper-submit {
  padding:10% 15%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
}

.schedule-phone{
  /* display: none; */
}
.schedule-pc{
  display: block;
  background-color: #F8F9F9;
  max-height: calc(100vh - 250px);
  overflow-y: auto;
}

@media screen and (max-width: 576px) {
  .schedule-pc {
    max-height: calc(100vh - 270px);
  }
}


.schedule-pc > div > div{
  transition: transform 1s cubic-bezier(0.15, 0.3, 0.25, 1) 0s !important;
}

.business-profile-admin-form-container .business-profile-header{
  background: #fff;
  height: 78px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.12);
  border-radius: 5px 5px 0 0;
  padding: 0 3%;
  margin-bottom: 1px;
  font-weight: bold;
}

.program-form-group .input-box {
  width: 86%;
  background: #fff;
  box-shadow: 0 8px 6px -6px #bfbfbf;
  height: 45px;
  margin: 0;
}

.bottom-title {
  text-align: center;
  margin: 60px auto;
}
.without-padding-select-cities {
  padding: 5px 14px !important;
  min-height: 0 !important;
}

.select-div {
  min-height: 47px !important;
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
}
.schedule-error-message {
  margin-top: 15px;
  text-align: center;
  color: red;
}
.program-timing-wrap{
  padding: 0px;
}

.without-padding {
  margin-bottom: 0 !important;
  height: auto !important;
  background: #FFFFFF;
}
.schedule-pc-desktop header{
  border-radius: 5px 5px 0 0 ;
}

.schedule-pc-desktop header button{
  box-shadow: 0 8px 6px -6px black;
  height: 60px;
  border-right:1px solid #A6ABB6 ;
  max-width: inherit;
  -ms-flex: 1 1;
      flex: 1 1;
  background: #F7F7F7;
  /*border-radius: 5px 0 0 0;*/
}
.schedule-pc-desktop header button:first-child{
  border-radius: 5px 0 0 0 ;
}
.schedule-pc-desktop header button:last-child{
  border-radius: 0 5px 0 0  ;
}

.schedule-pc-desktop header button span{
  font-weight: bold;
}
.schedule-pc-desktop header button.activeTabClass{
  background: #FFFFFF;
  color: #545860;
  position: relative;
  min-width: 65px;
}
.activeTab ,.activeTabClass{
  background: #ffffff !important;
  color: #545860 !important;
  position: relative;
  min-width: 65px;
}
.schedule-pc-desktop header button.activeTabClass:after,.activeTab :after,.activeTabClass{
  content: '';
  position: absolute;
  background-color: #f8f9f9;
  width: 100%;
  height: 9px;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.swipable-view{
  background: #F8F9F9;
}
.admin-program-button-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 0 20px;
}

.admin-program-button-group .btn-saveandpub{
  background: #F29223;
  color: #fff;
  margin: 1% 0%;
  width: 30%;
  box-shadow: none;
  padding: 10px;
  font-weight: bold;
  max-width: 190px;
}

body .div-wrapper .company-logo {
  margin-bottom: 1%;
  box-shadow: none;
  background-color: transparent;
}
.admin-program-button-group .btn-saveanddra{
  background: rgb(255, 255, 255);
  color: #474747;
  padding: 10px;
  margin: 1%;
  border: 1px solid #CACACA;
  box-shadow: none;
  font-weight: bold;
}
.admin-program-button-group .btn-saveanddra svg{
  margin: 0 5px ;
  color: #6FA963;
}

.about-description-box{
  width: 90%;
}
.quill {
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
border-radius: 5px;
}

.quill .ql-editor{
  box-shadow: none;
  max-height:80px;
  background:#FFFFFF;
  min-height:80px;
  border: none;
}
.tab-main{
  -ms-flex-pack: justify;
      justify-content: space-between;
  border-radius: 5px;
}

.newTimePicker{
  padding: 10px;
  border-radius: 10px;
}

/* 
.tab-main .activeTab:nth-of-type(1), .tab-main .active-tab:nth-of-type(1), .activeTab:nth-of-type(1) span:after {
  background: #29abe2 !important;
  color: #fff !important;
}

.activeTab:nth-of-type(1) span .category-icon, .active-tab:nth-of-type(1) span .category-icon{
  border: 1px solid #fff;
  border-radius: 4px
}
.tab-main .activeTab:nth-of-type(2), .tab-main .active-tab:nth-of-type(2), .activeTab:nth-of-type(2) span:after {
  background: #6fa963 !important;
  color: #fff !important;
}

.activeTab:nth-of-type(2) span .category-icon, .active-tab:nth-of-type(2) span .category-icon  {
  border: 1px solid #fff;
  border-radius: 4px
}
.tab-main .activeTab:nth-of-type(3), .tab-main .active-tab:nth-of-type(3), .activeTab:nth-of-type(3) span:after {
  background: #da6a6a !important;
  color: #fff !important;
} */

.activeTab:nth-of-type(3) span .category-icon, .active-tab:nth-of-type(3) span .category-icon {
  border: 1px solid #fff;
  border-radius: 4px
}
/* .tab-main .activeTab:nth-of-type(4), .tab-main .active-tab:nth-of-type(4), .activeTab:nth-of-type(4) span:after {
  background: #165593 !important;
  color: #fff !important;
} */

.activeTab:nth-of-type(4) span .category-icon, .active-tab:nth-of-type(4) span .category-icon  {
  border: 1px solid #fff;
  border-radius: 4px
}


.program-tabs-height .tab-main button.active-tab{
  background-color: #fff !important;
  color: black !important;
  border-bottom: 2px solid #c6c6c6cc;
  border-right: 1px solid #c6c6c6cc !important;
}

.div-wrapper .add-more{margin: 10px 0 0;}
.div-wrapper .add-more svg {    transform: rotateX(
  190deg
  );}



.tab-main .teb-cont{
  max-width: inherit;
  border-right: 1px solid #A6ABB6;
  font-weight: bold;
}
.tab-main .teb-cont:last-child{
  max-width: inherit;
  border-right:none;
}
/* .tab-main .active-tab:nth-of-type(1) {
  background: #29abe2 !important;
  color: #fff !important;
} */
.tab-main button span {
  display: contents;
}
.category-width label{
  /*width: 24%;*/
}
.tab-color-Health {
  background-color: #c9eaf8;
}

.tab-color-Food {
  background-color: #dbe9d8;
}

.tab-color-Housing {
  background-color: #f6dada;
}

.tab-color-Education {
  background-color: #c5d4e4;
}

.up-icon {
  width: 35px;
  height: 35px;
  background: #29ABE2;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
}
.direction-parent-div > div {
  padding-right:5px ;
}
.direction-icon-padding >div{
  padding-right: 5px;
}
.direction-icon-padding >div input{
  height: 47px;
  padding: 0 15px;
}
.direction-icon {
  width: 35px;
  height: 35px;
  background: #29ABE2;
  color: #fff;
  padding: 8px;
  border-radius: 5px;
}
.add-more .directions-div , .add-more .youtube-div {
  margin-top: 1%;
}
.directions-div > div ,.youtube-div > div{
  padding-right: 5px;
  background: white;
  height: 47px;
}
.program-form-container .stepper-width {
  width: 65%;
  background-color: transparent;
}
.program-tabs-height .tab-main button {
  height: auto;
  min-height: 50px;
  padding: 0;
  min-width: auto;
  -ms-flex-positive: 1;
      flex-grow: 1;
  border-right: none !important;
}
.program-tabs-height .tab-main button .category-icon {
  margin-top: 0;
  margin-right: 15px;
}


.schedule-pc{ background-color: #fff; }

@media screen and (min-width: 1024px) and (max-width: 2560px) {
  .form-container {
    margin: auto;
    width: 78%;
  }
  .outer-div-wrapper{
   /* padding: 0px 10%;*/
  }

}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .container {
    /*height: auto;*/
    /*width: 100vw;*/
    /*padding-bottom: 10%;*/
  }
  .btnMobile{
    width:25% !important;
  }
  .btn-weapper{
    padding: 0 15px;
  }
  .program-info-timestamp {
    padding: 0 15px;
    font-size: 1rem;
    margin-bottom: 10%;
  }
  .btn-single-weapper{
    padding: 0 15px;
    margin-bottom: 10%;
  }
  .form-container {
    width: 100%;
    margin: auto 10%;
  }
  .form {
    border: none;
    margin-top: 75px;
  }

  .new-program-form-group{
    margin-bottom: 15px;
  }
  .timing-container{
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: start;
        align-items: flex-start;
  }
  .program-timing{
    width: 100%;
  }
  .program-split-hours{
    margin:0;
  }
  .button-container{
    width: 60%;
  }
  .form-header{
    padding: 20% 2% 2% 2%;
  }
  .uploaded-image {
    width: 100%;
  }

  .custom-stepper {
    display: none;
  }
  .outer-div-wrapper{
    padding: 0;
  }
  .title-each{
    font-size: 24px;
    margin: 20px 5px;
  }
  .schedule-pc{
    /* display:none; */
  }
  .schedule-phone{
    display:block;
  }
}
@media (min-width: 960px){
          .res_tab{
            padding: 6px 0px;
            font-size: 12px;
          }
}
@media screen and (max-width: 768px){
  .form {
    border: none;
    margin-top: 15%;
  }
  .program-tabs-height .tab-main button {
      min-height: 65px !important;
      border-right: 1px solid #ddd;
  }
  .program-tabs-height .tab-main button:last-child {
    border-right: none;
  }
  .program-tabs-height .tab-main button .category-icon {
    margin-right: 0;
  }
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .tab-main button span {
    display: block;
  }
  .swipable-view >div{
   height: auto !important;
  }
  .program-form-container .stepper-width {
    width: 100%;
  }
  .container {
    /*height: auto;*/
    /*width: 100vw;*/
    /*padding-bottom: 10%;*/
  }
  .form-container {
    width: 100%;
    margin: auto 20px;
  }
  .btnMobile{
    width:25% !important;
  }
  .newlist-title{
    margin-top: 50px !important;
  }

  .btn-single-weapper ,.btn-weapper{
    padding: 15px;
  }

  .program-info-timestamp {
    padding: 0 15px;
    margin-bottom: 10%;
    font-size: 1rem;
  }
  .form {
    border: none;
    margin-top: 75px;
  }
  .div-wrapper .add-more ,.admin-program-form-group > textarea{
    width: 100%;
  }
  .timing-container{
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: start;
        align-items: flex-start;
    background: #f8f9f9;
  }
  .program-timing .hr-operation ,.program-split-hours{
    -ms-flex-pack: left;
        justify-content: left;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .program-timing .hr-operation .from, .program-timing .hr-operation .to {
    width: 100%;
  }
  .program-timing .hr-operation .from .label-text, .program-timing .hr-operation .to .label-text {
    width: 80px;
    text-align: left;
  
      }
      .program-timing .hr-operation div  {
        width: 100%;
      }
  .program-timing{

    width: 100%;
  }
  .timing-container .program-timing .inputbox {
    min-width: unset;
    margin: 0 !important;
    -ms-flex: 1 1;
        flex: 1 1;
  }
  .program-split-hours{
    margin:0;
    width: 100%;
  }
  .button-container{
    width: 60%;
  }
  .form-header{
    padding: 20% 2% 2% 2%;
  }
  .uploaded-image {
    width: 100%;
  }
  .custom-stepper {
    display: none;
  }
  .outer-div-wrapper{
    padding: 0;
  }
  .div-wrapper.emp-title{
    height: auto;
  }
  .title-each{
    font-size: 24px;
    margin: 20px 5px;
  }
  .swipable-view label{
    width: 48% !important;
  }
  .sub-title{
    text-align: center;
    color: #000;
    font-size: 15px;
    margin-bottom: 70px;
  }
  .schedule-pc{
    display:block;
  }
  .schedule-phone{
    display:block;
  }
  .composite-language-field {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    padding: 0;
  }
  .new-program-form-group .program-best{
    width: 48%;
  }
  .middle-title {
    -ms-flex-pack: left;
        justify-content: left;
  }
  .outer-div-wrapper-submit{
    padding: 5% 10%;
  }
  .operation{
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .submit-title{
    font-size: 38px;
  }
  .submit-message{
    font-size: 16px;
  }
  .timing-wrapper {
    padding: 0;
    border:0;
  }
  .program-timing-field{
    -ms-flex-direction: column;
        flex-direction: column;
  }
  .timing-collapse{
    background-color: #f6f6f6;
  }
  .check-circle {
    font-size: 70px;
  }
  .select-div {
    min-height: 0px !important;
  }
  .business-profileadmin-program-button-group button{
  width: 100%;}
  .program-form-group .input-box {
    width: 100%;
  }
  .schedule-pc-desktop{
    display: none;
  }
  .admin-program-button-group .btn-saveandpub{
    width: 100%;
  }
  .composite-field .categories-data label{
    width: 49%;
  }
  .right-padding-10 , .right-padding-5{
    padding: 0;
  }
  .direction-icon-padding >div input{
    height: 47px;
  }

  }
@media screen and (max-width: 768px) {
  .select-div {
    min-height: 38px !important;
    -ms-flex-align: center;
        align-items: center;
    display: -ms-flexbox;
    display: flex;
  }
  .composite-field {
    margin-top: 10px;
  }
  .newlist-title {
    font-size: 22px;
  }
  .newsublist-title {
    font-size: 12px;
  }
  .div-wrapper {
    /* max-height: calc(100vh - 390px); */
    overflow-x:hidden;
    scroll-behavior: smooth;
  }
  .program-form-container {
    height: auto !important;
  }
  .quill {
    width: 100% !important;
  }
  .bottom-title {
    margin: 30px auto !important;
  }
  .tab-main button {
    min-height: 45px !important;
  }
  .tab-main button span img {
    margin-right: 4px;
  }
  .tab-container-div .activeTabClass:nth-of-type(1) {
    background: #29abe2 !important;
    color: #fff !important;
  }

  .activeTabClass:nth-of-type(1) span .category-icon, .activeTabClass:nth-of-type(2) span .category-icon, .activeTabClass:nth-of-type(3) span .category-icon,
  .activeTabClass:nth-of-type(4) span .category-icon{
    border: 1px solid #fff;
    border-radius: 4px
  }
  .tab-container-div .activeTabClass:nth-of-type(2){
    background: #6fa963 !important;
    color: #fff !important;
  }


  .tab-container-div .activeTabClass:nth-of-type(3){
    background: #da6a6a !important;
    color: #fff !important;
  }


  .tab-container-div .activeTabClass:nth-of-type(4) {
    background: #165593 !important;
    color: #fff !important;
  }




}


@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .program-form-group fieldset{
    margin-top: 4px;
  }
  .new-program-form-group fieldset{
    margin-top: 4px;
  }
}

@media screen and (min-width: 320px) and (max-width: 375px) {
  .program-tabs-height .tab-main button span > div {
   font-size: 11px;
  }
}
.outer-div {
  background-color: #ffffff;
  padding: 5%;
  width: 100vw;
  height: auto;
  min-height: 100vh;
  overflow-x: hidden;
}

.program-container{
  background-color: white;
  margin: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: start;
      align-items: flex-start;
}

.action-btn {
  width: 22%;
  margin-bottom: 2%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
}


@media screen and (min-width: 320px) and (max-width: 767px) {
  .outer-div {
    width: 100vw;
    height: auto;
    padding: 5% 0 0 0;
    overflow-x: hidden;
  }
  .action-btn {
    width: 70%
  }
}
.main-div{
  margin: auto;
  height: 100vh;
  min-height: 100vh;
  min-width: 100%;
  width: 100%;
  padding: 1% 2% 1% 2%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: start;
      justify-content: flex-start;
}
.close-listing {
  display: none !important;
  position: absolute;
  left: 15px;
  top: 0px;
  font-size: 32px;
  color: #fff;
  z-index: 999;
  cursor: pointer;
}
.alert-div{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  width: 100%;
  font-size: 28px;
  -ms-flex-align: center;
      align-items: center;
  color: #fff;
}

.sub-alert-div{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin: 1%;
  width: 100%;
  -ms-flex-pack: start;
      justify-content: flex-start;
}

.claim-user{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: initial;
      align-items: initial;
  -ms-flex-pack: justify;
      justify-content: space-between;
  cursor: pointer
}

.image-list-item{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  border-bottom: 1px solid #d5d5d5;
  padding: 12px 2%;
  font-size: 12px;
  color: #191919;
  width: 100%;
  margin: auto;
}

.dragged-item{
  background-color: #fff;
  border: none;
  box-shadow: 0 0 16px -5px #aaaaaa;
}

.drag-icon{
  color: #757575;
  margin-right: 1%;
  cursor: move;
}

.edits {
  padding: 1% 2%;
  border: 1px solid #a8a8a8;
  border-radius: 6px;
  margin: 5px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.edit-text{
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .main-div {
    padding: 0px;
  }
  .edit-text {
    width: 50%;
  }
}

.main-div .WithStyles-Paper--root-1676 {
  width: -webkit-fill-available;
  padding: 1%;
  margin-top: 2%;
  border-radius: 0;
}

.PasswordReset-modal-29{
  z-index: 999999999 !important;
}
.main-container{
  /*padding: 2%;*/
}

.set-password-container {
  width: 50%;
  margin: auto;
  border: 1px solid grey;
  padding: 2%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 2%;
  background: #FFFFFF;
  border-radius: 5px 5px 5px 5px;
}

.set-password-error {
  width: 100%;
  color: #c70b1b;
}

.spinner {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 20%;
}

.overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 2;
  background-color: #245497;
  opacity: 0.95;
  overflow-x: hidden;
}

.overlay h1{
  color: white;
  text-align: center;
}


@media screen and (min-width: 320px) and (max-width: 767px) {
  .set-password-container {
    width: 100%;
    /*margin-top: 30%;*/
    border: none;
  }
  .spinner {
    margin-top: 10%;
  }
}


.main-wrap-about-us{
  min-height: 100%;

}

.about-us-title-div {
  color: #fff;
  font-weight: 800;
  font-size: 40px;
  width: 95%;
}

.divider-div {
  height: 2px;
  background: rgba(0, 0, 0, 0.12);
  margin-bottom: 10px;
}

.custom-container {
  width: 95%;
  margin: 0 auto;
  background: #fff;
  border-radius: 8px;
  padding: 24px;
  overflow: auto;
  height: calc(100% - 80px);

}
.close-btn{
  position: absolute;
  top: 48px;
  left: 23%;
  font-size: 24px;
  color: #fff;
}

.text-indent{
  /*text-indent:80px;*/
}

@media (max-width: 768px) {
  .close-btn{
    left: 4%;
    top: 30px;
  }
  .custom-container {
    width: 95%;
  }
}

@media (max-width: 1200px) {
  .main-wrap-about-us,.custom-container{
    height: auto !important;
  }
}
.main-wrap-about-us{
  min-height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: start;
      justify-content: flex-start;
  -ms-flex-align: center;
      align-items: center;
}

.about-us-title-div {
  color: #fff;
  font-weight: 800;
  font-size: 40px;
  width: 95%;
}

.divider-div {
  height: 2px;
  background: rgba(0, 0, 0, 0.12);
  margin-bottom: 10px;
}

.custom-container {
  width: 95%;
  margin: 0 auto;
  background: #fff;
  border-radius: 8px;
  padding: 24px;
  overflow: auto;
  height: calc(100% - 80px);
}
.close-btn{
  position: absolute;
  top: 48px;
  left: 23%;
  font-size: 24px;
  color: #fff;
}

.text-indent{
  /*text-indent:80px;*/
}

.iframe-container{
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
@media (max-width: 768px) {
  .close-btn{
    left: 4%;
    top: 30px;
  }
  .custom-container {
    width: 95%;
  }
}

.main-wrap-about-us{
  min-height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: start;
      justify-content: flex-start;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
}

.about-us-title-div {
  color: #fff;
  font-weight: 800;
  font-size: 40px;
  width: 95%;
}

.divider-div {
  height: 2px;
  background: rgba(0, 0, 0, 0.12);
  margin-bottom: 10px;
}

.custom-container {
  width: 95%;
  margin: 0 auto;
  background: #fff;
  border-radius: 8px;
  padding: 24px;
  overflow: auto;
  height: calc(100% - 80px);
}
.close-btn{
  position: absolute;
  top: 48px;
  left: 23%;
  font-size: 24px;
  color: #fff;
}

.text-indent{
  /*text-indent:80px;*/
}

@media (max-width: 768px) {
  .close-btn{
    left: 4%;
    top: 30px;
  }
  .custom-container {
    width: 95%;
  }
}
.view-switch{
    position: absolute;
    z-index: 30;
    right: 50px;
    top: 20px;
    border-radius: 10px;
    border: 1px solid #E7E7E8;
    overflow: hidden;
}

.view-switch button{
    height: 48px;
    background-color: white;
    color: #01225A;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 20px;
    text-transform: uppercase;
    border: none;
    width: 107px;
    cursor: pointer;
}

.view-switch .active-switch{
    color: #FFF;
    background-color: #01225A;
}
.admin-main-container{
  margin: auto;
  height: auto;
  min-height: 100%;
  min-width: 100%;
  width: auto;
}

.btn-img {
  height: 15px;
  width: 15px;  
}
.admin-main{
  padding: 1% 2% 2% 2%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
}
.admin-main >div{
  max-height: calc(100vh - 118px);
  overflow-y: auto;
  width: 100% !important;
}
.logout{
  color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}

.header-sort{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  font-size: 14px;
  font-weight: 500;
}

.needs-approval {
  color: #bf2b1c;
}

.published {
  color: #2f973e;
}

.unpublished {
  color: #cc7a00;
}

.table-header{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.sort-icon-group{
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-left: 3px;
}

.sort-icon{
  color: #aaaaaa;
  cursor: pointer;
}

.sort-icon:hover {
  color: #888888;
}

.active-sort-icon {
  color: #888888;
}

.disclaimer-header{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.ql-align-center{
  text-align: center;
  list-style-position: inside;
}

.ql-align-right{
  text-align: right;
  list-style-position: inside;
}

.ql-align-justify{
  text-align: justify;
  list-style-position: inside;
}

.ql-size-huge {
  font-size: 2.5em;
}

.ql-size-large {
  font-size: 1.5em;
}

.ql-size-small {
  font-size: 0.75em;
}

.admin-side-bar{
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 1;
}

.btn-img-done{
  height: 10px;
  width: 10px;
  margin-right: 5px;
}

.status-wrap {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.input-wrap button{
  margin-left: 0px;
  margin-top: inherit;
  background-color: #245497;
  color: white;
  border: 0;
  border-radius: 5px;
  padding: 5px 15px;
}
.btn-section {
  display: -ms-flexbox;
  display: flex;
}
.my-account-text{
  color:#FFFFFF;
  font-weight: 800;
  margin-top: 5px;
  font-size: 35px;
}
.login-security-header{
  font-weight: bold;
  font-size: 24px;
  color: #545860;
}
.icon-div{
  font-size: 15px !important;
  margin-right: 5px;
}
.delete-icon{
  font-size: 15px !important;
  color: #fff;
}
.program-entries-header{
  font-weight: bold;
  font-size: 24px;
  color: #545860;
  display: block;
  margin-left: 15px;
  margin-bottom: 0;
}
.data-table table  tr td.program-font-color {
  color: #29ABE2;
}
.data-table table  tr td.number-font-color ,.data-table table  tr th.number-font-color {
  color: #A6ABB6;
}
.data-table table thead tr th ,.data-table table tr td{
  padding: 4px 5px 4px 24px;
  border: none;
  text-align: left;
}
.data-table table tr:nth-child(even) {background: #F8F9F9}
.pagination{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: stretch;
      align-items: stretch;
  float: right;
}
.data-table table thead tr:first-child{
  border-bottom:1px solid #DBDBDB ;
}
.data-table table tfoot{
  box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.12), 0px -1px 2px rgba(0, 0, 0, 0.12);
}
.data-table table thead tr th {
background-color:#F7F7F7
}
.btn-section .btn-view{
  background: #29ABE2;
  font-size: 10px;
  font-weight: bold;
  text-transform: none;
  min-width: 32px;
}
.delete-icon-div{
  height: 28px;
  width: 28px;
  background: #DA6A6A;
  border-radius: 5px;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  display: -ms-flexbox;
  display: flex;
  color: #FFFFFF;
  margin: 0 5px;
  cursor: pointer;
}
.btn-section .btn-duplocate{
  background: #165593;
  box-shadow: 0px 2px 3px 4px #f9f9f9;
  margin: 0 5px;
  font-size: 10px;
  text-transform: none;
  font-weight: bold;
  min-width: 32px;
}
.btn-section .btn-duplocate svg ,.btn-view svg {
  margin-right: 5px;
}
.label-name {
  font-size: 14px;
  font-weight: 600;
}
.btn-section .btn-duplocate:hover{
  background: #165593;
}
.show-page-label > div {
  border: 1px solid #e2e2e2;
  background: #F8F9F9;
  border-radius: 5px;
  padding: 0 5px;
  margin-left: 13px;
  font-size: 14px;
}
.show-page-label > div:before{
  display: none;
}
.export-wrap img{
  margin-right: 5px;
}
.tableCell-font-style{
  font-size: 14px;
  text-align: justify;
}
.action-div {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  font-size: 14px;
}
.showing-page-div {
  float: left;
  font-size: 14px;
}
.no-data-div {
  text-align: center;
  padding: 20px;
  font-size: 15px;
}
.btn-section .btn-view:hover{
  box-shadow: 0px 2px 3px 4px #f9f9f9;
  background: #29ABE2;
}
.pagination-div {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}
.pagination-showpage {
  float: left;
}
.pagination-pagenumber {
  float: right;
}
.data-title .export-wrap button.web-btn{
  display: block;
}
.data-title .export-wrap button.m-btn{
  display: none;
}
.header-display {
  display: none;
}

.loader {
  text-align: center;
  display: -ms-flexbox;
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  height: 100%;
}
.disabled-page-btn {
  background: #A6ABB6;
  border-radius: 5px;
  color: #fff;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  width: 25px;
  margin: 0 5px;
  max-height: 25px;
}
@media screen and (min-width: 320px) and (max-width: 425px) {
  .admin-main-container {
    /* width: min-content; */
    width: 100%;
    min-height: 100%;
  }
  .table-header{
    -ms-flex-direction: column;
        flex-direction: column;
  }
  .export-wrap{
    text-align: center;
  }
  .btn-single-weapper, .btn-weapper.btn-wrap-mobile{
    display: block;
  }
  .program-info-admin-form-container .btn-single-weapper, .btn-weapper.btn-wrap-mobile button{
    width: 100% !important;
    max-width: 100%;
  }
  .edit-section-last{
    margin-bottom: 70px;
  }
}
@media screen and (min-width: 320px) and (max-width: 568px) {
  .data-table table tr td.m-view-hide, .data-table table tr th.m-view-hide{
    display: none;
  }
  .data-table table tr td.m-hide, .data-table table tr th.m-hide{
  display: none;
  }

}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .admin-main-container {
    /* width: min-content; */
    width: 100%;
    min-height: 100vh;
  }
  .showing-page-div {
    font-size: 13px;
  }
  .data-table {
    max-width: 100%;
    overflow: auto;
  }
  .btn-section{
    -ms-flex-pack: center;
        justify-content: center;
  }
  .data-title {
    -ms-flex-direction: column;
        flex-direction: column;
    padding: 8px !important;
    width: 100% !important;
  }
  .admin-main >div {
       overflow-X: hidden !important;
  }
  .my-account-text , .newlist-title ,.about-us-title-div{
    margin-top: 30px;
  }
  .program-entries-header {
    margin-left: 0;
    font-size: 18px !important;
  }
  .data-title .export-wrap {
    margin-right: 0 !important;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-pack: center;
        justify-content: center;
  }
  .data-title .export-wrap .search-box {
    -ms-flex: 1 1;
        flex: 1 1;
    min-width: 100px;
    margin-top: 10px;
  }
  .data-title .export-wrap .search-box div {
    margin-right: 0 !important;
  }
  .show-page-label > div {
    margin-left: 2px;
    width: 50px;
  }
  .show-page-label {
    font-size: 11px !important;
  }
  .mobile-view-hide {
    display: none;
  }
  .data-table table tr td.number-font-color, .data-table table tr th.number-font-color{
    display: none;
  }

  .data-table table tr td.mobile-view-hide,.data-table table tr th.mobile-view-hide{
    display: none;
  }
  .btn-section .btn-duplocate svg ,.btn-view svg {
    margin-right: 0px;
  }
}
@media screen and (min-width: 320px) and (max-width: 1200px){
  .data-title .export-wrap button.web-btn{
    display: none;
  }
  .data-title .export-wrap button.m-btn{
    display: block;
    font-size: 11px;
  }
  .header-display {
    display: -ms-flexbox;
    display: flex;
  }
  .data-table table tr td.mobile-view-hide,.data-table table tr th.mobile-view-hide{
    display: none;
  }
}



.alert-admin-main-container{
  margin: auto;
  height: auto;
  min-height: 100vh;
  min-width: 100%;
  width: auto;
}

.view-dlt-div {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}
.table-body{
  color: red;
}

.alert-admin-main{
  background-size: contain;
  padding: 1% 2%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
}
.alert-admin-main > div{
  max-height: calc(100vh - 64px);
  overflow-y: auto;
  width: 100%;
}

.WithStyles-Paper--root-187 {
  width: 100%;
  padding: 1%;
  margin-top: 0px;
  border-radius: 0;
}

.alert-admin-main table tr th,
.alert-admin-main table tr td{
  display: table-cell;
  padding: 4px 40px 4px 24px;
}

.alert-admin-main table tr td button{
  background: #29ABE2;
  color: #fff;
  margin-right: 5px;
}
.alert-admin-main table tr td svg{
  /*font-size: 9px !important;*/
  /*margin-right: 10px;*/
  /*background: #A6ABB6;*/
  /*color: #fff !important;*/
  /*padding: 7px;*/
  /*width: 4em;*/
  /*height: 4em;*/
  /*border-radius: 5px;*/
}

.page-btn{
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 20px;
  padding: 1%;
  font-size: 14px;
  border-radius: 2px;
  cursor: pointer;
  -ms-flex-align: center;
      align-items: center;
  margin: 2px;
  text-align: center;
  -ms-flex-pack: center;
      justify-content: center;
  border-right:1px solid #DEE1E5 ;
}
.page-btn:hover {
  background-color: #ececec;
}

.n-btn ,.p-btn{
  background: #A6ABB6;
  border-radius: 5px;
  color: #fff;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  width: 25px;
  margin: 0 5px;
  max-height: 25px;
}
.disabled-p-btn ,.disabled-n-btn {
  background: #A6ABB6;
  border-radius: 5px;
  color: #fff;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  width: 25px;
  margin: 0 5px;
  max-height: 25px;
}
.data-title{
  padding: 15px;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.12);
  margin-bottom: 2px;
}

.data-title .export-wrap{
  display: -ms-flexbox;
  display: flex;
  margin-right: 15px;
  -ms-flex-align: center;
      align-items: center;
}

.data-title h4{
  font-size: 24px;
  font-weight: bold;
  color: #545860;
}

.data-title .export-wrap button {
  background:transparent;
  color: #000;
  border: 1px solid #CACACA;
  box-sizing: border-box;
  padding: 6px 16px;
  border-radius: 5px;
  font-style: normal;
  font-weight: bold;
  margin-right: 8px;
  font-size: 13px;
  line-height: 24px;
  font-family: Roboto;
  text-transform: uppercase;
}

.show-page-label {
  font-size: 14px;
}

.data-title .export-wrap button:hover{
  box-shadow: 0px 2px 3px 4px #f9f9f9;
}

.search-box > div{
  border: 1px solid #e2e2e2;
  margin: 0 10px;
  background: #F8F9F9;
  border-radius: 5px;
  padding: 0 5px;
  font-family: Roboto;
  font-weight: normal;
  color: #545860;
}
.search-box > div:before{
  content: none;
}
.filter {
  border: 1px solid #e2e2e2;
  margin: 0 10px;
  background: #F8F9F9;
  border-radius: 5px;
  padding: 0 5px;
  height: 35px;

}
.filter >div {
  margin-right: 15px;
}
.filter >div label + div:before {
  display: none;
}

.filter >div label + div{
  margin-top:0
}
.filter >div label + div:after{
  content: none;
}
.filter >div label {
  top:-15px;
  font-family: Roboto;
}
.pagination-div {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}
.pagination-showpage {
  float: left;
}
.pagination-pagenumber {
  float: right;
}
.delete-all-alerts {
  width: 150px !important;
  cursor: pointer;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .data-title{
    width: -webkit-max-content;
    width: max-content;
  }
  .data-title .export-wrap button {
    min-width: auto;
    padding: 6px;
    margin-right: 10px !important;
  }
  .data-title .export-wrap button img {
    margin-right: 2px!important;
  }
}

.drop_custom{
    position: relative;
}
.drop_custom_label{
    display: -ms-flexbox;
    display: flex;
    padding: 10px 24px;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    grid-gap: 8px;
    gap: 8px;
    margin-bottom: 0;
    cursor: pointer;
}

.drop_custom ul{
    position: absolute;
    display: none;
    z-index: 222222;
    width: 187px;
    padding: 0px 24px;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: start;
        align-items: flex-start;
    border-radius: 5px;
    background: #ffffff;
    box-shadow: 0px 2px 30px 0px rgba(171, 171, 171, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    list-style: none;
}
.drop_custom .drop_custom_list{
    display: -ms-flexbox;
    display: flex;
}

.drop_custom li{
    height: 48px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    grid-gap: 8px;
    gap: 8px;
    width: 100%;
}
.drop_custom li:not(:last-child){
    border-bottom: 1px solid #DDDFE3;
}

.drop_custom li * {
    font-weight: 500 ;
    color: #04346C;
    width: 100%;
    cursor: pointer;
}
.drop_custom li a{
     color:#29ABE2;
     font-weight: 500;
     line-height: 20px;
     letter-spacing: 0.1px;
 }
.header-bar{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: justify;
        justify-content: space-between;
    padding: 14px 50px;
    background-color: #ffffff;
    box-shadow: 0px 2px 30px 0px rgba(171, 171, 171, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}
.header-bar-burger{
    width: 24px;
    height: 24px;
    padding: 3px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: justify;
        justify-content: space-between;
    position: relative;
    cursor: pointer;
}
.header-bar-burger span {
    display: block;
    height: 3px;
    width: 100%;
    background-color: #000;
    border-radius: 10%;
    cursor: pointer;
    transition: transform 0.2s linear;
}
.header-bar-section{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    grid-gap: 40px;
    gap: 40px;
}
.header-bar-logo{
    display: block;
    height: 75px;
}
.header-bar-logo img{
    height: 100%;
}

.header-bar-search{
    display: -ms-flexbox;
    display: flex;
    padding: 4px 4px 4px 16px;
    -ms-flex-align: center;
        align-items: center;
    grid-gap: 4px;
    gap: 4px;
    -ms-flex: 1 0;
        flex: 1 0;
    border-radius: 5px;
    border: 2px solid #04346C;
    background: #ffffff;
    height: 52px;
    width: 412px;

}
.header-bar-search input{
    border: none !important;
    height: 100%;
    outline: none;
    -ms-flex-positive: 1;
        flex-grow: 1;
}

.header-bar-search button{
        border: none;
        background-color: transparent;
        padding: 6px;
        cursor: pointer;
}


.main-btn{
    display: -ms-flexbox;
    display: flex;
    padding: 10px 24px;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    grid-gap: 8px;
    gap: 8px;
    height: 48px;
    border-radius: 14px;
    background: #04346C;
    color: #ffffff !important;
}
.main-btn:hover{
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
}

.header-bar-burger__active{

}
.header-bar-burger__active span{
    position: absolute;
    top: calc(50% - 2px);
    left: calc(50% - 12px);
}
.header-bar-burger__active span:nth-child(2){
    display: none;
}
.header-bar-burger__active span:first-child{
    -ms-transform: rotate(45deg);
        transform: rotate(45deg);
}

.header-bar-burger__active span:last-child{
    -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
}
.map-switch_mob{
    padding: 0 16px 16px 16px;
    display: none;
}
.map-switch_mob .view-switch{
        position: static;
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
}
.map-switch_mob .view-switch button{
    -ms-flex-positive: 1;
        flex-grow: 1;
    width: 50%;
}
.header-bar-search_mob{
    display: none;
}

.header-bar-search_clear{
    position: relative;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    cursor: pointer;
}
.header-bar-search_clear span{
    display: block;
    height: 3px;
    width: 20px;
    background-color: #9A9EA7;
    border-radius: 10%;
    position: absolute;
    left: 4px;
    top: 12px;
}

.header-bar-search_clear span:first-child{
    -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
}
.header-bar-search_clear span:last-child{
    -ms-transform: rotate(45deg);
        transform: rotate(45deg);
}


@media (max-width: 1200px) {
    .header-bar-section .drop_custom{
            display: none;
    }
    .header-bar-section .main-btn span{
        display: none;
    }
    .header-bar-section .main-btn{
        width: 48px;
        padding: 0;
    }
   .header-bar-section .header-bar-search{
        display: none;
    }
    .header-bar-logo {
        height: 48px;
    }
    .header-bar-section{
        grid-gap: 16px;
        gap: 16px;
    }
    .header-bar-search_mob{
        display: -ms-flexbox;
        display: flex;
        margin:  16px;
        max-width: unset;
        -ms-flex-positive: 1;
            flex-grow: 1;
        width: unset;
    }
    .map-switch_mob{
        display: block;
    }
}

@media (max-width: 576px) {
    .header-bar{
        padding: 12px 16px;
        height: 75px;
    }
    .template-withMap .sidePanel-container{
        width: 100%;
    }
}
