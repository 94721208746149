.header-bar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 50px;
    background-color: #ffffff;
    box-shadow: 0px 2px 30px 0px rgba(171, 171, 171, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}
.header-bar-burger{
    width: 24px;
    height: 24px;
    padding: 3px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
}
.header-bar-burger span {
    display: block;
    height: 3px;
    width: 100%;
    background-color: #000;
    border-radius: 10%;
    cursor: pointer;
    transition: transform 0.2s linear;
}
.header-bar-section{
    display: flex;
    align-items: center;
    gap: 40px;
}
.header-bar-logo{
    display: block;
    height: 75px;
}
.header-bar-logo img{
    height: 100%;
}

.header-bar-search{
    display: flex;
    padding: 4px 4px 4px 16px;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 5px;
    border: 2px solid #04346C;
    background: #ffffff;
    height: 52px;
    width: 412px;

}
.header-bar-search input{
    border: none !important;
    height: 100%;
    outline: none;
    flex-grow: 1;
}

.header-bar-search button{
        border: none;
        background-color: transparent;
        padding: 6px;
        cursor: pointer;
}


.main-btn{
    display: flex;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    height: 48px;
    border-radius: 14px;
    background: #04346C;
    color: #ffffff !important;
}
.main-btn:hover{
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
}

.header-bar-burger__active{

}
.header-bar-burger__active span{
    position: absolute;
    top: calc(50% - 2px);
    left: calc(50% - 12px);
}
.header-bar-burger__active span:nth-child(2){
    display: none;
}
.header-bar-burger__active span:first-child{
    transform: rotate(45deg);
}

.header-bar-burger__active span:last-child{
    transform: rotate(-45deg);
}
.map-switch_mob{
    padding: 0 16px 16px 16px;
    display: none;
}
.map-switch_mob .view-switch{
        position: static;
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
}
.map-switch_mob .view-switch button{
    flex-grow: 1;
    width: 50%;
}
.header-bar-search_mob{
    display: none;
}

.header-bar-search_clear{
    position: relative;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    cursor: pointer;
}
.header-bar-search_clear span{
    display: block;
    height: 3px;
    width: 20px;
    background-color: #9A9EA7;
    border-radius: 10%;
    position: absolute;
    left: 4px;
    top: 12px;
}

.header-bar-search_clear span:first-child{
    transform: rotate(-45deg);
}
.header-bar-search_clear span:last-child{
    transform: rotate(45deg);
}


@media (max-width: 1200px) {
    .header-bar-section .drop_custom{
            display: none;
    }
    .header-bar-section .main-btn span{
        display: none;
    }
    .header-bar-section .main-btn{
        width: 48px;
        padding: 0;
    }
   .header-bar-section .header-bar-search{
        display: none;
    }
    .header-bar-logo {
        height: 48px;
    }
    .header-bar-section{
        gap: 16px;
    }
    .header-bar-search_mob{
        display: flex;
        margin:  16px;
        max-width: unset;
        flex-grow: 1;
        width: unset;
    }
    .map-switch_mob{
        display: block;
    }
}

@media (max-width: 576px) {
    .header-bar{
        padding: 12px 16px;
        height: 75px;
    }
    .template-withMap .sidePanel-container{
        width: 100%;
    }
}