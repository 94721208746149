.main-wrap-about-us{
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.about-us-title-div {
  color: #fff;
  font-weight: 800;
  font-size: 40px;
  width: 95%;
}

.divider-div {
  height: 2px;
  background: rgba(0, 0, 0, 0.12);
  margin-bottom: 10px;
}

.custom-container {
  width: 95%;
  margin: 0 auto;
  background: #fff;
  border-radius: 8px;
  padding: 24px;
  overflow: auto;
  height: calc(100% - 80px);
}
.close-btn{
  position: absolute;
  top: 48px;
  left: 23%;
  font-size: 24px;
  color: #fff;
}

.text-indent{
  /*text-indent:80px;*/
}

.iframe-container{
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
@media (max-width: 768px) {
  .close-btn{
    left: 4%;
    top: 30px;
  }
  .custom-container {
    width: 95%;
  }
}
