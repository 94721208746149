.menu-container {
  z-index: 10;
  margin: 5px;
  position: fixed;
  right: 1%;
  display: flex;
  align-items: center;
}

.notification-wrap-div {
  overflow: auto;
}

.menu-popover {
  position: absolute;
  z-index: 1111;
  top: 103px;
  background: #FFFFFF;
  height: 100%;
}


/*.MuiPopover-paper-62{*/
/*  left: 0 !important;*/
/*  top: 0 !important;*/
/*  width: 420px !important;*/
/*  min-height: 100vh !important;*/
/*  background: #fff;*/
/*}*/
.underline-div {
  margin: 0px !important;
  padding: 10px 50px !important;
}
.bottom-div {
  position: fixed;
  bottom: -30px;
  width: 100%;
}
.border-last-div {
  border-bottom: 0 !important;
}
.div-wrap {

}
.copy-right-div{
  height: 80px;
  align-items: center;
  justify-content: center;
  display: flex;
  text-align: center;
  position: absolute;
  width: 100%;
  color: #A6ABB6;
  position: absolute;
  background: #FFFFFF;
  box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.12);
}
.MuiList-padding-397{
  padding: 0;
}
.MuiMenu-paper-52,
.MuiMenu-paper-392{
  width: 418px;
  top: 0px !important;
  left: 1px !important;
}
.input-div {
  background-color: white;
  border-radius: 5px;
  width: 300px;
  padding: 10px;
  position: absolute;
  z-index: 999;
  right: 0;
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 139ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  opacity: 1;
  box-shadow: 1px 5px 15px #888888;
}

.input-non-focused {
  opacity: 0;
  transition: opacity 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 139ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.category-collapse-wrapper{
  padding:0 2px;
  margin: 5px 0;
}

.search-apply-filter{
  width: 100%;
  text-align: center;
  font-weight: 600;
}

.business-profile-header-img img{
  width: 50% !important;
  height: auto;
}

.toogleMenu { position: absolute !important; left: 0; margin-top: -4px ​!important;  }

.business-wrap .business-profile-header-img{
  text-align: center;
}

.business-wrap .wrap-notification{
  align-items: center;
  margin: 0px 20px;
  border-bottom: 1px solid #CACCD2;
  padding: 8px 30px;
  font-size: 20px;
  color: #A6ABB6;
}
.business-wrap .noauth{
  align-items: center;
  margin: 0px 20px;
  padding: 8px 30px;
  font-size: 20px;
  color: #A6ABB6;
}
.business-wrap .wrap-notification:hover, .business-wrap .noauth:hover ,.business-wrap .wrap-notification.active-menu,.business-wrap .noauth.active-menu  {
  margin: 0px 0px;
  padding: 8px 50px;
  background: #29ABE2;
  color: #FFFFFF;
}
.business-wrap .wrap-notification svg{
  width: 20px;

}
.business-wrap .wrap-notification:hover li, .business-wrap .noauth:hover li ,.wrap-notification:hover li .youtube-channel ,.business-wrap .wrap-notification.active-menu li, .business-wrap .noauth.active-menu li{
  color: #ffffff;
  background: transparent;
}
.business-wrap .wrap-notification li:hover,.business-wrap .wrap-notification:hover li:focus{
  background: transparent;
}

.business-wrap .wrap-font ,.business-wrap .wrap-font:focus {
  font-weight: bold;
  color:#545860;
  font-style: normal;
  font-size: 16px;
  width: 100%;
  background: transparent;
}
.business-wrap .wrap-font a{
  width: 100%;
}
.menu-view.div-wrap div:focus{
  outline: none;
}
.menu-view{
  z-index: 33;
}
.menuItem-div {
  text-align: end;
  position: absolute;
  right: 0;
}
.menuItem-div:focus{
  outline: none;
}

.menu-wrap{
  padding: 0px 0px 35px 0px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);
  background-color: #FFFFFF;
}

.notification-popover {
  width: auto;
  position: relative;
  border-top: 2px solid #165593;
}

.notification-popover:before{
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-right: 12px solid #165593;
  right: 50px;
  top: -20px;
  transform: rotate(90deg);
}
.notification-popover{
  padding: 10px;
  width: 390px;
  max-width: 390px;
  background: #F8F9F9;
  border-radius: 5px 5px 0px 0px;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.12);
  margin-bottom: 2px;
}
body .quill .ql-snow .ql-tooltip{
  left: 0!important;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}
body .quill .ql-snow .ql-tooltip.ql-hidden{
  display: none;
}
.notification-popover h3{
  font-size: 14px;
  font-weight: 600;
  margin-left: 15px;
}
.notification-footer {
  background: #FFFFFF;
  padding: 10px;
  width: auto;
  background: #FFFFFF;
  box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.12), 0px -1px 2px rgba(0, 0, 0, 0.12);
  border-radius: 5px 5px 0px 0px;
  transform: matrix(1, 0, 0, -1, 0, 0);
}

.manage-alert-div-wrap >span {
  color: #165593;
  font-size: 12px;
}
.manage-alert-div-wrap span.new-alert-label {
  color:#A1A1A1 ;
  font-size: 12px;
}
.manage-alert-div-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  transform: matrix(1, 0, 0, -1, 0, 0);
}

.manage-alert-div-wrap button ,.manage-alert-div-wrap button:hover{
  color: white;
  background: #F29223 !important;
  font-size: 12px;
  font-weight: bold;
}
.notification-item {
  padding: 5px;
  margin: 10px 0;
  font-size: 12px;
  font-family: Roboto;
  border-bottom: black;
  flex: 6;
}
.noti-div-wrap svg, .noti-div-wrap-last svg{
  flex: 1;
  font-size: 14px;
  font-weight: 900;
  color: #29ABE2;
}
.menu-img-tag {
  height: 22px;
  width: 25px;
}
.icon-button-div {
  padding: 5px !important;
  /*position: absolute !important;
  right: 0;*/
}
.icon-button-div:hover {
  background-color: transparent !important;
}
.menu-last-div {
  background: #F8F9F9;
  margin-top: 3px;
}
.noti-div-wrap:hover {
background-color: #CACCD2;
cursor: pointer;
}
.noti-div-wrap {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-bottom: 1px solid #D7D8DD;
}
.noti-div-wrap-last {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.menu-view.div-wrap{
  width: 100%;
  min-height: calc(100vh - 103px);
  height: 100%;
  overflow: auto;
}

.menu-view.div-wrap> div{
  width: 420px;
}
.said-icon button p ,.menuItem-div button p{
  font-size: 20px;
  margin: 0 2px;
  color: #9A9EA7;
}

.said-icon-wh button p{ 
  font-size: 20px;
  margin: 0 2px;
  color: white;
}

.menuItem-div button p.cross ,.said-icon button p.cross, .said-icon-wh button p.cross {
  background: #165593;
  width: 40px;
  height: 40px;
  color: #fff;
  padding: 5px;
  border-radius: 8px;
  margin-right:5px;
}
.youtube-menu-icon{
  margin: 0;
}
.youtube-menu-icon svg{
  width: 25px;
  height: 25px;
}
.youtube-channel{
  color: #545860;
}


@media screen and (min-width: 320px) and (max-width: 768px){
  .menu-popover ,.menu-view.div-wrap> div {
    width: 100%;
  }
  .MuiPopover-paper-62{
    max-width: 100%;
    width: 100% !important;
  }
  .business-wrap .wrap-notification{
    margin: 0px 15px;
  }
  .notification-popover{
    width: 100%;
  }
  .notification-popover:before{
    left: 75%;
  }
  .menu-view.div-wrap{
    width: 100%;
  }
  .menu-view.div-wrap.menu-hide-div{
    display: none;
  }
  .mobile-pop-up div + div {
    right: 10px;
    left: auto !important;
    width: 280px;
  }
  .notification-wrap-div{
    max-width: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 330px;
  }
  .business-profile-header-img img{
    width: auto !important;
    height: auto;
  }

  .toogleMenu { position: absolute !important; left:10px; right: auto;} 
}

@media (max-width: 1200px) {
  .menu-popover {
    top: 75px;
    height: 100%;
    position: fixed !important;
  }
  .header-bar_fixed{
    position: fixed;
    width: 100%;
    z-index: 999999;
    top: 0;

  }

}