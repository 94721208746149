
.business-profile-admin-container .close-icon {
  position: absolute;
  right: -48px;
  background: #A6ABB6;
  border-radius: 0 5px 5px 0;
  top: 8px;
}
.business-profile-admin-container .close-icon button ,.business-profile-admin-container .close-icon button:hover{
  color: #FFFFFF;
  background: transparent;
}

.admin-login-details{
  width: 100%
}
.business-profile-admin-form-container .schedule-pc .div-wrapper{
padding: 2% 0%;
}
.business-profile-admin-form-container {
  width: 80%;
  margin: auto;
  background-color: white;
  border-radius: 5px;
  position: relative;
  /* left: 50%; */
  /* transform: translateX(-50%) !important; */
  }

.admin-form {
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}

.business-profile-admin-program-form-group {
  width:100%;
  margin-bottom: 3%;
}
.admin-info-icon {
  height: 16px;
  width: 18px;
  margin-left: 5px;
  cursor: pointer;
}

.admin-button-container{
  width: 40%;
}

.admin-composite-field{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
}

.admin-uploaded-image{
  margin-bottom: 2%;
  width: auto;
  height: 150px;
}

.admin-thumbnail-container{
  height: 100%;
  width: 200px;
  border: 1px solid grey;
}

.admin-thumbnail-image{
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.admin-remove-image{
  position: relative;
  left: 150px;
}

.img-overlay{
  position: relative;
  bottom: 0px;
}

.custom-upload{
  margin-bottom: 15px;
}

.w-100{
  width: 100%;
}

.admin-image-overlay {
  position: relative;
  bottom: 148px;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.65);
  width: 100%;
  height: 100%;
  display: none;
}
.admin-subcategory-container {
  display: flex;
  flex-direction: column;
}

.admin-button-label{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content:space-between;
}

.admin-timing-container{
  display:flex;
  align-items: center;
}

.admin-program-timing{
  display:flex;
  flex-direction: column;
  width: 50%;
}

.admin-thumbnail-container:hover .admin-image-overlay{
  display: inherit;
}

.admin-program-split-hours{
  margin-left: 20%
}

.admin-error-message {
  margin-top: 3%;
  color: #c70b1b;
}

.business-profile-admin-program-button-group {
  display: flex;
  justify-content: flex-start;
}

.admin-form h1{
  font-weight: 600;
  width: 45%;
}

.select-input{
  text-align: center;
  margin-left: 10px;
  width: 135px;
}

.user-level-div{
  align-items: center;
}
.user-level-div .input-box > div{
  width: 100%;
  margin: 0;
  min-height: 45px !important;
}

.user-level-label{
  font-size: 17px;
  color: #000;
}

.business-profile-admin-container{
  margin-top: 0px;
  height: 100vh;
}

.name-group {
  display: inline-flex;
}

.profile-title {
  display: inline-flex;
  width: 100%;
}

.ml-2{
  margin-left: 10px;
}

.admin-side-bar{
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 1;
}

.schedule-wrap{
  /*padding: 24px 0px;*/
  padding: 0px 24px 24px 24px;
}

.business-profile-header{
  font-size: 25px;
}

.business-profile-admin-program-form-group{
  padding: 0px 24px;
  margin-bottom: 3%;
  width: 100%;
}

.composite-language-field{
  padding: 10px 20px;
  margin-top: 0px;
}

.program-form-group textarea{
  width: 100%;
  height: 80px;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
.modal-main-div.business-modal{
  padding-bottom: 200px !important;
}

  .business-profile-admin-container {
    height: auto;
    width: 100%;
    padding-bottom: 10%;
  }
  .business-profile-admin-form-container {
    transform: none;
    width: 90% !important;
    height:auto;
    margin: 0 auto;
  }
  .business-profile-admin-container .close-icon{
    right: 0;
    background: transparent;
    top: 2%;
  }
  .business-profile-admin-container .close-icon button{
    color: #545860;
  }
  .admin-form {
    border: none;
  }
  .business-profile-admin-form-container .schedule-pc{
    display: block;
  }
  .business-profile-admin-program-form-group{
    margin-bottom: 3%;
  }
  .business-profile-header{
    font-size: 25px;
  }
  .admin-timing-container{
    flex-direction: column;
    align-items: flex-start;
  }
  .admin-program-timing{
    width: 100%;
  }
  .admin-program-split-hours{
    margin:0;
  }
  .admin-button-container{
    width: 60%;
  }
  .admin-uploaded-image {
    width: 100%;
  }
  .business-profile-admin-program-button-group {
    flex-direction: column;
    width: 100%;
  }
  .admin-form h1{
    font-size: 20px;
    width: 80%;
  }
}
.schedule-phone .admin-page {
  margin: 0 10px;
  margin-top: 10px;
}

.country-flag {
  width: 29px;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  /* .country-flag{
    .country-flag?
  } */
}
@media screen and (min-width: 1024px) {
  .business-modal {
    margin-left: 420px !important;
    width: calc(100% - 420px) !important;
  }
}