.main-review-container.ask-Question{
  height: auto;
  background: #ffffff;
  max-height: 700px;
  max-width: 630px;
  min-height: max-content;
  border-radius: 5px;
  position: relative;
  top: 50%;
  left: 47%;
  transform: translate(-50%, -50%);
  overflow-y: inherit;
}

.ask-Question .close-icon,.main-review-container .close-icon  {
  position: absolute;
  right: -40px;
  background: #A6ABB6;
  border-radius: 0 5px 5px 0;
  top: 8px;
  height: 40px;
  width: 40px;
}
.ask-Question .close-icon button ,.main-review-container .close-icon button {
  color: #FFFFFF;
  background: transparent;
  padding: 8px;
}
.ask-Question .titlebar ,.main-review-container .titlebar{
  background: #fff;
  min-height: 108px;
  display: flex;
  position: relative;
  justify-content: left;
  padding: 0 10px;
  align-items: center;
  box-shadow: 0 16px 15px -8px #eaeaea;
  border-radius: 5px;
}
.ask-Question .titlebar h2 ,.main-review-container .titlebar h2{
  font-weight: bold;
  color: #545860;
  margin: 0;
}
.main-question-title-header {
  width: 100%;
  display: flex;
  padding-right: 40px;
  align-items: center;
  justify-content: space-between;
}

.question-button {
  float: right;
}
.question-button button ,.question-button button:hover{
  background: transparent;
  border: 1px solid #CACACA;
  border-radius: 5px;
  padding: 11px;
  font-size: 13px;
  color: #474747;
  font-weight: bold;
  width: 100%;
}

.questions-total-list {
  margin: 20px 0 0;
  background: #FFFFFF;
  border: 1px solid #F3F3F3;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}

.single-question {
  padding: 18px 20px;
  margin-bottom: 16px;
  background: #ffffff;
}

.list-question-text {
  margin-bottom: 0px;
  font-weight: bold;
  font-size: 14px;
  color: #545860;
}

.divider {
  width: auto;
  height: 1px;
  background-color: #CACCD2;
}
@media screen and (max-width: 920px) and (min-width: 771px) {
  .question-main-container {
    width: 280px;
  }
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .ask-Question .titlebar {
    height: 78px;
    padding-right: 40px;
  }
  .main-review-container .titlebar{
    height: auto;
  }
  .main-question-title-header{
    display: block;
  }
  .ask-Question .close-icon ,.question-main-container .close-icon ,.main-review-container .close-icon{
  right: 0;
  top: 16px;
  background: transparent;
  z-index: 1;
  }
  .ask-Question .close-icon, .main-review-container .close-icon{
    top: 3%;
  }
  .ask-Question .close-icon button ,.ask-Question .close-icon button ,.main-review-container .close-icon button{
    color: #545860;
  }
  .question-main-container .close-icon button, .question-main-container .close-icon button:hover ,.main-review-container .close-icon button :hover{
    color: #545860;
  }
  .question-main-container{
    width: 100%;
  }
  .ask-Question .titlebar h2 {
    width: 100%;
    padding-top: 10px;
    font-size: 20px;
    padding-bottom: 10px;
  }
  .ask-Question .titlebar h2, .main-review-container .titlebar h2{
    width: 95%;
  }
  body .main-review-container .titlebar{
    padding-left: 10px;
  }
}