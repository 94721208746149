.drop_custom{
    position: relative;
}
.drop_custom_label{
    display: flex;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-bottom: 0;
    cursor: pointer;
}

.drop_custom ul{
    position: absolute;
    display: none;
    z-index: 222222;
    width: 187px;
    padding: 0px 24px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 5px;
    background: #ffffff;
    box-shadow: 0px 2px 30px 0px rgba(171, 171, 171, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    list-style: none;
}
.drop_custom .drop_custom_list{
    display: flex;
}

.drop_custom li{
    height: 48px;
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
}
.drop_custom li:not(:last-child){
    border-bottom: 1px solid #DDDFE3;
}

.drop_custom li * {
    font-weight: 500 ;
    color: #04346C;
    width: 100%;
    cursor: pointer;
}
.drop_custom li a{
     color:#29ABE2;
     font-weight: 500;
     line-height: 20px;
     letter-spacing: 0.1px;
 }