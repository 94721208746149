.main-container{
  /*padding: 2%;*/
}

.set-password-container {
  width: 50%;
  margin: auto;
  border: 1px solid grey;
  padding: 2%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 2%;
  background: #FFFFFF;
  border-radius: 5px 5px 5px 5px;
}

.set-password-error {
  width: 100%;
  color: #c70b1b;
}

.spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20%;
}

.overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 2;
  background-color: #245497;
  opacity: 0.95;
  overflow-x: hidden;
}

.overlay h1{
  color: white;
  text-align: center;
}


@media screen and (min-width: 320px) and (max-width: 767px) {
  .set-password-container {
    width: 100%;
    /*margin-top: 30%;*/
    border: none;
  }
  .spinner {
    margin-top: 10%;
  }
}

