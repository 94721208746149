.alert-admin-main-container{
  margin: auto;
  height: auto;
  min-height: 100vh;
  min-width: 100%;
  width: auto;
}

.view-dlt-div {
  display: flex;
  align-items: center;
}
.table-body{
  color: red;
}

.alert-admin-main{
  background-size: contain;
  padding: 1% 2%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}
.alert-admin-main > div{
  max-height: calc(100vh - 64px);
  overflow-y: auto;
  width: 100%;
}

.WithStyles-Paper--root-187 {
  width: 100%;
  padding: 1%;
  margin-top: 0px;
  border-radius: 0;
}

.alert-admin-main table tr th,
.alert-admin-main table tr td{
  display: table-cell;
  padding: 4px 40px 4px 24px;
}

.alert-admin-main table tr td button{
  background: #29ABE2;
  color: #fff;
  margin-right: 5px;
}
.alert-admin-main table tr td svg{
  /*font-size: 9px !important;*/
  /*margin-right: 10px;*/
  /*background: #A6ABB6;*/
  /*color: #fff !important;*/
  /*padding: 7px;*/
  /*width: 4em;*/
  /*height: 4em;*/
  /*border-radius: 5px;*/
}

.page-btn{
  display: inline-flex;
  width: 20px;
  padding: 1%;
  font-size: 14px;
  border-radius: 2px;
  cursor: pointer;
  align-items: center;
  margin: 2px;
  text-align: center;
  justify-content: center;
  border-right:1px solid #DEE1E5 ;
}
.page-btn:hover {
  background-color: #ececec;
}

.n-btn ,.p-btn{
  background: #A6ABB6;
  border-radius: 5px;
  color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 25px;
  margin: 0 5px;
  max-height: 25px;
}
.disabled-p-btn ,.disabled-n-btn {
  background: #A6ABB6;
  border-radius: 5px;
  color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 25px;
  margin: 0 5px;
  max-height: 25px;
}
.data-title{
  padding: 15px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.12);
  margin-bottom: 2px;
}

.data-title .export-wrap{
  display: flex;
  margin-right: 15px;
  align-items: center;
}

.data-title h4{
  font-size: 24px;
  font-weight: bold;
  color: #545860;
}

.data-title .export-wrap button {
  background:transparent;
  color: #000;
  border: 1px solid #CACACA;
  box-sizing: border-box;
  padding: 6px 16px;
  border-radius: 5px;
  font-style: normal;
  font-weight: bold;
  margin-right: 8px;
  font-size: 13px;
  line-height: 24px;
  font-family: Roboto;
  text-transform: uppercase;
}

.show-page-label {
  font-size: 14px;
}

.data-title .export-wrap button:hover{
  -moz-box-shadow:    0px 2px 3px 4px #f9f9f9;
  -webkit-box-shadow: 0px 2px 3px 4px #f9f9f9;
  box-shadow: 0px 2px 3px 4px #f9f9f9;
}

.search-box > div{
  border: 1px solid #e2e2e2;
  margin: 0 10px;
  background: #F8F9F9;
  border-radius: 5px;
  padding: 0 5px;
  font-family: Roboto;
  font-weight: normal;
  color: #545860;
}
.search-box > div:before{
  content: none;
}
.filter {
  border: 1px solid #e2e2e2;
  margin: 0 10px;
  background: #F8F9F9;
  border-radius: 5px;
  padding: 0 5px;
  height: 35px;

}
.filter >div {
  margin-right: 15px;
}
.filter >div label + div:before {
  display: none;
}

.filter >div label + div{
  margin-top:0
}
.filter >div label + div:after{
  content: none;
}
.filter >div label {
  top:-15px;
  font-family: Roboto;
}
.pagination-div {
  display: flex;
  justify-content: space-between;
}
.pagination-showpage {
  float: left;
}
.pagination-pagenumber {
  float: right;
}
.delete-all-alerts {
  width: 150px !important;
  cursor: pointer;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .data-title{
    width: max-content;
  }
  .data-title .export-wrap button {
    min-width: auto;
    padding: 6px;
    margin-right: 10px !important;
  }
  .data-title .export-wrap button img {
    margin-right: 2px!important;
  }
}
