.sidePanel-header {
  display: flex;
  flex-direction: row;
  height: 42px;
  width: 45px;
  color: #ffffff;
  background: #29ABE2;
  padding: 0px 5px !important;
  border-radius: 5px;
  align-items: center;
}

.hover-title:hover {
  text-decoration: underline;
  color: #29abe2;
  cursor: pointer;
}

.sidePanel-header .tool-tip {
  visibility: visible;
  width: 185px;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  align-items: right;
  bottom: -55px;
  position: absolute;
  z-index: 3;
  right: calc(25% - 92.5px);
}

.sidePanel-header:hover {
  cursor: pointer;
}

.sidePanel-header:hover .tool-tip {
  visibility: hidden;
}

.sidePanel-header-display {
  flex-direction: row;
  justify-content: space-around;
}

.header-category {
  display: flex;
  color: white;
  font-size: 18px;
  font-weight: 600;
}

.btn-select {
  background-color: #245497;
  color: white;
  border: 2px solid white;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  padding: 5px 10px;
}

.btn-select:hover {
  background-color: #234b8d;
}

.mobile-sidePanel-header {
  /*height: 205px;*/
  width: 100%;
}

.mobile-category-container {
  padding: 2% 0;
  /*padding: 20px 0;*/
  /*padding-left: 25px;*/
  background-color: #245497;
  display: flex;
  align-items: center;
  min-height: 66.67px;
  position: sticky;
  top: 0;
  z-index: 4;
  width: 100%;
}

.map-text {
  position: relative;
  z-index: 1;
}

.header-wrap {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  z-index: 1;
}

.header-wrap img {
  width: 100px;
  margin: 5px 10px;
}

.img-wrap {
  background-color: #0000004d;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
}

.title-text {
  font-size: 17px;
  font-weight: 700;
}
.header-detail{
  text-align: center;
  height: 66px;
}
.header-detail .header-img{ display: flex; padding-left: 50px; }

.header-detail .header-img img {
  margin-top: 15px;
  max-width: 70px;
  height: 40px! important;
}

.header-detail h3{
  color: #545860;
  font-weight: 800;
  font-size: 14px;
  margin-bottom: 0;
  line-height: 20px;
  margin-top: 6px;
}
.header-detail h4{
  font-size: 14px;
  line-height: 25px;
  color: #165593;
  font-weight: 800;
  margin-top: 26px;
  max-width: 140px;
  text-align: left;
  line-height: 20px;
  padding-top: 10px;
  padding-left: 10px;

}

.header-detail h4:hover{ text-decoration: none; }

.search-wrap{
  display: flex;
  background-color: #fff;
  height: 50px;
  align-items: center;
  padding: 0px;
  padding-left: 5px;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
}

.header-category {
  color: #fff;
  font-size: 14px;
  display: block;
}

.MuiInput-root-65{
  width: 100%;
}

.search-wrap-div {
padding: 15px;
  background: #F29223;
  box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.15), 0px 5px 2px rgba(0, 0, 0, 0.12);
}

.input-wrap{
  background: #fff;
  margin: 0;
  height: 40px;
  width: 90%;

}
.input-wrap >div {
  display: block;
}

.input-wrap input{
  padding: 10px 15px;
  width: 100%;
}

.search-btn{
  position: relative;
  height: 40px;
  background: #FFF;
  width: 40px;
  display: flex;
  left: 0;
  justify-content: center;
  align-items: center;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.search-btn svg{
  color:#545860 !important;
}

@media (min-width: 692px) {
  .mobile-category-container {
    display: none;
  }

  .mobile-menu-container {
    display: none !important;
  }
}

.mobile-category-container svg {
  opacity: 0;
}

.mobile-btn-select {
  width: 50%;
  color: #000;
  background-color: #e5e5e5;
  /*border: 1px solid #9e9e9e;*/
  /*border-radius: 20px;*/
  border: 1px solid #e5e5e5;
  cursor: pointer;
  font-weight: 800;
  padding: 10px 0px;
}

.filter-button {
  width: 50%;
  cursor: pointer;
  border: none;
  color: #000;
  background-color: #fff;
  padding: 10px 0px;
  font-weight: 800;
  /*background-color: #fff;*/
  /*color: #1890ff;*/
}

.sidePanel-header-map {
  /*height: 150px;*/
  width: 100%;
}

.btn-group {
  height: auto;
  /*padding: 3%;*/
  background-color: white;
  /*display: flex;*/
  align-items: center;
  justify-content: space-around;
}

.filter-button:focus {
  outline: 0;
}

.label-class {
  color: black;
  font-weight: bold;
}

.disable-button {
  background-color: #bbb6b6;
  border: 1px solid #9e9e9e;
  color: #999;
}

.header-img-wrap {
  position: absolute;
  height: 150px;
  width: 100%;
  background-color: #0000004d;
}

.searched-results{
  direction: ltr !important;
}

.resourceContainer{
  background: #FFFFFF;
  border: 1px solid #F3F3F3;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  margin:6px 10px;
}


@media screen and (min-width: 320px) and (max-width: 768px) {
  .map-text{ 
    max-height: 130px;
  }
  .header-detail .header-img img{
    margin-top: 13px;
  }
  .header-detail h4 {
    font-size: 20px;
    margin-top: 6px;
  }
  .header-wrap img{
    width:75px;
  }

  .sidePanel-header .tool-tip {
    visibility: visible;
    width: 140px;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    align-items: right;
    /* Position the tooltip */
    right: calc(25% - 70px);
    bottom: -55px;
    position: absolute;
    z-index: 3;
  }

  .header-wrap {
    background: #245497;
    height: 60px;
    justify-content: flex-end;
    position: sticky;
    top: 0px;
  }

  .header-mobile-wrap {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    z-index: 1;
  }
  .header-detail-wrap{
    position: relative;
    top: -60px;
    z-index: 1;
  }
  .header-wrap{
    position: unset;
  }
  .search-wrap{
    justify-content: space-between;
  }
}

@media screen and (min-width: 320px) and (max-width: 1024px) {
  .header-wrap div {
    /*position: absolute;
    right: 5px;*/
    z-index: 5;
    /*top: 5px;
    width: 100%;*/
  }
}

.img-check {
  margin-left: 15px;
  margin-top: 11px;
  width: 24px;
  height: 24px;
}
@media (max-width: 375px) {
  .btn_mobile{
    margin-right: -15px !important;
  }
}