.main-contactform-container {
  height: auto;
  background: #ffffff;
  max-height: 100%;
  min-height: max-content;
  border-radius: 5px;
  width: 420px;
  margin: 0 auto;
  /* position: relative;
  left: 50% !important;
  top: 25%;
  transform: translate(-50%, 0%); */
}

.main-contactform-container .close-icon {
  position: absolute;
  right: -40px;
  background: #A6ABB6;
  border-radius: 0 5px 5px 0;
  top: 8px;
}
.main-contactform-container .close-icon button{
  height: 40px;
  width: 40px;
  color: white;
  padding: 8px;
}
.main-contactform-container .close-icon button:hover{
  background: #A6ABB6;
}
.main-contactform-container .close-icon >div{
  color: #fff;
}

.contactform-container {
  overflow-y: auto;
  padding: 1% 6% 6% 6%;
  background-color: #f8f9f9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0 0 5px 5px;
  max-height: calc(100vh - 250px);
}
.main-contactform-container .modal-title {
  background: #fff;
  height: 78px;
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.12);
  border-radius: 5px 5px 0 0;
  padding: 0 6%;
  margin-bottom: 1px;

}
.main-contactform-container .modal-title h2{
  margin: 0;
  font-weight: bold;
  color: #545860;
  font-size: 24px;
}
.field-container {
  max-height: 70vh;
  margin: 2% 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
}
.contactform-container .error{
  border: none;
  box-shadow: none;
  display: flex;
  align-items: center;
  height: auto;
  margin-top: 10px;
}
.error p{
  margin: 0 0 0 5px;
}
.field-container label{
  font-size: 16px;
  color: #545860;
}
.field-container > div ,.field-container > div:focus{
  height: 40px;
  margin-top: 9px;
  border: 1px solid #dadada;
  padding: 0 1%;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}
.field-container > div:hover{
  border: 1px solid #787878;
}
.field-container > div.error:hover{
  border: none;
  box-shadow: none;
}

.contactform-container button ,.contactform-container button:hover{
  width: 80%;
  margin: 10px auto;
  background: #F29223;
  height: 38px;
}

.ql-align-center {
  text-align: center;
  list-style-position: inside;
}

.ql-align-right {
  text-align: right;
  list-style-position: inside;
}

.ql-align-justify {
  text-align: justify;
  list-style-position: inside;
}

.ql-size-huge {
  font-size: 2.5em;
}

.ql-size-large {
  font-size: 1.5em;
}

.ql-size-small {
  font-size: 0.75em;
}

.disclaimer-modal-container::-webkit-scrollbar {
  width: 5px;
  border-right: 1px solid #808080;
}

.disclaimer-modal-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}
.main-modal {
  position: relative;
} 
@media screen and (max-width: 920px) and (min-width: 771px) {
   .main-contactform-container{
    width: 280px;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .main-contactform-container{
    max-width: 100%;
    width: 300px;
  }
  
  .main-contactform-container .close-icon {
    top: 4%;
    right: 0;
    background: transparent;
  }
  .main-contactform-container .close-icon button{
    color: #545860;
  }
  .contactform-container { 
    max-height: calc(100vh - 200px);
  }
}
