.side-panel-content {
  height: 100%;
  background: white;
  /* overflow: hidden; */
}
.side-panel-content .map-text +div{
  flex-wrap: wrap;
  justify-content: flex-start;
}

.search-wrapper {
  flex-direction: row;
  display: flex;
  justify-content: space-around;
  background-color: #F1F1F1;
  padding: 06px;
  margin-top: 4px;
  max-height: 120px;
  overflow: auto;
}
.program-category .program-type-label .category-icon-filter{
  position: absolute;
  right: 15px;
  border-radius: 5px;
  top: 29%;
}
.program-category .program-type-label .filter-title {
  color: #545860;
}
.program-category .program-type-label .category-icon-filter p{
  width: 22px;
  margin: 0 2px;
  color: #fff;
  border-radius: 5px;
  font-size: 10px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.program-type-label.active .resource-icon p.heart,.program-type-label.active .resource-icon p.thlarge ,.program-type-label.active .resource-icon p.home, .program-type-label.active .resource-icon p.pizza{
  border: 1px solid #FFFFFF;
  background: transparent;
  color: #FFFFFF;
}


.search-btn-div {
  font-weight: normal;
  font-style: normal;
  font-size: 12px;
  color: #545860;
}

.filter-record {
  color: #000000;
  padding: 15px;
}
.filter-record .program-category{
  margin:0 ;
}
.filter-record .program-category .composite-field .subcategory0,
.filter-record .program-category .composite-field .subcategory1,
.filter-record .program-category .composite-field .subcategory2,
.filter-record .program-category .composite-field .subcategory3{
  border: none;
  height: 38vh;
  direction: unset;
  overflow-y: auto;
  scroll-behavior: smooth;
  box-shadow: 0 5px 6px -6px black;
}
.filter-record .filter-record-heading {
  background: #FFFFFF;
  border-radius: 5px 5px 0 0;
  padding: 25px 34px;
  border: 1px solid #dedede;
  box-shadow: 0 4px 2px -4px #ababab;
  margin-bottom: 1px;


}

.filter-record .filter-record-heading span {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #545860;
}
.filter-record .program-category .program-type-label {
  background: #F8F9F9;
  padding: 12px 30px;
  width:100%;
  margin:0 !important;
  color:#545860 !important;
  border: 1px solid #CACCD2;
  position: relative;
}
.filter-record .program-category .program-type-0.active{
  background: #29ABE2;
}
.filter-record .program-category .program-type-1.active{
  background: #6FA963;
}

.filter-record .program-category .program-type-2.active{
  background: #DA6A6A;
}

.filter-record .program-category .program-type-3.active{
  background: #165593;
}
.program-category .program-type-label.active .filter-title{
  color: #fff;
}

.program-category .program-type-label span{
  color: #CACCD2;
}
.program-category .program-type-label span.filter-title{
  color: #545860;
  font-weight: bold;
}

.filter-btn-wrap{
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: center;
  margin: 20px auto 50px;
}
.filter-record .filter-btn-wrap{
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 20px 50px;
  border: 1px solid #CACCD2;
  border-top: none;
  background: #F8F9F9;
  border-radius: 0 0 5px 5px;
  margin: 0;

}
.detail-section{
  margin-left: 0px !important;
}

.title-address:hover{
  text-decoration: underline;
}

.apply-button{
  font-weight: 800;
  background: linear-gradient(0deg, #29ABE2, #29ABE2), #165593;
  border-radius: 5px;
  color: #fff;
  border: none;
  margin-bottom: 15px;
  padding: 13px;
}
.apply-button img{
  margin-right: 10px;
}
.clear-button {
  justify-content: center;
  display: flex;
  border: none;
  background: transparent;
  color: #545860;
}

.filter-scrollable {
  width: 100%;
  height: calc(100vh - 255px);
  position: relative;
  direction: unset;
  overflow-y: scroll;
  scroll-behavior: smooth;
  z-index: 1;
}

.filter-scrollable::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #245397;
  background-color: #245397;
}


.filter-scrollable::-webkit-scrollbar
{
  width: 6px;
  border-right: 1px solid #808080;
}

.filter-scrollable::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #245397;
  background-color: #245397;
}

.searched-results{
  background: #F1F1F1;
}

.searched-keyword{
  padding: 5px 7px;
  margin:3px 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* position: relative;
  width: 161.11px;
  height: 35px; */

  position: relative;
  width: auto;
  height: auto;

  /* left: 0.61px;
  top: 0px; */

  background: #FCFCFC;
  border-radius: 5px;
}

.searched-keyword-div{
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 22px;
  color: #000;
  margin-left: 5px;
}
.searched-keyword-div .search-btn-div{
  font-weight: normal;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .side-panel-content {
    height: 100vh;
  }
  .filter-scrollable {
    height: calc(100vh - 200px);
  }
  .search-wrapper{
    margin-top: 10px;
  }
}
