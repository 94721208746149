.main-wrap-about-us{
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}

.about-us-title-div {
  color: #fff;
  font-weight: 800;
  font-size: 40px;
  width: 95%;
}

.divider-div {
  height: 2px;
  background: rgba(0, 0, 0, 0.12);
  margin-bottom: 10px;
}

.custom-container {
  width: 95%;
  margin: 0 auto;
  background: #fff;
  border-radius: 8px;
  padding: 24px;
  overflow: auto;
  height: calc(100% - 80px);
}
.close-btn{
  position: absolute;
  top: 48px;
  left: 23%;
  font-size: 24px;
  color: #fff;
}

.text-indent{
  /*text-indent:80px;*/
}

@media (max-width: 768px) {
  .close-btn{
    left: 4%;
    top: 30px;
  }
  .custom-container {
    width: 95%;
  }
}