.list-resources {
  width: 100%;
  height: calc(100vh - 197px);
  position: relative;
  direction: rtl;
  overflow-y: scroll;
  scroll-behavior: smooth;
  z-index: 999;
}

.img-icon {
  padding: 0px 1px;
}

.today-open{
  color: #6FA963;
  margin-left: 6px;
}

.today-close{
  color: #DA6A6A;
  margin-left: 6px;
}

.now-open-div {
  display: flex;
  align-items: center;
}

.div-top-container {
  border-bottom: 1px solid rgb(202, 204, 210);
  padding-bottom: 7px;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.read-more-icons-div {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.list-resources-timestamp{
  color: #ccc;
  font-size: 14px;
}

.now-open-text{
  margin-bottom: 0;
}

.clock-img{
  height: 12px;
  width: 12px;
}

.searched-results {
  width: 100%;
  position: relative;
  direction: rtl;
  scroll-behavior: smooth;
  z-index: 1;
}

.rating-div-color {
  margin: 0px 5px;
  color: #F29223;
  font-weight: normal;
}

.list-resources-text-input-div{
  margin-top: 7px;
  margin-bottom: 0px;
  min-height:0px;
  height: auto;
  overflow: hidden;
  font-size: 14px;
}

.list-resource-loader {
  direction: ltr;
  text-align: center;
  margin-top: 2%;
}

 .presentation{
   /*position: absolute !important;*/
   z-index: 0 !important;
 }

/*.resourceContainer .arrow {*/
  /*border-top: 15px solid transparent;*/
  /*border-left: 25px solid red;*/
  /*border-bottom: 15px solid transparent;*/
  /*position: absolute;*/
  /*top: 40%;*/
  /*transform: translate(-40%);*/
  /*right: -5px;*/
  /*z-index: 999;*/
/*}*/
.title-address{
  /*text-transform: lowercase;*/
  font-size: 18px;
  font-weight: 900;
  color: #245397;
}
/*.title-address:first-letter{*/
/*  text-transform:uppercase;*/
/*}*/
.resourceContainer {
  padding: 18px 20px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  font-size: 13px;
  color: #545860;
  direction: ltr;
  position: relative;
  border-bottom: 1px solid #808080;
}

.selected-resources-container {
  background-color: #29ABE2;
  color: #FFFFFF;
  position: relative;
}
.selected-resources-container:before{
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-right: 12px solid #29abe2;
  right: -11px;
  top: 40%;
  transform: rotate(180deg);
}
.selected-resources-container .title-address {
  color: #FFFFFF;
  text-decoration: underline;
}
.selected-resources-container .now-open-text ,.selected-resources-container .number-of-rating ,.selected-resources-container .rating-div-color{
  color: #AFE6FE;
}
.videoBlock{  margin-top: 20px; padding:0 10px; } 
.assistanceBtn{
  background: #6FA963;
border-radius: 5px;
width: 100%;
color: #fff;
border: none;
padding: 10px ;
font-size: 14px;
font-weight: 500;
}
.assistanceBtn svg{margin-right: 10px;}
.assistanceBtn span{font-size: 16px; font-weight: 600; padding-left:10px ;}
.p10{padding:0 10px;}
.menuLeft{
  list-style: none;
  padding: 0 10px; margin: 0;
  display: flex;
  flex-direction: row;
  padding-top: 5px; 
  line-height: 22px;
  display: flex;
  justify-content: center;

}
.menuLeft li {
  padding:0px 10px;
}
.menuLeft li a{  color: #474747; font-size: 13px; padding: 0 8px; text-decoration: underline}
.menuLeft li a:hover{  color: #245397; text-decoration: none}
.dropDown{
  color: #474747 !important;     font-size: 11px !important; background: #fff !important; padding: 0 !important;
  text-transform: capitalize !important; box-shadow: none !important;  font-weight: normal !important;
}
.MuiListItem-root-186{ 
  padding-top:5px !important;
  padding-bottom:5px !important; 
}
.WithStyles-MenuItem--root-182:focus .MuiTypography-subheading-211{ color: #fff !important; }
.Component-paper-147{ border: none !important; }
.MuiList-padding-179{ padding-top: 0 !important; padding-bottom: 0 !important; }

.resource-icon{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.resource-icon p{
  width: 22px;
  margin: 0 2px;
  color: #fff;
  border-radius: 5px;
  font-size: 10px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.resource-icon p.pizza{background: #6FA963;}
.resource-icon p.home{background: #DA6A6A;}
.resource-icon p.thlarge{background: #165593;}
.resource-icon p.heart{background: #29ABE2;}
.selected-resources-container .resource-icon p,.selected-resources-container .resource-icon p.heart,.selected-resources-container .resource-icon p.thlarge ,.selected-resources-container .resource-icon p.home, .selected-resources-container .resource-icon p.pizza{
  border: 1px solid #AFE6FE;
  background: transparent;
  color: #AFE6FE;
}
.selectedResourceContainer {
  padding: 10px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  font-size: 13px;
  background-color: lightgray;
  color: #000;
  direction: ltr;
  position: relative;
  border-bottom: 1px solid #808080;
}

.rate1 {
  display: none;
}

.number-of-rating {
  margin-left: 5px;
  color: #A6ABB6;
}

.rating-container {
  display: contents;
  width: 40%;
  padding: 3px;
}

.detail-section {
  margin-left: 2%;
}
.MuiModal-root-19{
  z-index: 3333333 !important;
}
.MuiModal-root-30 .ListResources-popup-100{
  left: 440px !important;
}/*
.presentation :nth-of-type(2n){
  left: 440px !important;
}*/
.presentation .ListResources-popup-89{
  left: 440px!important;
}

@media screen and (min-width: 320px) and (max-width: 425px) {
  /*.presentation :nth-of-type(2n){
    left: 0px !important;
  }*/
  .presentation .ListResources-popup-89{
    left:0px!important;
  }
}

@media screen and (min-width: 1025px) {
 .selected-resources-container .rating-container {
   color: #000;
   padding: 3px;
   width: 40%;
 }

  .selected-resources-container .rate2 {
    display: none;
  }
  
  .selected-resources-container .rate1 {
    display: inherit;
  }

}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .list-resources {
    width: 100%;
    height: calc(100vh - 262px);
    overflow-y: scroll;
    z-index: 0;
  }
  .searched-results {
    width: 100%;
    z-index: 0;
    padding-bottom: 20px;
  }

  .presentation {
    /*position: absolute !important;*/
    z-index: 5 !important;
  }
}
.list-resources::-webkit-scrollbar
{
  width: 6px;
  border-right: 1px solid #808080;
}

.searched-results::-webkit-scrollbar
{
  width: 6px;
  border-right: 1px solid #808080;
}

.list-resources::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #245397;
  background-color: #245397;
}

.searched-results::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #245397;
  background-color: #245397;
}

.list-resources-wrapper-read-more {
  position: relative;
}

.list-resources-read-less {
  height: 40px;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;

}

.list-resources-read-more-content {
  color: #29ABE2;
  max-width: 75px;
}
list-resources-read-more-content:hover {
  text-decoration: underline;
}

.menuLeft .dropDown {
  background-color:transparent !important;
  color: #474747 !important; 
  font-size: 13px !important;    
}


 
.menuLeft .MuiTypography-subheading-211
{
  font-size: 13px !important;
}

.Organizations-link{
  padding-left: 8px !important;
  font-size: 13px !important;
  color: #545860  !important;
  line-height: .4em  !important;
  padding-top: 10px  !important;
  padding-bottom: 10px  !important;
  height: auto  !important;
  width: 70px !important;
}

.Organizations-link a{ 
  color: #545860  !important; 
}




/*v2*/
.resource * {
    color: #000;
}

.resource{
  padding:20px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #DDDFE3;
  cursor: pointer;
}
.resource-title{
  color: #04346C;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.18px;
  margin-bottom: 4px;
}
.resource-subTitle{
  color: #545860;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #DDDFE3;
}

.resource-description{
  line-height: 22px;
  height:62px;
  overflow: hidden;
  white-space: pre-wrap;
  text-overflow: ellipsis;
}



.resource-links{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 10px;
}

.resource-links a,.resource-links > div {
  margin-right: 16px;
  margin-bottom:20px
}
.resource-links img {
    margin-right: 8px;
    max-width: 24px;
}

.resource-list .resource{
  margin-bottom:20px;
}

.resource-modal-item{
  width: 400px;
}

.close-modal-map{
  background-color: #9A9EA7;
  width:40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 5px 5px 0;
  border: none;
  position: absolute;
  right:-52px;
  top: 0;
  z-index: 30000;
  cursor: pointer;
  transition: all 0.2s linear;
}
.close-modal-map:hover{
  background-color: #04346C;
}
.resource-modal{
  position: relative;
}

.close-modal-map span{
    background-color: #fff;
    width:2px;
    height: 16px;
    display: block;
    position: absolute;
}

.close-modal-map span:first-child{
  transform: rotate(-45deg);
}
.close-modal-map span:last-child{
  transform: rotate(45deg);
}

.gm-style-iw-t,.gm-style-iw-d ,.gm-style-iw-c{
  overflow: visible !important;
}

button.gm-ui-hover-effect{
  display:none !important;
}

.gm-style-iw.gm-style-iw-c{
  padding-bottom:12px !important;
  padding-right:12px !important;
}

.resource-modal-list{
  max-height: 400px;
  overflow-y: scroll;
  padding-right: 8px;
}
.resource-modal-list > div{
  margin-bottom: 8px;
}
.presentation{
  z-index: 30002 !important;
}
.resource-details{
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1234;
  max-height: 100vh;
  overflow-y: scroll;
  width: 41vw;
}
.resource-details .content-container{
  width: 100% !important;
}
@media (max-width:1200px) {
  .resource-details{
    width: 100vw;
  }
}

@media (max-width: 576px) {
  .gm-style-iw.gm-style-iw-c{
    max-width: calc(100vw - 40px) !important;
    max-height: unset !important;
  }
  .resource-modal-item{
    width: 100%;
  }
  .resource-modal-list{
    max-height: 260px;
  }
}

@media (max-width: 390px) {
  .gm-style-iw.gm-style-iw-c{
    max-width: calc(100vw - 80px) !important;
  }

  .resource-modal-list{
    max-height: 220px;
  }

}