.question-main-container{
  height: auto;
  background: #ffffff;
  min-height: max-content;
  border-radius: 5px;
  width: 420px;
  position: relative;
  top: 50%;
  left: 50% !important;
  transform: translate(-50%, -50%);
}
.question-main-container .close-icon {
  position: absolute;
  right: -40px;
  background: #A6ABB6;
  border-radius: 0 5px 5px 0;
  top: 8px;
  width: 40px;
  height: 40px;
}
.question-main-container .close-icon button,.question-main-container .close-icon button:hover{
  color: #FFFFFF;
  background: transparent;
  padding: 8px;
}
.question-container .btn-section button{
  background: #F29223;
  width: 100%;
  border-radius: 3px;
  margin: 10px 0;
  color: #fff;
}
.question-container .btn-section button:hover{
  box-shadow: 0 0 11px rgba(33,33,33,.2);
  background: #F29223;
  color: #fff;
}
.question-main-container .modal-title {
  background: #fff;
  height: 78px;
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.12);
  border-radius: 5px 5px 0 0;
  padding: 0 6%;
  margin-bottom: 1px;

}
.question-main-container .modal-title h2{
  margin: 0;
  font-weight: bold;
  color: #545860;
}

.question-container {
  padding: 1% 6% 6% 6%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  overflow-y: scroll;
}

.user-question{
  width: 100%;
  margin-bottom:1%;
  padding-right: 5%;
  border-bottom: 1px solid #000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: justify;
}

.question-label{
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer
}

.answer{
  width: 100%;
  margin: 0% 0 0 5%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: justify;
}

.by-line{
  font-size: 11px;
  color: gray;
}

.by-line.italic-font {
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
  color: #A6ABB6;
  margin-bottom: 8px;
}

.message{
  display: inline-flex;
  justify-content: center;
}

.success{
  color: #008000;
}

.failed {
  color: #bf0708;
}

.question-container::-webkit-scrollbar
{
  width: 5px;
  border-right: 1px solid #808080;
}

.question-container::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #555;
  height: 2px;
}

.user-question-text {
  margin-bottom: 0px;
}
@media screen and (max-width: 920px) and (min-width: 771px) {
  .question-main-container{
    width: 280px;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .message{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .question-main-container .close-icon  {
    top: 4%;
    right: 0;
    background: transparent;
  }
  .question-main-container .close-icon button ,.question-main-container .close-icon button:hover{
    color:#545860;
  }
}