.view-switch{
    position: absolute;
    z-index: 30;
    right: 50px;
    top: 20px;
    border-radius: 10px;
    border: 1px solid #E7E7E8;
    overflow: hidden;
}

.view-switch button{
    height: 48px;
    background-color: white;
    color: #01225A;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 20px;
    text-transform: uppercase;
    border: none;
    width: 107px;
    cursor: pointer;
}

.view-switch .active-switch{
    color: #FFF;
    background-color: #01225A;
}