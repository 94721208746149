.gallery-grid {
  width: 400px;
  height: 100vh;
  background-color:white;
  overflow-y: scroll;
  overflow-x: hidden;
  direction: rtl
}

.gallery-grid-list{
  margin-top: 25px;
  direction: ltr
}

.thumbnail-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.gallery-header {
  margin:auto;
  padding:5px;
  text-align: center;
  height:auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .gallery-grid {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
  }
  .gallery-header{
    margin-top: 10%;
  }
}


.gallery-grid::-webkit-scrollbar
{
  width: 5px;
  border-right: 1px solid #808080;
}

.gallery-grid::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #555;
}


