.main-modal-container{
  width: 100%;
  background-color: rgb(255, 255, 255);
  margin: auto;
  border-radius: 5px;
}

.popup-image {
  height: 100%;
  width: 100%;
  object-fit: contain;
  border: 1px solid #aaa;
}

.popup-image-container{
  height: 100%;
  width: 100%;
  margin: auto;
  padding: 1% 6% 6% 6%;
}