.confirm-email-container {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  border: 1px solid;
  padding: 20px 30px;
  background: #fff;
  border-radius: 5px;
}
body .Content-success-332{
  background-color: #008000;
}
body .Content-error-331{
  background-color: #bf0708;
}
.confirm-email-container img {
  width: 40%;
}
.confirm-email-container h1 {
  font-size: 26px;
  color: #666;
}

.confirm-email-container p {
  font-size: 14px;
}

.resend-email-div {
  background-color: #6ea8dc;
  color: #fff;
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 14px;
  cursor: pointer;
}

.wrapeer{
  width: 40%;
  margin: 0 auto;
  margin-top: 100px;
}

@media (max-width: 425px) and (min-width: 320px){
  .wrapeer{
    width: 100%;
    margin: 0 auto;
  }
  body .swipable-view label{
    width: 100% !important;
  }
  .confirm-email-container{
    margin-top: 80px;
  }
}