.main-modal {
  height: auto;
  background: #ffffff;
}

.review-modal{
  padding: 6%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.review-sub-container{
  border: 1px solid #000;
  border-radius: 5px;
  height: auto;
}

.review-subject{
  text-align: left;
  padding: 1%;
  border-bottom: 1px solid black;
}

.review-text {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1%;
  overflow-wrap: break-word;
  height: auto;
}

.reply-container{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2% 0;
}

.reply-actions {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 920px) and (min-width: 771px) {
  .main-modal {
    width: 280px;
  }
}