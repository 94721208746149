.password-main-container{
  background: #ffffff;
}

.password-modal-container {
  height: auto;
  padding: 1% 6% 6% 6%;
  display: flex;
  flex-direction: column;
}

.modal-field {
  display: flex;
  flex-direction: column;
  margin: 1% 0;
}

.password-error{
  margin-top: 1%;
  color: #bf0708;
}

.password-message{
  display: inline-flex;
  justify-content: center;
}

.password-success{
  color: #008000;
}

.password-failed {
  color: #bf0708;
}