.admin-main-container{
  margin: auto;
  height: auto;
  min-height: 100%;
  min-width: 100%;
  width: auto;
}

.btn-img {
  height: 15px;
  width: 15px;  
}
.admin-main{
  padding: 1% 2% 2% 2%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}
.admin-main >div{
  max-height: calc(100vh - 118px);
  overflow-y: auto;
  width: 100% !important;
}
.logout{
  color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}

.header-sort{
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
}

.needs-approval {
  color: #bf2b1c;
}

.published {
  color: #2f973e;
}

.unpublished {
  color: #cc7a00;
}

.table-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sort-icon-group{
  display: inline-flex;
  margin-left: 3px;
}

.sort-icon{
  color: #aaaaaa;
  cursor: pointer;
}

.sort-icon:hover {
  color: #888888;
}

.active-sort-icon {
  color: #888888;
}

.disclaimer-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ql-align-center{
  text-align: center;
  list-style-position: inside;
}

.ql-align-right{
  text-align: right;
  list-style-position: inside;
}

.ql-align-justify{
  text-align: justify;
  list-style-position: inside;
}

.ql-size-huge {
  font-size: 2.5em;
}

.ql-size-large {
  font-size: 1.5em;
}

.ql-size-small {
  font-size: 0.75em;
}

.admin-side-bar{
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 1;
}

.btn-img-done{
  height: 10px;
  width: 10px;
  margin-right: 5px;
}

.status-wrap {
  display: flex;
  align-items: center;
}

.input-wrap button{
  margin-left: 0px;
  margin-top: inherit;
  background-color: #245497;
  color: white;
  border: 0;
  border-radius: 5px;
  padding: 5px 15px;
}
.btn-section {
  display: flex;
}
.my-account-text{
  color:#FFFFFF;
  font-weight: 800;
  margin-top: 5px;
  font-size: 35px;
}
.login-security-header{
  font-weight: bold;
  font-size: 24px;
  color: #545860;
}
.icon-div{
  font-size: 15px !important;
  margin-right: 5px;
}
.delete-icon{
  font-size: 15px !important;
  color: #fff;
}
.program-entries-header{
  font-weight: bold;
  font-size: 24px;
  color: #545860;
  display: block;
  margin-left: 15px;
  margin-bottom: 0;
}
.data-table table  tr td.program-font-color {
  color: #29ABE2;
}
.data-table table  tr td.number-font-color ,.data-table table  tr th.number-font-color {
  color: #A6ABB6;
}
.data-table table thead tr th ,.data-table table tr td{
  padding: 4px 5px 4px 24px;
  border: none;
  text-align: left;
}
.data-table table tr:nth-child(even) {background: #F8F9F9}
.pagination{
  display: flex;
  align-items: stretch;
  float: right;
}
.data-table table thead tr:first-child{
  border-bottom:1px solid #DBDBDB ;
}
.data-table table tfoot{
  box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.12), 0px -1px 2px rgba(0, 0, 0, 0.12);
}
.data-table table thead tr th {
background-color:#F7F7F7
}
.btn-section .btn-view{
  background: #29ABE2;
  font-size: 10px;
  font-weight: bold;
  text-transform: none;
  min-width: 32px;
}
.delete-icon-div{
  height: 28px;
  width: 28px;
  background: #DA6A6A;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
  color: #FFFFFF;
  margin: 0 5px;
  cursor: pointer;
}
.btn-section .btn-duplocate{
  background: #165593;
  box-shadow: 0px 2px 3px 4px #f9f9f9;
  margin: 0 5px;
  font-size: 10px;
  text-transform: none;
  font-weight: bold;
  min-width: 32px;
}
.btn-section .btn-duplocate svg ,.btn-view svg {
  margin-right: 5px;
}
.label-name {
  font-size: 14px;
  font-weight: 600;
}
.btn-section .btn-duplocate:hover{
  background: #165593;
}
.show-page-label > div {
  border: 1px solid #e2e2e2;
  background: #F8F9F9;
  border-radius: 5px;
  padding: 0 5px;
  margin-left: 13px;
  font-size: 14px;
}
.show-page-label > div:before{
  display: none;
}
.export-wrap img{
  margin-right: 5px;
}
.tableCell-font-style{
  font-size: 14px;
  text-align: justify;
}
.action-div {
  display: flex;
  justify-content: center;
  font-size: 14px;
}
.showing-page-div {
  float: left;
  font-size: 14px;
}
.no-data-div {
  text-align: center;
  padding: 20px;
  font-size: 15px;
}
.btn-section .btn-view:hover{
  box-shadow: 0px 2px 3px 4px #f9f9f9;
  background: #29ABE2;
}
.pagination-div {
  display: flex;
  justify-content: space-between;
}
.pagination-showpage {
  float: left;
}
.pagination-pagenumber {
  float: right;
}
.data-title .export-wrap button.web-btn{
  display: block;
}
.data-title .export-wrap button.m-btn{
  display: none;
}
.header-display {
  display: none;
}

.loader {
  text-align: center;
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  height: 100%;
}
.disabled-page-btn {
  background: #A6ABB6;
  border-radius: 5px;
  color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 25px;
  margin: 0 5px;
  max-height: 25px;
}
@media screen and (min-width: 320px) and (max-width: 425px) {
  .admin-main-container {
    /* width: min-content; */
    width: 100%;
    min-height: 100%;
  }
  .table-header{
    flex-direction: column;
  }
  .export-wrap{
    text-align: center;
  }
  .btn-single-weapper, .btn-weapper.btn-wrap-mobile{
    display: block;
  }
  .program-info-admin-form-container .btn-single-weapper, .btn-weapper.btn-wrap-mobile button{
    width: 100% !important;
    max-width: 100%;
  }
  .edit-section-last{
    margin-bottom: 70px;
  }
}
@media screen and (min-width: 320px) and (max-width: 568px) {
  .data-table table tr td.m-view-hide, .data-table table tr th.m-view-hide{
    display: none;
  }
  .data-table table tr td.m-hide, .data-table table tr th.m-hide{
  display: none;
  }

}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .admin-main-container {
    /* width: min-content; */
    width: 100%;
    min-height: 100vh;
  }
  .showing-page-div {
    font-size: 13px;
  }
  .data-table {
    max-width: 100%;
    overflow: auto;
  }
  .btn-section{
    justify-content: center;
  }
  .data-title {
    flex-direction: column;
    padding: 8px !important;
    width: 100% !important;
  }
  .admin-main >div {
       overflow-X: hidden !important;
  }
  .my-account-text , .newlist-title ,.about-us-title-div{
    margin-top: 30px;
  }
  .program-entries-header {
    margin-left: 0;
    font-size: 18px !important;
  }
  .data-title .export-wrap {
    margin-right: 0 !important;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }
  .data-title .export-wrap .search-box {
    flex: 1;
    min-width: 100px;
    margin-top: 10px;
  }
  .data-title .export-wrap .search-box div {
    margin-right: 0 !important;
  }
  .show-page-label > div {
    margin-left: 2px;
    width: 50px;
  }
  .show-page-label {
    font-size: 11px !important;
  }
  .mobile-view-hide {
    display: none;
  }
  .data-table table tr td.number-font-color, .data-table table tr th.number-font-color{
    display: none;
  }

  .data-table table tr td.mobile-view-hide,.data-table table tr th.mobile-view-hide{
    display: none;
  }
  .btn-section .btn-duplocate svg ,.btn-view svg {
    margin-right: 0px;
  }
}
@media screen and (min-width: 320px) and (max-width: 1200px){
  .data-title .export-wrap button.web-btn{
    display: none;
  }
  .data-title .export-wrap button.m-btn{
    display: block;
    font-size: 11px;
  }
  .header-display {
    display: flex;
  }
  .data-table table tr td.mobile-view-hide,.data-table table tr th.mobile-view-hide{
    display: none;
  }
}


