.slider-container{
  padding-right: 2%;
  padding-left: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 100vh;
  background-color: rgb(30,30,32);
}
.closegallery {
  position: absolute;
  right: 1px;
  top: 1px;
  color: #fff;
  z-index: 999;
  cursor: pointer;
}
.slider-container p.cross {
  background: #165593;
  width: 35px;
  height: 35px;
  color: #fff;
  border-radius: 5px;
  font-size: 20px;
  padding: 2px;
}
.slider-container p {
  margin: 0;
}
.image-container-wrap{
  position: relative;
  width: 100vw;
}
.image-dim {
  height:auto;
  width:auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.image-container-wrap .slider-container button{
  position: absolute;
  top: 10px;
  right: 10px;
}
.nav-icons{
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center
}
.arrow-icon{
  display: none;
  position: absolute;
  top: 25px;
  left: 15px;
}
.nav-icons svg{
  color: #fff !important;
  font-size: 70px !important;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .slider-container{
    padding: 0;
    width: 100vw;
  }
  .image-dim{
    height: auto;
    width: 100vw;
    object-fit: cover;
  }

  .arrow-icon{
    display: inherit;
  }
  .nav-icons {
    bottom: 50px;
  }
}
