.main-div{
  margin: auto;
  height: 100vh;
  min-height: 100vh;
  min-width: 100%;
  width: 100%;
  padding: 1% 2% 1% 2%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.close-listing {
  display: none !important;
  position: absolute;
  left: 15px;
  top: 0px;
  font-size: 32px;
  color: #fff;
  z-index: 999;
  cursor: pointer;
}
.alert-div{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  font-size: 28px;
  align-items: center;
  color: #fff;
}

.sub-alert-div{
  display: flex;
  align-items: center;
  margin: 1%;
  width: 100%;
  justify-content: flex-start;
}

.claim-user{
  display: flex;
  align-items: initial;
  justify-content: space-between;
  cursor: pointer
}

.image-list-item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d5d5d5;
  padding: 12px 2%;
  font-size: 12px;
  color: #191919;
  width: 100%;
  margin: auto;
}

.dragged-item{
  background-color: #fff;
  border: none;
  box-shadow: 0 0 16px -5px #aaaaaa;
}

.drag-icon{
  color: #757575;
  margin-right: 1%;
  cursor: move;
}

.edits {
  padding: 1% 2%;
  border: 1px solid #a8a8a8;
  border-radius: 6px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.edit-text{
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .main-div {
    padding: 0px;
  }
  .edit-text {
    width: 50%;
  }
}

.main-div .WithStyles-Paper--root-1676 {
  width: -webkit-fill-available;
  padding: 1%;
  margin-top: 2%;
  border-radius: 0;
}

.PasswordReset-modal-29{
  z-index: 999999999 !important;
}