.login {
  height: auto;
  background: #ffffff;
  min-height: max-content;
  border-radius: 5px;
  width: 420px;
  position: relative;
  top: 50%;
  left: 50% !important;
  transform: translate(-50%, -50%);
}
.user-login {
  padding: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background: #F8F9F9;
  border-radius: 0 0 5px 5px;
}
.login .close-icon {
  position: absolute;
  right: -40px;
  background: #A6ABB6;
  border-radius: 0 5px 5px 0;
  top: 8px;
  height: 40px;
  width: 40px;
}
.login .close-icon button ,.login .close-icon button:hover{
  background: transparent;
  color: #FFFFFF;
  padding: 8px;
}
.login .modal-title {
  background: #fff;
  height: 78px;
  border-radius: 5px 5px 0 0;
  display: flex;
  justify-content: left;
  padding: 0 5%;
  align-items: center;
  margin: 3px 0;
  box-shadow: 0 6px 15px -8px #a7a7a7;
  border-radius: 5px 5px 0px 0px;
}
.login .modal-title h2{
  margin: 0;
  font-weight: bold;
  color: #545860;
}
.login .field-container > div{
  width: auto;
  margin: 5px 0 15px;
}


.btn-section{
  width: 100%;
}
.btn-section .login-btn{
  background: #F29223;
  width: 100%;
  border-radius: 3px;
  margin: 10px 0;
  color: #fff;
}
.btn-section .login-btn:hover{
  box-shadow: 0 0 11px rgba(33,33,33,.2);
  background: #F29223;
  color: #fff;
}

.modal-footer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}

.google {
  margin-top: 3%;
}

.error {
  color: #c70b1b;
  margin: 0;
}
.forgot {
  color: blue;
  text-decoration: underline;
  margin-top: 5px;
}
.forgot:hover{
  cursor: pointer;
}
@media screen and (max-width: 920px) and (min-width: 771px) {
  .login {
    width: 280px;
  }
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .login{
    width: 100%;
    max-width: 100%;
    top: 40%;
  }
  .login .close-icon {
    top:20px;
    right: 0;
    background: transparent;
  }

  .login .close-icon button ,.login .close-icon button:hover{
    color: #545860;
  }
}