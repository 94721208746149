body {
  background-color: #fff;
  min-height: 100vh;
  width: 100%;
  z-index: 1;
  overflow-x: hidden;
  font-family: Roboto !important;
}
.abc{
  z-index: 1;
}
.bg-white {
  background: #FFFFFF !important;
}
.p-0 {
  padding: 0 !important;
}
.wrapper{
  display: flex;
  flex-direction: row;
  overflow: hidden;
  max-width: 100vw;
}
.modal-main-div{
  width: calc(100vw - 420px);
  height: auto;
  padding: 6%;
  margin-left: 420px;
}
.business-modal {
margin-left: 0;
}

/* .modal-main-div > div { */
  /* left: 420px;
  margin: 0 auto; */
/* } */
.business-profile-admin-container {
  /* position: relative;
  left: 50%;
  transform: translateX(-50%); */
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  background-color: #616161;
}
.footer {
  max-height: 250px;
}
.main-screen{
  align-items: center;
  flex-grow: 1;
  overflow: auto;
  background-color: #165593;
  background-repeat: no-repeat;
}
.app-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  border-top: 1px solid #245497;
  background-color: #245497;
  color: white;
  font-weight: 600;
}
.full-width-btn{
  background: #F29223;
  width: 100%;
  border-radius: 3px;
  margin: 10px 0;
  color: #fff;
}
.pagination-div{
  display: flex;
  justify-content: space-between;
}
.full-width-btn:hover{
  box-shadow: 0 0 11px rgba(33,33,33,.2);
  background: #F29223;
  color: #fff;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .footer {
    display: none;
  }
  .header {
    display: none;
  }
  .app-btn{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .mobile-btn{
    display: none;
  }
  .wrapper{
    display: block;
  }
  .main-screen{
    display: block;
    width: 100%;
  }
  .image-map-container .map{
    display: block;
  }
  .map{
    display: none;
  }
  .modal-main-div > div ,.modal-main-div{
    margin-left: 0 !important;
    left: 0 ;
  }
  body .modal-main-div{
    width: 100%;
  }
}

@media screen and (min-width: 320px) and (max-width: 1200px){
  .main-screen{
    position: absolute;
    right: 0;
  }
  body .header-display{
    right: 05px;
    top: 0;
  }
}
::-webkit-scrollbar {
  width: 0.5em;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: #165593;
  outline: 1px solid slategrey;
}

.MuiInputBase-input-445{
  border:none !important;  
}

.MuiOutlinedInput-root-712 .MuiOutlinedInput-notchedOutline-719{
  border-color:transparent !important;  
}
.pl15{ padding-left: 15px; }


.menuItem-div button p.cross, .said-icon button p.cross, .said-icon-wh button p.cross{ background: none!important; color: #165593 !important;
   }

.custom-cluster img{
    display: none;
}

.custom-cluster{
}

.custom-cluster >div{
  height: 48px !important;
  width: 48px !important;
  background: #C64D18;
  border-radius: 50%;
  line-height: 48px !important;
}

.custom-cluster > *{
  color: #fff !important;
  font-weight: 700 !important;
  font-size: 16px !important;
}

.MuiMenu-paper-739{ max-height: 200px !important; }

@media screen and (min-width: 1024px) and (max-width: 2560px){

.form-container {
    margin: auto;
    width: 96% !important;
}
}



.MuiPrivateTabIndicator-colorSecondary-153{
  display: none !important;
}
