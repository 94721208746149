.list-div{
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.list-div a{
  color: #2fc5ff;
  cursor: pointer;
}

a:hover{
  /*text-decoration: underline;*/
}

.list-img-div{
  height: auto;
  width: 60%;
}

.list-img-div img{
  height: 100%;
  width: 100%;
  object-fit: contain;
}

@media screen and (min-width: 320px) and (max-width: 760px){
  .list-img-div{
    height: auto;
    width: 100%;
  }
}