 body .main-review-container{
  background: #ffffff;
}

 body .main-review-container.review-div{
   position: relative;
   overflow-y: inherit;
   border-radius: 5px;
   top: 50%;
   left: 47% !important;
   transform: translate(-50%, -50%);
 }


 body .main-review-container .titlebar{
   padding: 10px 0;
   padding-left:40px ;

 }
.review-box{
  height: auto;
  background: #ffffff;
  max-height: 100%;
  min-height: max-content;
  border-radius: 5px;
  width: 420px;
  position: relative;
  top: 50%;
  left: 50% !important;
  transform: translate(-50%, -50%);
  overflow-y: inherit;
}

.review-box .close-box {
  position: absolute;
  right:-40px;
  background: #A6ABB6;
  border-radius: 0 5px 5px 0;
  top: 8px;
  height: 40px;
  width: 40px;
  color: white;

}
.review-box .close-box button ,.review-box .close-box button:hover{
  color: #FFFFFF;
  background: transparent;
  padding: 8px;
}

.review-container {
  background: #F8F9F9;
  padding: 1% 6% 6% 6%;
  display: block;
  flex-direction: column;
  justify-content: space-around;
  min-height: max-content;
  height: calc(100vh - 200px);
  overflow: auto;
  border-radius: 0 0 5px 5px;
  margin-bottom: 5px;
}

.review-container .star-ratings,.review-container .star-ratings:hover{
  border: none;
  box-shadow: none;
  height: 20px;
  margin: 8px 0;
}

.review-box .titlebar{

  height: 78px;
  display: flex;
  position: relative;
  justify-content: left;
  padding: 0 20px;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.12);
  border-radius: 5px 5px 0px 0px;
}
.review-box .titlebar h2{
  font-weight: bold;
  color: #545860;
  margin: 0;
}
.user-review{
  width: 100%;
  margin-bottom:1%;
  padding-right: 5%;
  border-bottom: 1px solid #000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: justify;
}

.review-label{
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer
}

.reply{
  width: 100%;
  margin: 5% 0 0 5%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: justify;
}

.by-line{
  font-size: 11px;
  color: gray;
}

.message{
  display: inline-flex;
  justify-content: center;
}

.success{
  color: #008000;
}

.failed {
  color: #bf0708;
}
.btn-submit{
  text-align: center;
}
.btn-submit button,.btn-submit button:hover{
  width: 80%;
  margin: 10px auto;
  background: #F29223;
  height: 38px;
}
@media screen and (max-width: 920px) and (min-width: 771px) {
  .main-review-container {
    width: 280px;
  }
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .main-review-container{
    width: 100%;
  }
  .star-container svg{
    /*height: 20px !important;*/
    /*width: 20px !important;*/
  }
  .review-container{
    min-height: max-content;
  }
  .message{
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
  .review-box .close-box {
    top: 4%;
    right: 0;
    background: transparent;
  }
  .review-box .close-box button ,.review-box .close-box button:hover{
    color:#545860;
  }
  body .main-review-container.ask-Question {
    left: 50% !important;
  }
}