.list-container {
  padding: 0% 40px 40px;
  max-height: 64vh;
  overflow: auto;
  background: #F8F9F9;
  border-radius: 5px;
}
.header-total-rating {
  display: inline-flex;
  align-items: baseline;
}
.rating-review-count p {
  color: #F29223;
  margin-right: 5px;
}
.reviewers-total-list {
  margin-top: 15px;
}
.single-review {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  background: #FFFFFF;
  border: 1px solid #F3F3F3;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding: 18px 20px;
  font-size: 14px;
  color: #545860;
  font-style: italic;
}
.single-review p{
  font-style: normal;
  margin-bottom: 5px;
}
.single-review h3{
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.01em;
  margin-bottom: 0;
  color: #545860;
}
.divider-line {
  width: auto;
  height: 1px;
  background-color: #CACCD2;
  margin: 5px 0;
}
.list-container h2 {
  font-weight: 500;
}
.header-total-rating h3 {
  color: orange;
  font-size: 16px;
  margin-right: 5px;
  font-weight: 500;
}
.header-total-rating p {
  color: #A6ABB6;
  margin-left: 5px;
  font-size: 16px;
  margin-bottom: 0;
}
.review-button {
  float: right;
}
.review-rating-title {

}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .main-title-header {
    display: flex;
    flex-direction: column;
  }
  .list-container {
    padding: 0 16px 16px;
    height: 250px;
    overflow: auto;
  }
  .review-button{
    float: left;
  }
}

.mod1{
  z-index: 22222222;
}

.resource-details .MuiPaper-root-169{
    left: 400px !important;
}
.MuiModal-root{
  background-color: red !important;
}
